<mat-nav-list>
  <mat-list-item (click)="onNewClick()">
    <mat-icon>add_circle</mat-icon>
    <span mat-line>Inserisci nuovo Vino</span>
    <span mat-line>Aggiungi tu tutti i dettagli del prodotto</span>
  </mat-list-item>
  <mat-list-item (click)="onImportClick()">
    <mat-icon>auto_fix_high</mat-icon>
    <span mat-line>Importa Vini da Esploravino</span>
    <span mat-line>Inserisci i tuoi prodotti automaticamente</span>
  </mat-list-item>
</mat-nav-list>
