<div class="container-max-width">

    <div class="banner">
        <b>Ben fatto!</b>
        La Rilevazione Consumi è stata completata con successo. Ci stiamo attivando per un nuovo rifornimento.
        Grazie per la collaborazione.
    </div>

    <ng-container *ngIf="applyCoupons(inventoryReportDocument.totalAmountDue,
    coupons); let cart">

        <div class="doc">
            <p class="title">
                <b>{{inventoryReportDocument.documentSeries}}: {{inventoryReportDocument.documentNumber}}</b>
            </p>
            <p>del {{getDocumentDate(inventoryReportDocument) | date:'dd/MM/yyyy'}}</p>
            <hr>
            <p>Quantità consumata<br><b class="value">{{inventoryReportDocument.totalQuantity}}</b></p>
            <hr>
            <p>Netto a Pagare<br><b class="value">{{inventoryReportDocument.totalAmountDue | number: "1.2"}} €</b></p>
            <p *ngFor="let couponEntry of cart.coupons | keyvalue; let index = index" class="coupon">buono
                {{couponEntry.key.id}}<br>- <b class="value">{{couponEntry.value | number: "1.2"}} €</b></p>
            <hr>
            <p>Totale se paghi ora<br><b class="value">{{cart.amount |
                    number: "1.2"}} €</b></p>
        </div>

        <div class="extended-fab-container">

            <button mat-raised-button color="accent" (click)="onPagaOraClick(cart.amount, coupons)">
                <mat-icon>payment</mat-icon>Paga Ora <b>{{cart.amount |
                    number: "1.2"}} €</b>
            </button>
        </div>

    </ng-container>

    <div class="extended-fab-spacer"></div>
</div>