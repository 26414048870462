import { Component, OnDestroy } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { InventoryItem } from '../models/inventory-item';
import { InventoryReport, InventoryReportDocument, InventoryReportItem } from '../models/inventory-report';
import { WineCategory } from '../models/vino';


import firebase from 'firebase/app';
import 'firebase/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, share, switchMap } from 'rxjs/operators';
import { EsploravinoApiService } from '../esploravino-api.service';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { SessionClaims } from '../models/session-claims';
import { SnackBarService } from '../snack-bar.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CurrentStoreService } from '../current-store.service';
import { Coupon } from '../models/coupon';
import { Payment } from '../models/payment';

type Step = "edit" | "saving" | "review" | "submitting" | "success" | "paying" | "paid";

@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.scss']
})
export class InventarioComponent implements OnDestroy {

  tenantId: string;
  sessionClaims: SessionClaims;
  storeId: string;
  currentUser: firebase.User;

  currentStep: Step = "edit";

  inventoryReport$: Observable<InventoryReport>;
  wineCategories$: Observable<WineCategory[]>;

  coupons!: Coupon[];

  constructor(private activatedRoute: ActivatedRoute, private mainToolbarTitleService: MainToolbarTitleService, private esploravinoApiService: EsploravinoApiService, private router: Router, private snackBarService: SnackBarService, private matDialog: MatDialog, private currentStoreService: CurrentStoreService) {
    this.tenantId = this.activatedRoute.snapshot.params.tenantId;
    this.sessionClaims = this.activatedRoute.snapshot.data.currentSessionClaims as SessionClaims;
    this.storeId = this.activatedRoute.snapshot.params.storeId;

    this.currentUser = this.activatedRoute.snapshot.data.currentUser as firebase.User;

    this.mainToolbarTitleService.setTitle('Inventario');
    this.mainToolbarTitleService.setSubtitle(this.storeId);
    this.currentUser.getIdToken().then((idToken) => esploravinoApiService.getStore(this.tenantId, this.sessionClaims.customerId, this.storeId, idToken, this.sessionClaims).toPromise().then((store) => this.mainToolbarTitleService.setSubtitle(store.name)));

    this.wineCategories$ = from(this.currentUser.getIdToken()).pipe(switchMap((idToken) => esploravinoApiService.listWineCategories(this.tenantId, idToken, this.sessionClaims)));

    this.inventoryReport$ = from(this.currentUser.getIdToken()).pipe(switchMap((idToken) => esploravinoApiService.getInventoryReport(this.tenantId, this.sessionClaims.customerId, this.storeId, idToken, this.sessionClaims))).pipe(catchError((e) => {
      if (e.status === 409) {
        this.matDialog.open(AlertDialogComponent, {
          data: {
            title: "Inventario", body: 'I dati contabili per questo inventario sono cambiati, è necessario ricaricare i dati.', buttons: [
              {
                key: 'ok',
                label: "Ok"
              },
            ]
          }
        }).afterClosed().toPromise().then((value) => {
          this.router.navigate(['.'], { relativeTo: this.activatedRoute });
        });
        return new Observable<never>();
      } else {
        return throwError(e);
      }
    }));

    this.setCurrentStep(this.currentStep);
  }
  ngOnDestroy(): void {
    this.snackBarService.close();
  }

  private setCurrentStep(stepName: Step) {
    this.currentStep = stepName;
    this.router.navigate([], { queryParams: { step: this.currentStep } });
  }

  async onEditComplete(inventoryReport: InventoryReport) {
    this.setCurrentStep("saving");
    this.snackBarService.showInProgress("Salvataggio bozza inventario in corso...");
    try {
      await from(this.currentUser.getIdToken()).pipe(switchMap((idToken) => this.esploravinoApiService.updateInventoryReport(this.tenantId, this.sessionClaims.customerId, this.storeId, idToken, this.sessionClaims, inventoryReport))).toPromise();
      this.snackBarService.close();
      this.setCurrentStep("review");
    } catch (e) {
      console.error(e);
      this.snackBarService.showError();
      this.setCurrentStep("edit");
    }
  }

  onReviewCancel() {
    this.setCurrentStep("edit");
  }

  async onReviewComplete(inventoryReport: InventoryReport) {
    this.setCurrentStep("submitting");
    this.snackBarService.showInProgress("Conferma inventario in corso...");
    try {
      inventoryReport.draft = false;
      const result = await from(this.currentUser.getIdToken()).pipe(switchMap((idToken) => this.esploravinoApiService.updateInventoryReport(this.tenantId, this.sessionClaims.customerId, this.storeId, idToken, this.sessionClaims, inventoryReport))).toPromise();
      inventoryReport.document = result.document;
      this.coupons = await from(this.currentUser.getIdToken()).pipe(switchMap((idToken) => this.esploravinoApiService.listCoupons(this.tenantId, this.sessionClaims.customerId, idToken, this.sessionClaims))).toPromise();
      this.snackBarService.close();
      this.setCurrentStep("success");
    } catch (e) {
      console.error(e);
      inventoryReport.draft = true;
      this.snackBarService.showError();
      this.setCurrentStep("edit");
    }

  }

  async onPagaOra(payment: Payment, inventoryReportDocument: InventoryReportDocument) {
    this.setCurrentStep("paying");
    this.snackBarService.showInProgress("Pagamento in corso...");
    try {
      await from(this.currentUser.getIdToken()).pipe(switchMap((idToken) => this.esploravinoApiService.createPayment(this.tenantId, this.sessionClaims.customerId, idToken, this.sessionClaims, inventoryReportDocument.documentSeries, inventoryReportDocument.documentId, payment))).pipe(catchError((e) => {
        if (e.status === 409) {
          this.snackBarService.showError();
          this.setCurrentStep("success");
          this.matDialog.open(AlertDialogComponent, {
            data: {
              title: "Pagamento", body: e.error.message ?? 'Spiacente, non è stato possibile procedere con il pagamento.', buttons: [
                {
                  key: 'ok',
                  label: "Ok"
                },
              ]
            }
          }).afterClosed().toPromise().then((value) => {
            this.router.navigate(['.'], { relativeTo: this.activatedRoute });
          });
          return new Observable<never>();
        } else {
          return throwError(e);
        }
      })).toPromise();
      this.snackBarService.close();
      this.setCurrentStep("paid");
    } catch (e) {
      console.error(e);
      this.snackBarService.showError();
      this.setCurrentStep("success");
    }
  }

}
