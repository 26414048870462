<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <p>
    Tramite questo codice QR è possibile accedere alla Carta dei Vini in formato
    PDF.
  </p>
  <div class="qr-container">
    <canvas
      #qr
      qr-code
      [qrCode]="data.url"
      [width]="200"
      [height]="200"
      qrCodeErrorCorrectionLevel="M"
      qrCodeCenterImageSrc="/assets/v-white-on-green.png"
      qrCodeCenterImageWidth="40"
      qrCodeCenterImageHeight="40"
      style="display: block; position: relative; left: 50%; margin-left: -100px"
    ></canvas>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-icon-button aria-label="Condividi" (click)="shareImage(qr)">
    <mat-icon>share</mat-icon>
  </button>
  <button mat-icon-button aria-label="Scarica" (click)="downloadImage(qr)">
    <mat-icon>file_download</mat-icon>
  </button>
  <span fxFlex></span>
  <button
    mat-raised-button
    color="accent"
    mat-button
    mat-dialog-close
    cdkFocusInitial
  >
    OK
  </button>
</mat-dialog-actions>
