import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { filter, first, map, switchMap } from 'rxjs/operators';

import firebase from 'firebase/app';
import 'firebase/auth';
import { SessionClaims } from './models/session-claims';

@Injectable({
  providedIn: 'root'
})
export class CurrentSessionClaimsResolver implements Resolve<SessionClaims> {

  constructor(private angularFireAuth: AngularFireAuth) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SessionClaims> {
    return this.angularFireAuth.user.pipe(filter((x) => x !== null)).pipe(first()).pipe(switchMap((u) => (u as firebase.User).getIdTokenResult())).pipe(map((t) => t.claims as SessionClaims));
  }
}
