import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { CurrentCustomerService } from '../current-customer.service';
import { EsploravinoApiService } from '../esploravino-api.service';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { Customer } from '../models/customer';
import { SessionClaims } from '../models/session-claims';
import firebase from 'firebase/app';
import 'firebase/auth';

@Component({
  selector: 'app-customer-selector',
  templateUrl: './customer-selector.component.html',
  styleUrls: ['./customer-selector.component.scss']
})
export class CustomerSelectorComponent {

  customers$!: Observable<Customer[]>;

  loadingSettingCurrentCustomer = false;

  tenantId: string;
  sessionClaims: SessionClaims;
  currentUser: firebase.User;

  constructor(private activatedRoute: ActivatedRoute, private esploravinoApiService: EsploravinoApiService, private router: Router, private currentCustomerService: CurrentCustomerService, mainToolbarTitleService: MainToolbarTitleService) {
    this.sessionClaims = this.activatedRoute.parent?.parent?.snapshot.data.currentSessionClaims as SessionClaims;
    this.currentUser = this.activatedRoute.parent?.parent?.snapshot.data.currentUser as firebase.User;
    this.tenantId = this.activatedRoute.parent?.parent?.snapshot.params.tenantId;

    this.customers$ = this.sessionClaims.salesRepresentativeId
      ?
      from(this.currentUser.getIdToken()).pipe(switchMap((token) => this.esploravinoApiService.listCustomers(this.tenantId, token, this.sessionClaims))).pipe(share())
      :
      of([{
        id: this.sessionClaims.customerId,
        displayName: this.sessionClaims.customerName,
      }]);

    mainToolbarTitleService.setTitle("Seleziona il cliente");

    this.customers$.subscribe((customers) => {
      if (customers.length === 1) {
        this.setCurrentCustomerAndNavigate(customers[0], [customers[0].id]);
      } else {
        if (this.currentCustomerService.currentCustomer !== null) {
          const customer = customers.find((s) => s.id === this.currentCustomerService.currentCustomer?.id);
          if (customer) {
            this.setCurrentCustomerAndNavigate(customer, [customer.id]);

          } else {
            this.setCurrentCustomerAndNavigate(null);
          }
        }
      }
    });
  }

  async setCurrentCustomerAndNavigate(customer: Customer | null, navigateParams?: any[]): Promise<void> {
    this.loadingSettingCurrentCustomer = true;
    if (customer !== null) {
      await this.currentCustomerService.setCurrentCustomer(customer, this.tenantId, this.currentUser, this.sessionClaims);
    } else {
      this.currentCustomerService.clearCurrentCustomer(this.sessionClaims);
    }
    if (navigateParams) {
      this.router.navigate(navigateParams, { replaceUrl: true, relativeTo: this.activatedRoute });
    }
    this.loadingSettingCurrentCustomer = false;
  }
}
