import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-carta-vini-qr-dialog',
  templateUrl: './carta-vini-qr-dialog.component.html',
  styleUrls: ['./carta-vini-qr-dialog.component.scss']
})
export class CartaViniQrDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { url: string, title: string }) { }

  ngOnInit(): void {

  }

  private slugify(input: string): string {

    // https://gist.github.com/djabif/b8d21c4ebcef51db7a4a28ecf3d41846

    return input.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }

  downloadImage(canvas: HTMLCanvasElement): void {
    canvas.toBlob((blob) => {
      if (blob !== null) {
        saveAs(new Blob([blob], { type: 'image/png' }), this.slugify(this.data.title) + '.png');
      }
    }, 'image/png');
  }

  shareImage(canvas: HTMLCanvasElement): void {
    canvas.toBlob((blob) => {
      if (blob !== null) {
        const filesArray = [
          new File(
            [blob],
            this.slugify(this.data.title) + '.png',
            {
              type: 'image/png',
              lastModified: new Date().getTime()
            }
          )
        ];
        const shareData = {
          title: this.data.title,
          files: filesArray,
          text: this.data.title + ' ' + this.data.url
        };

        navigator.share(shareData as ShareData);
      }
    }, 'image/png');
  }

}
