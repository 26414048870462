import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private static DURATION = 2500;
  private static POSITION: MatSnackBarVerticalPosition = 'bottom';

  constructor(private matSnackBar: MatSnackBar) { }

  showInProgress(message: string): Promise<void> {
    return this.matSnackBar.openFromComponent(SnackBarComponent, { data: { message, type: 'in-progress' }, duration: 0, verticalPosition: SnackBarService.POSITION }).onAction().toPromise();
  }

  showInfo(message: string, action?: string): Promise<void> {
    return this.matSnackBar.openFromComponent(SnackBarComponent, { data: { message, action: action ?? 'OK', type: 'info' }, duration: 0, verticalPosition: SnackBarService.POSITION }).onAction().toPromise();
  }

  showSuccess(message: string, action?: string): Promise<void> {
    return this.matSnackBar.openFromComponent(SnackBarComponent, { data: { message, action: action ?? 'OK', type: 'success' }, duration: SnackBarService.DURATION, verticalPosition: SnackBarService.POSITION }).onAction().toPromise();
  }

  showWarning(message: string, action?: string): Promise<void> {
    return this.matSnackBar.openFromComponent(SnackBarComponent, { data: { message, action: action ?? 'OK', type: 'warning' }, duration: 0, verticalPosition: SnackBarService.POSITION }).onAction().toPromise();
  }

  showError(message?: string, action?: string): Promise<void> {
    return this.matSnackBar.openFromComponent(SnackBarComponent, { data: { message: message ?? 'Spiacente, si è verificato un errore.', action: action ?? 'OK', type: 'error' }, duration: 0, verticalPosition: SnackBarService.POSITION }).onAction().toPromise();
  }

  close() {
    this.matSnackBar.dismiss();
  }

}
