<div class="container-max-width">
  <ng-container *ngIf="wineCategories$ | async; let wineCategories; else: spinner">
    <ng-container *ngIf="wine$ | async; let vino; else: spinner">
      <div class="container-with-padding">
        <form fxLayout="column" (submit)="saveWine(vino, myForm.valid)" #myForm="ngForm">
          <div fxLayout="row">
            <h3 class="title" fxFlex>Dettagli Vino</h3>
            <mat-chip-list *ngIf="vino.externalId">
              <mat-chip (click)="showImportedFromEsploravinoMessage()"><img src="/assets/v-white-on-green.png" style="
                  width: 24px;
                  height: 24px;
                  border-radius: 16px;
                  position: relative;
                  left: -4px;
                " />Da Esploravino</mat-chip>
            </mat-chip-list>
          </div>

          <mat-form-field class="input-full-width" appearance="fill" color="accent">
            <mat-label>Denominazione</mat-label>
            <input matInput [(ngModel)]="vino.denominazione" name="denominazione" [readonly]="vino.externalId"
              [required]="!vino.externalId" />
          </mat-form-field>

          <mat-form-field class="input-full-width" appearance="fill" color="accent">
            <mat-label>Cantina</mat-label>
            <input matInput [(ngModel)]="vino.cantina" name="cantina" [readonly]="vino.externalId"
              [required]="!vino.externalId" />
          </mat-form-field>

          <div fxLayout="row">
            <mat-form-field fxFlex appearance="fill" color="accent" style="width: calc(50% - 5px)">
              <mat-label>Categoria</mat-label>
              <select *ngIf="!vino.externalId" matNativeControl name="categoria" [(ngModel)]="vino.categoria" required>
                <option [value]="wineCategory.id" *ngFor="let wineCategory of wineCategories">
                  {{ wineCategory.name }}
                </option>
              </select>
              <input matInput *ngIf="vino.externalId"
                value="{{ getWineCategoryNameById(vino.categoria, wineCategories) }}" readonly />
            </mat-form-field>

            &nbsp;&nbsp;&nbsp;

            <mat-form-field fxFlex appearance="fill" color="accent" style="width: calc(50% - 5px)">
              <mat-label>Capacità bottiglia</mat-label>
              <input matInput name="capacita" [(ngModel)]="vino.capacita" [readonly]="vino.externalId" type="number"
                min="0" step="1" [required]="!vino.externalId" />
              <span matSuffix>ml</span>
            </mat-form-field>
          </div>

          <mat-form-field class="input-full-width" appearance="fill" color="accent">
            <mat-label>Descrizione</mat-label>
            <textarea matInput [(ngModel)]="vino.descrizione" name="descrizione" cdkTextareaAutosize></textarea>
          </mat-form-field>

          <div style="margin: 8px 0 16px 0">
            <mat-slide-toggle [ngModel]="!vino.hidden" (ngModelChange)="vino.hidden = !$event" name="hidden">Visibile
              sulla Carta?
              <b>{{ vino.hidden ? "No" : "Sì" }}</b>
            </mat-slide-toggle>
          </div>

          <div fxLayout="row">
            <h3 class="title" fxFlex>Prezzi</h3>
            <div>
              <small *ngIf="vino.prezzoAcquisto">Prezzo d'acquisto di riferimento&nbsp;&nbsp;&nbsp;</small>
              <b style="font-size: 16px;">{{ vino.prezzoAcquisto | number: "1.2" }}</b>€
            </div>
          </div>

          <mat-form-field fxFlex appearance="fill" color="accent">
            <mat-label>Prezzo BOTTIGLIA</mat-label>
            <input matInput name="prezzo-bottiglia" type="number" min="0" step="1" [(ngModel)]="vino.prezzoBottiglia" />
            <span matSuffix>€</span>
          </mat-form-field>

          <mat-form-field fxFlex appearance="fill" color="accent">
            <mat-label>Prezzo ASPORTO</mat-label>
            <input matInput name="prezzo-asporto" type="number" min="0" step="1" [(ngModel)]="vino.prezzoAsporto" />
            <span matSuffix>€</span>
          </mat-form-field>

          <mat-form-field fxFlex appearance="fill" color="accent">
            <mat-label>Prezzo CALICE</mat-label>
            <input matInput name="prezzo-calice" type="number" min="0" step="1" [(ngModel)]="vino.prezzoCalice" />
            <span matSuffix>€</span>
          </mat-form-field>

          <div class="extended-fab-container">
            <button *ngIf="myForm.dirty" type="submit" mat-raised-button color="accent" [disabled]="fabDisabled">
              <mat-icon>check</mat-icon>Salva
            </button>
          </div>
        </form>

        <div class="extended-fab-spacer"></div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #spinner>
  <app-fullscreen-spinner></app-fullscreen-spinner>
</ng-template>