import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { InventoryReportItem } from '../models/inventory-report';

@Component({
  selector: 'app-inventario-item',
  templateUrl: './inventario-item.component.html',
  styleUrls: ['./inventario-item.component.scss']
})
export class InventarioItemComponent {

  @Input()
  item!: InventoryReportItem;

  @Input()
  disabled: boolean = false;

  constructor(private matDialog: MatDialog) { }

  plus() {
    if (this.item.qtaInventario < this.item.qtaContabile) {
      this.item.qtaInventario++;
    }
  }

  minus() {
    if (this.item.qtaInventario > 0) {
      this.item.qtaInventario--;
    }
  }

  showCentralinaWarningDialog() {
    this.matDialog.open(AlertDialogComponent, { data: { title: "SenseTray", body: 'La centralina SenseTray che ospita questo articolo non sta funzionando correttamente.' } });
  }

}
