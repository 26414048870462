<div class="container-max-width">
  <ng-container *ngIf="wineList$ | async; let carta; else: spinner">
    <div class="message-bar" *ngIf="carta.renderingPdf">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span fxFlex>Pubblicazione Carta dei Vini in corso...</span>
      </div>
    </div>
    <div class="message-bar" *ngIf="carta.dirty && !carta.renderingPdf">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span fxFlex>Questa Carta dei Vini è in bozza.</span><button mat-stroked-button
          (click)="publishWineList(carta)">
          Pubblica
        </button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" class="header">
      <nav mat-tab-nav-bar color="accent" fxFlex>
        <a mat-tab-link class="mat-typography" routerLink="./wines" routerLinkActive [active]="rla1.isActive"
          #rla1="routerLinkActive">
          <mat-icon class="tab-icon">liquor</mat-icon>
          <span>Lista Vini</span>
        </a>
        <a mat-tab-link class="mat-typography" routerLink="./settings" routerLinkActive [active]="rla2.isActive"
          #rla2="routerLinkActive">
          <mat-icon class="tab-icon">settings</mat-icon>
          <span>Impostazioni</span>
        </a>
      </nav>
      <div style="border-bottom: 1px solid rgba(0, 0, 0, 0.12); margin-bottom: -7px">
        <button mat-icon-button aria-label="Apri menu contestuale" [matMenuTriggerFor]="menu" [disabled]="disabled"
          style="margin-right: 16px; position: relative; bottom: 3px">
          <mat-icon>more_horiz</mat-icon>

          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="carta.dirty && !carta.renderingPdf" (click)="publishWineList(carta)">
              <mat-icon>publish</mat-icon>
              <span>Pubblica</span>
            </button>
            <button mat-menu-item [disabled]="!carta.url" (click)="shareWineList(carta)">
              <mat-icon>share</mat-icon>
              <span>Condividi</span>
            </button>
            <a mat-menu-item [disabled]="!carta.url" (click)="downloadWineList(carta)">
              <mat-icon>file_download</mat-icon>
              <span>Scarica PDF</span>
            </a>
            <a mat-menu-item [disabled]="!carta.url" (click)="showQrWineList(carta)">
              <mat-icon>qr_code_2</mat-icon>
              <span>QR Code</span>
            </a>
            <button mat-menu-item (click)="deleteWineList(carta)" [disabled]="carta.renderingPdf">
              <mat-icon>delete</mat-icon>
              <span>Elimina</span>
            </button>
          </mat-menu>
        </button>
      </div>
    </div>
    <router-outlet></router-outlet>
  </ng-container>
</div>

<ng-template #spinner>
  <app-fullscreen-spinner></app-fullscreen-spinner>
</ng-template>