import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { EsploravinoApiService } from '../esploravino-api.service';
import { SessionTokens } from '../models/session-tokens';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgZone } from '@angular/core';
import { CurrentStoreService } from '../current-store.service';
import { MatDialog } from '@angular/material/dialog';
import { threadId } from 'worker_threads';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';

const packageJson = require('../../../package.json');

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export default class LoginPageComponent implements OnInit, OnDestroy {

  loading = false;

  tenantId: string;

  url!: SafeUrl | null;

  environmentId: string | null = null;

  version: string | null = null;

  private onMessageFn!: ((event: any) => void) | null;

  constructor(private ngZone: NgZone, private sanitizer: DomSanitizer, private angularFireAuth: AngularFireAuth, private router: Router, private activatedRoute: ActivatedRoute, @Inject(DOCUMENT) private document: Document, private esploravinoApiService: EsploravinoApiService, private currentStoreService: CurrentStoreService, private matDialog: MatDialog) {
    this.tenantId = this.activatedRoute.snapshot.params.tenantId;

    this.currentStoreService.setCurrentStore(null);

    if (this.activatedRoute.snapshot.queryParams.userId) {
      this.loginStart(this.activatedRoute.snapshot.queryParams.userId);
    }

    if (!environment.production) {
      this.environmentId = environment.id;
      this.version = packageJson.version
    }
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    if (this.onMessageFn) {
      window.removeEventListener('message', this.onMessageFn);
    }
  }


  loginStart(userId: string = ''): void {
    this.loading = true;
    const url = environment.enumeraLoginBaseUrl + '?db=' + this.tenantId + '&redirect=' + encodeURIComponent(this.document.location.protocol + '//' + this.document.location.host + '/login-postmessage.html') + '&user=' + encodeURIComponent(userId);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.onMessageFn = (event: any) => {
      this.ngZone.run(async () => {

        const { type, data } = event.data;
        if (type === 'esploravino-login-enumera-success') {
          if ('uiSessionName' in data && 'uiSessionToken' in data && 'apiSessionName' in data && 'apiSessionToken' in data) {
            this.url = null;
            if (this.onMessageFn) {
              window.removeEventListener('message', this.onMessageFn);
              this.onMessageFn = null;
              this.loginEnd({
                uiSessionName: data.uiSessionName,
                uiSessionToken: data.uiSessionToken,
                apiSessionName: data.apiSessionName,
                apiSessionToken: data.apiSessionToken,
              });
            }
          } else {
            this.loading = false;
            await this.matDialog.open(AlertDialogComponent, { data: { title: "Login", body: 'Purtroppo si è verificato un erorre, non sono stati ricevuti i parametri di sessione, riprova più tardi. Se l\'errore persiste, contatta il supporto, grazie.' } }).afterClosed().toPromise();
            this.router.navigate(['/']);
          }
          this.onMessageFn = null;
        }
      });
    };
    window.addEventListener('message', this.onMessageFn);
  }

  onIframeLoaded(): void {
    this.loading = false;
  }

  async loginEnd(sessionTokens: SessionTokens): Promise<void> {
    this.loading = true;
    try {

      const token = await this.esploravinoApiService.createSession(this.tenantId, sessionTokens).pipe(first()).toPromise();
      const userCredential: firebase.auth.UserCredential = await this.angularFireAuth.signInWithCustomToken(token);

      if (userCredential === null || userCredential?.user === null) { throw new Error('Empty userCredential || userCredential.user'); }

      if (this.activatedRoute.snapshot.queryParams.returnUrl && this.activatedRoute.snapshot.queryParams.returnUrl.indexOf("login") === -1) { // prevent redirect loop
        this.router.navigateByUrl(this.activatedRoute.snapshot.queryParams.returnUrl, {});
      } else {
        this.router.navigate(['/', this.tenantId]);
      }

    } catch (error) {
      this.loading = false;
      console.error(error);
      await this.matDialog.open(AlertDialogComponent, { data: { title: "Login", body: 'Purtroppo si è verificato un erorre durante l\'accesso, riprova più tardi. Se l\'errore persiste, contatta il supporto, grazie.' } }).afterClosed().toPromise();
      this.router.navigate(['/', this.tenantId]);
    }
  }
}
