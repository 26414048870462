import { CartaVini } from './../models/carta-vini';
import { Vino, WineCategory } from './../models/vino';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionClaims } from '../models/session-claims';
import { map, switchMap } from 'rxjs/operators';
import { MainToolbarTitleService } from '../main-toolbar-title.service';

import firebase from 'firebase/app';
import 'firebase/auth';
import { EsploravinoApiService } from '../esploravino-api.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { SnackBarService } from '../snack-bar.service';

@Component({
  selector: 'app-carta-vini-dettaglio-vino',
  templateUrl: './carta-vini-dettaglio-vino.component.html',
  styleUrls: ['./carta-vini-dettaglio-vino.component.scss']
})
export class CartaViniDettaglioVinoComponent implements OnInit {

  wine$: Observable<Vino>;

  private wineListDocument: AngularFirestoreDocument<CartaVini>;

  private wineDocument: AngularFirestoreDocument<Vino>;

  private winesCollection: AngularFirestoreCollection<Vino>;

  wineCategories$: Observable<WineCategory[]>;

  fabDisabled = false;

  constructor(private angularFirestore: AngularFirestore, private activatedRoute: ActivatedRoute, private router: Router, private snackBarService: SnackBarService, mainToolbarTitleService: MainToolbarTitleService, private esploravinoApiService: EsploravinoApiService, private matDialog: MatDialog) {
    const tenantId = this.activatedRoute.parent?.parent?.snapshot.params.tenantId;
    const sessionClaims = this.activatedRoute.parent?.parent?.snapshot.data.currentSessionClaims as SessionClaims;
    const storeId: string = this.activatedRoute.snapshot.params.storeId;
    const cartaId: string = this.activatedRoute.snapshot.params.cartaId;
    const vinoId: string = this.activatedRoute.snapshot.params.vinoId;

    this.wineListDocument = this.angularFirestore.doc<CartaVini>(`tenants/${tenantId}/customers/${sessionClaims.customerId}/stores/${storeId}/wine-lists/${cartaId}`);
    this.winesCollection = this.wineListDocument.collection<Vino>(`wines`);

    if (vinoId) {
      this.wineDocument = this.winesCollection.doc(vinoId);
      this.wine$ = this.winesCollection.doc(vinoId).get().pipe(map((d) => d.data())) as Observable<Vino>;
      this.wine$.toPromise().then((wine) => mainToolbarTitleService.setTitle(wine.denominazione ?? null));
    } else {
      this.wineDocument = this.winesCollection.doc();
      this.wine$ = new BehaviorSubject<Vino>({ id: this.wineDocument.ref.id, externalId: null, hidden: false } as Vino).asObservable();
      mainToolbarTitleService.setTitle('Nuovo Vino');
    }

    const currentUser = this.activatedRoute.parent?.parent?.snapshot.data.currentUser as firebase.User;
    this.wineCategories$ = from(currentUser.getIdToken()).pipe(switchMap((idToken) => esploravinoApiService.listWineCategories(tenantId, idToken, sessionClaims)));

    this.angularFirestore.doc<CartaVini>(`tenants/${tenantId}/customers/${sessionClaims.customerId}/stores/${storeId}/wine-lists/${cartaId}`).get().toPromise().then((doc) => mainToolbarTitleService.setSubtitle(doc.data()?.title ?? null));
  }

  ngOnInit(): void {
  }

  async saveWine(vino: Vino, isValid: boolean | null): Promise<void> {

    if (!isValid) {
      this.snackBarService.showWarning('Compila tutti i campi obbligatori');
      return;
    }

    this.fabDisabled = true;

    this.snackBarService.showInProgress('Salvataggio vino...');

    try {
      await this.wineDocument.set(vino);
      this.snackBarService.showSuccess('Vino salvato.');
      this.wineListDocument.update({ dirty: true });
      this.router.navigate(['./../'], { relativeTo: this.activatedRoute });
    } catch (e) {
      console.error(e);
      this.snackBarService.showError();
    }
    this.fabDisabled = false;
  }

  async showImportedFromEsploravinoMessage(): Promise<void> {
    await this.matDialog.open(AlertDialogComponent, { data: { title: 'Vino importato da Esploravino', body: 'Questo vino è stato importato direttamente dai tuoi prodotti Esploravino. Puoi soltanto modifcare i prezzi mostrati sulla tua Carta dei Vini ed inserire informazioni aggiuntive.' } }).afterClosed().toPromise();
  }

  getWineCategoryNameById(id: string, wineCategories: WineCategory[]) {
    return wineCategories.find((wc) => wc.id === id)?.name;
  }

}
