import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CropperComponent } from 'angular-cropperjs';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent {

  @ViewChild('angularCropper')
  angularCropper!: CropperComponent;

  loading = false;

  maxW = 1250;
  maxH = 625;

  cropperOptions = {
    aspectRatio: this.maxW / this.maxH,
    autoCropArea: 1,
    background: false,
    guides: false,
    toggleDragModeOnDblclick: false,
    dragMode: 'move',
    viewMode: 0
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, imageUrl: string }, private matDialogRef: MatDialogRef<ImageCropperDialogComponent>) { }

  ok(): void {
    this.loading = true;
    this.data.title = "Attendi un attimo...";
    this.angularCropper.cropper.getCroppedCanvas({
      maxWidth: this.maxW,
      maxHeight: this.maxH,
    }).toBlob((blob) => {
      this.matDialogRef.disableClose = true;
      this.matDialogRef.close(blob);
    }, "image/png", 1);
  }

}
