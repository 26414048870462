<a class="item" mat-list-item style="height: 144px" disableRipple>
    <div class="vino-icon" mat-list-icon>
        <img *ngIf="item.imageUrl" src="{{item.imageUrl}}" />
    </div>

    <div style="overflow: hidden; margin-right: 8px" mat-line>
        <b>{{ item.description }}</b>
        <div class="vino-detail">
            {{ item.brand }}
            <small> &mdash; {{item.capacity}} ml
            </small>
        </div>
        <div class="form">
            <button mat-stroked-button class="left" (click)="minus()" [disabled]="disabled || !(item.qtaInventario>0)">
                <mat-icon>remove_circle_outline</mat-icon>
            </button>
            <input matInput [(ngModel)]="item.qtaInventario" required type="number" step="1" min="0"
                [max]="item.qtaContabile" *ngIf="!disabled" />
            <input matInput type="number" *ngIf="disabled" />
            <button mat-stroked-button class="right" (click)="plus()"
                [disabled]="disabled || !(item.qtaInventario<item.qtaContabile)">
                <mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>
        <div>

            <small *ngIf="item.qtaInScadenza>0"><b>{{item.qtaInScadenza}}</b> in scadenza
            </small>
            <small *ngIf="item.qtaInScadenza>0 && (item.isCentralinaOk === true || item.isCentralinaOk === false)">
                • </small>
            <small *ngIf="item.isCentralinaOk === true || item.isCentralinaOk === false">
                <span *ngIf="item.isCentralinaOk; else: centralinaKo"><b>{{
                        item.qtaCentralina
                        }}</b> SenseTray</span>
                <ng-template #centralinaKo>
                    <span class="item-sensetray-warning" (click)="showCentralinaWarningDialog()">
                        <mat-icon>warning</mat-icon> SenseTray
                    </span>
                </ng-template>
            </small>

        </div>
    </div>
    <div class="item-details" [ngClass.xs]="'xs'">
        <span><span>Contabile</span>
            <b><span class="item-value">{{
                    item.qtaContabile
                    }}</span></b>
        </span>
        <span *ngIf="item.qtaContabile-item.qtaInventario>0"><span><b>Consumo</b></span>
            <b><span class="item-value item-value-red">{{
                    item.qtaContabile-item.qtaInventario
                    }}</span></b>
        </span>
    </div>
</a>