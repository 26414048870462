import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InventoryTransaction } from '../models/inventory-transaction';

@Component({
  selector: 'app-movimenti-magazzino-warning-dialog',
  templateUrl: './movimenti-magazzino-warning-dialog.component.html',
  styleUrls: ['./movimenti-magazzino-warning-dialog.component.scss']
})
export class MovimentiMagazzinoWarningDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { transaction: InventoryTransaction }) {

  }

}
