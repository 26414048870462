import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { first, mergeMapTo } from 'rxjs/operators';
import { EsploravinoApiService } from '../esploravino-api.service';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { SessionClaims } from '../models/session-claims';

import { SnackBarService } from '../snack-bar.service';

import firebase from 'firebase/app';
import 'firebase/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Device } from '../models/device';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {

  currentMessagingToken: string | null = null;
  activating = false;

  notifications$: Observable<Notification[]>;

  tenantId: string;
  sessionClaims: SessionClaims;

  constructor(private angularFireMessaging: AngularFireMessaging, private activatedRoute: ActivatedRoute, private snackBarService: SnackBarService, private angularFirestore: AngularFirestore, private mainToolbarTitleService: MainToolbarTitleService, private esploravinoApiService: EsploravinoApiService, private matDialog: MatDialog) {
    this.tenantId = this.activatedRoute.snapshot.params.tenantId;
    this.sessionClaims = this.activatedRoute.snapshot.data.currentSessionClaims as SessionClaims;

    this.notifications$ = this.angularFirestore.collection<Notification>(`tenants/${this.tenantId}/customers/${this.sessionClaims.customerId}/notifications`, ref => ref.orderBy('timestamp', 'desc')).valueChanges();

    this.mainToolbarTitleService.setTitle('Notifiche');
    this.mainToolbarTitleService.setSubtitle(this.sessionClaims.customerId + " • " + this.sessionClaims.customerName);

    this.angularFireMessaging.tokenChanges.subscribe(
      (token) => {
        this.currentMessagingToken = token;
      },
      (error) => {
        console.error(error);
        this.snackBarService.showError("Errore durante l'attivazione delle notifiche push.", error);
      });

  }

  async requestPermission() {

    this.activating = true;
    this.snackBarService.showInProgress("Attivazione notifiche push...");
    this.angularFireMessaging.requestPermission
      .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
      .pipe(first())
      .subscribe(
        async (token) => {
          try {
            const doc = this.angularFirestore.collection<Device>(`tenants/${this.tenantId}/customers/${this.sessionClaims.customerId}/devices`).doc();
            await doc.set({ token: token as string, timestamp: firebase.firestore.Timestamp.now() });
            this.snackBarService.showSuccess("Le notifiche push sono ora attive su questo dispositivo.");
            this.currentMessagingToken = token;
          } catch (e) {
            console.error(e);
            this.snackBarService.showError("Errore durante l'attivazione delle notifiche push.");
          }
          this.activating = false;
        },
        (error) => {
          console.error(error);
          this.snackBarService.showError("Errore durante l'attivazione delle notifiche push.");
          this.activating = false;
        });
  }

  revokePermission() {
    this.activating = true;
    this.snackBarService.showInProgress("Disattivazione notifiche push...");
    this.angularFireMessaging.deleteToken(this.currentMessagingToken as string)
      .pipe(first())
      .subscribe(
        async (token) => {
          try {
            const docs = await this.angularFirestore.collection<Device>(`tenants/${this.tenantId}/customers/${this.sessionClaims.customerId}/devices`, ref => ref.where("token", "==", this.currentMessagingToken)).get().toPromise();
            const promises: Promise<any>[] = [];
            docs.forEach((doc) => promises.push(doc.ref.delete()));
            await Promise.all(promises);
            this.snackBarService.showSuccess("Le notifiche push sono ora disattivate su questo dispositivo.");
            this.currentMessagingToken = null;
          } catch (e) {
            console.error(e);
            this.snackBarService.showError("Errore durante la disattivazione delle notifiche push.");
          }
          this.activating = false;
        },
        (error) => {
          console.error(error);
          this.snackBarService.showError("Errore durante la disattivazione delle notifiche push.");
          this.activating = false;
        });
  }

}
