import { SessionClaims } from './../models/session-claims';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';

import firebase from 'firebase/app';
import 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import { EsploravinoApiService } from '../esploravino-api.service';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { CurrentStoreService } from '../current-store.service';
import { from, Observable, ObservableLike } from 'rxjs';
import { CurrentCustomerService } from '../current-customer.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';

const packageJson = require('../../../package.json');

@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent implements OnInit {

  @Output()
  menuButtonClick = new EventEmitter<void>();

  @Output()
  moreMenuButtonClick = new EventEmitter<void>();

  appVersion = packageJson.version;

  currentUser: firebase.User;

  tenantId: string;

  currentSessionClaims: SessionClaims;

  backButtonRouterLink: any = null;
  firstChild: ActivatedRoute | null = null;
  title: string | null = null;
  subtitle: string | null = null;

  currentStore$;
  currentCustomer$;
  notificationsCount$: Observable<number | null>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private esploravinoApiService: EsploravinoApiService,
    mainToolbarTitleService: MainToolbarTitleService,
    private currentStoreService: CurrentStoreService,
    private currentCustomerService: CurrentCustomerService,
    private angularFirestore: AngularFirestore
  ) {
    this.currentUser = this.activatedRoute.snapshot.data.currentUser as firebase.User;
    this.currentSessionClaims = this.activatedRoute.snapshot.data.currentSessionClaims as SessionClaims;
    this.tenantId = this.activatedRoute.snapshot.params.tenantId;

    this.currentStore$ = this.currentStoreService.currentStore$;
    this.currentCustomer$ = this.currentCustomerService.currentCustomer$;

    router.events.pipe(
      filter(evt => evt instanceof NavigationEnd)
    ).subscribe((evt) => {
      let route = activatedRoute;
      while (route?.firstChild && (evt as NavigationEnd).urlAfterRedirects.indexOf(this.getResolvedUrl(route.firstChild.snapshot)) === 0) {
        route = route.firstChild;
      }
      this.firstChild = route;

      this.backButtonRouterLink = this.firstChild?.snapshot.data.backButtonRouterLink;
      const storeIdParam = this.firstChild?.snapshot.params.storeId;

      if (this.currentStoreService.currentStore === null && storeIdParam) {
        this.setCurrentStore(storeIdParam);
      }
    });

    mainToolbarTitleService.title$.subscribe((t) => this.title = t);
    mainToolbarTitleService.subtitle$.subscribe((t) => this.subtitle = t);

    this.notificationsCount$ = this.angularFirestore.collection<Notification>(`tenants/${this.tenantId}/customers/${this.currentUser.uid}/notifications`, ref => ref.where('read', '==', false).where("archived", "==", false)).valueChanges().pipe(map((results) => results.length > 0 ? results.length : null));
  }

  ngOnInit(): void {

  }

  private getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/');
  }

  async logout(): Promise<void> {
    await this.angularFireAuth.signOut();
    this.router.navigate(['/' + this.tenantId + '/login']);
    await this.esploravinoApiService.deleteSession(this.tenantId, this.currentSessionClaims).pipe(first()).toPromise();
    this.currentStoreService.setCurrentStore(null);
    this.currentCustomerService.clearCurrentCustomer(this.currentSessionClaims);
  }

  goBack(): void {
    this.router.navigate(this.backButtonRouterLink, { relativeTo: this.firstChild });
  }

  private async setCurrentStore(storeId: string): Promise<void> {
    const store = await from(this.currentUser.getIdToken()).pipe(switchMap((token) => this.esploravinoApiService.getStore(this.tenantId, this.currentSessionClaims.customerId, storeId, token, this.currentSessionClaims))).toPromise()
    this.currentStoreService.setCurrentStore(store);
  }

  clearCurrentStoreAndGoToStoreSelector(currentStoreId: string): void {
    this.currentStoreService.setCurrentStore(null);
    this.router.navigate([this.getStoreSelectorPath(currentStoreId)]);
  }

  getStoreSelectorPath(currentStoreId: string): string {
    const idx = location.pathname.indexOf(currentStoreId);
    if (idx >= 0) {
      return location.pathname.substring(0, idx - 1);
    } else {
      return '/' + this.tenantId
    }
  }

  clearCurrentCustomerAndStoreAndGoToCustomerSelector(currentCustomerId: string): void {
    this.currentCustomerService.clearCurrentCustomer(this.currentSessionClaims);
    this.currentStoreService.setCurrentStore(null);
    this.router.navigate([this.getCustomerSelectorPath(currentCustomerId)]);
  }

  getCustomerSelectorPath(currentCustomerId: string): string {
    const idx = location.pathname.indexOf(currentCustomerId);
    if (idx >= 0) {
      return location.pathname.substring(0, idx - 1);
    } else {
      return '/' + this.tenantId
    }
  }
}
