<div class="container-max-width">
    <ng-container *ngIf="posts$ | async; let posts">


        <mat-toolbar>
            <mat-chip-list *ngIf="tag$ | async; let tag; else: allNewsTitle">
                <mat-chip (click)="setTag(tag)" [removable]="true" (removed)="setTag(null)">
                    <ng-container *ngIf="tag !== FEATURED_TAG; else featuredTagIccon">
                        <mat-icon>local_offer</mat-icon>
                    </ng-container>
                    <ng-template #featuredTagIccon>
                        <mat-icon>star</mat-icon>
                    </ng-template>
                    &nbsp;&nbsp;{{getTagDisplayText(tag)}}&nbsp;&nbsp;
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
            </mat-chip-list>

            <ng-template #allNewsTitle>
                Tutte le Notizie & Eventi
            </ng-template>

            <div fxFlex></div>

            <button mat-icon-button aria-label="Filtra" [matMenuTriggerFor]="tagsMenu">
                <mat-icon>filter_list</mat-icon>
            </button>
            <mat-menu #tagsMenu="matMenu">
                <button mat-menu-item (click)="setTag(FEATURED_TAG)">
                    <mat-icon>star</mat-icon>
                    <span>In Evidenza</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="setTag(tag)" *ngFor="let tag of getTags(posts)">
                    <mat-icon>local_offer</mat-icon>
                    <span>{{getTagDisplayText(tag)}}</span>
                </button>
            </mat-menu>
        </mat-toolbar>

        <div *ngIf="filterPostsByTag(posts, tag$ | async); let filteredPosts">
            <div class="posts-container" [ngClass.xs]="'xs'">
                <div class="posts-container" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center center">
                    <a routerLink="/{{ currentSessionClaims.tenantId }}/news/post/{{post.url | base64url}}"
                        class="link-unstyled post" [ngClass]="{'featured':isFeatured(post)}"
                        *ngFor="let post of filteredPosts">
                        <mat-card>
                            <div class="tri">
                                <mat-icon *ngIf="isFeatured(post)">star</mat-icon>
                            </div>
                            <mat-card-header>
                                <mat-card-subtitle>
                                    {{post.date | date:'dd MMMM yyyy'}}
                                </mat-card-subtitle>
                                <mat-card-title>
                                    {{post.title}}
                                </mat-card-title>

                            </mat-card-header>
                            <img mat-card-image [src]=" getSafeUrl(post.imageUrl)">
                        </mat-card>
                    </a>
                </div>
            </div>
        </div>
    </ng-container>
</div>