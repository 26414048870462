<div class="container-max-width">
  <ng-container *ngIf="paymentMethods$ | async; let paymentMethods; else: spinner">
    <div class="container-with-padding">
      <div class="card-container" *ngFor="let paymentMethod of paymentMethods">
        <app-payment-method-card [paymentMethod]="paymentMethod" [disabled]="disabled" [deleteDisabled]="
          !hasAtLeastAnotherValidPaymentMethod(paymentMethod, paymentMethods)
        " (deleteClick)="onPaymentMethodDeleteClick(paymentMethod)"
          (starClick)="onPaymentMethodStarClick(paymentMethod)"
          (detailsClick)="onPaymentMethodDetailsClick(paymentMethod)"></app-payment-method-card>
      </div>
    </div>
    <app-empty-list-message *ngIf="paymentMethods.length === 0">
      Nessun metodo di pagamento registrato.
    </app-empty-list-message>
  </ng-container>
</div>

<ng-template #spinner>
  <app-fullscreen-spinner></app-fullscreen-spinner>
</ng-template>


<div class="extended-fab-spacer"></div>

<div class="extended-fab-container">
  <a mat-raised-button color="accent" routerLink="./new">
    <mat-icon>add</mat-icon>Aggiungi Carta di Credito
  </a>
</div>