<h2 mat-dialog-title>Dettaglio Carta</h2>
<mat-dialog-content class="mat-typography">
  <form>
    <mat-form-field
      class="input-full-width"
      appearance="outline"
      color="accent"
    >
      <mat-label>Ultime Cifre</mat-label>
      <input
        matInput
        [value]="data.paymentMethod.lastDigits"
        name="lastDigits"
        readonly
      />
    </mat-form-field>

    <mat-form-field
      class="input-full-width"
      appearance="outline"
      color="accent"
    >
      <mat-label>Scadenza</mat-label>
      <input
        matInput
        [value]="
          data.paymentMethod.expirationMonth +
          '/' +
          data.paymentMethod.expirationYear
        "
        name="funding"
        readonly
      />
    </mat-form-field>

    <mat-form-field
      class="input-full-width"
      appearance="outline"
      color="accent"
    >
      <mat-label>Tipo</mat-label>
      <input
        matInput
        [value]="data.paymentMethod.funding"
        name="funding"
        readonly
      />
    </mat-form-field>

    <mat-form-field
      class="input-full-width"
      appearance="outline"
      color="accent"
    >
      <mat-label>Circuito</mat-label>
      <input
        matInput
        [value]="data.paymentMethod.brand"
        name="brand"
        readonly
      />
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-bottom: -12px">
  <button
    mat-raised-button
    color="accent"
    mat-button
    mat-dialog-close
    cdkFocusInitial
  >
    OK
  </button>
</mat-dialog-actions>
