<div class="spinner-container" [style.background-color]="backgroundColor ? backgroundColor : '#eee'">
  <div style="
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      place-content: center;
      align-items: flex-start;
      align-content: space-around;
      justify-content: center;
      height: 100%;
      flex-wrap: wrap;
    ">
    <mat-spinner [diameter]="32" color="accent" style="margin: 64px"></mat-spinner>
  </div>
</div>