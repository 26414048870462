import { Component, Inject } from '@angular/core';
import { MAT_DATE_RANGE_SELECTION_STRATEGY, DefaultMatCalendarRangeStrategy, DateRange } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-date-range-picker-dialog',
  templateUrl: './date-range-picker-dialog.component.html',
  styleUrls: ['./date-range-picker-dialog.component.scss'],
  providers: [{
    provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
    useClass: DefaultMatCalendarRangeStrategy,
  },]
})
export class DateRangePickerDialogComponent {

  dateRange: DateRange<Moment>;

  maxDate: Moment | undefined = undefined;
  minDate: Moment | undefined = undefined;

  constructor(@Inject(MAT_DIALOG_DATA) data: { dateRange: DateRange<Moment>, maxDate: Moment | undefined, minDate: Moment | undefined }, private matDialogRef: MatDialogRef<DateRangePickerDialogComponent>, private matDialog: MatDialog) {
    this.dateRange = data.dateRange;
    this.maxDate = data.maxDate;
    this.minDate = data.minDate;
  }

  onMatCalendarSelectedChange(date: Moment): void {
    if (
      this.dateRange &&
      this.dateRange.start &&
      date.isAfter(this.dateRange.start) &&
      !this.dateRange.end
    ) {
      this.dateRange = new DateRange(
        this.dateRange.start,
        date
      );
    } else {
      this.dateRange = new DateRange(date, null);
    }
  }

  ok() {
    if (!this.dateRange.start) {
      this.matDialog.open(AlertDialogComponent, { data: { title: "Periodo Personalizzato", body: 'Seleziona la data di inizio del periodo.' } });
      return;
    }

    if (!this.dateRange.end) {
      this.matDialog.open(AlertDialogComponent, { data: { title: "Periodo Personalizzato", body: 'Seleziona la data di fine del periodo.' } });
      return;
    }

    this.matDialogRef.close(this.dateRange);
  }

}
