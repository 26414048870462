<div class="container-max-width">
    <div class="banner">
        <b>Grazie!</b>
        Il pagamento è andato a buon fine.
    </div>
</div>

<div class="fab">
    <a mat-raised-button color="accent" [routerLink]="['../../..']">
        Vai alla Home<mat-icon>chevron_right</mat-icon>
    </a>
</div>

<div class="icon-container">
    <div style="
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        place-content: center;
        align-items: flex-start;
        align-content: space-around;
        justify-content: center;
        height: 100%;
        flex-wrap: wrap;
      ">
        <mat-icon>check</mat-icon>
    </div>
</div>