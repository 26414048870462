<app-page #page>
  <app-main-toolbar slot="toolbar" (menuButtonClick)="page.toggleDrawer()" (moreMenuButtonClick)="page.closeDrawer()">
  </app-main-toolbar>

  <mat-nav-list slot="drawer" (click)="page.closeDrawer()" style="padding-top: 0;">
    <a mat-list-item class="drawer-link" routerLink="/{{ tenantId }}" routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: true}">
      <mat-icon matListIcon>home</mat-icon> Home
    </a>
    <a mat-list-item class="drawer-link" routerLink="/{{ tenantId }}/news" routerLinkActive="active">
      <mat-icon matListIcon>rss_feed</mat-icon> News & Eventi
    </a>
    <mat-divider></mat-divider>
    <a mat-list-item class="drawer-link" routerLink="/{{ tenantId }}/magazzino" routerLinkActive="active"
      *ngIf="currentSessionClaims.permissions.includes('cli-magazzino')">
      <mat-icon matListIcon>liquor</mat-icon> SenseTray
    </a>
    <a mat-list-item class="drawer-link" routerLink="/{{ tenantId }}/inventario" routerLinkActive="active"
      *ngIf="currentSessionClaims.permissions.includes('cli-inventario')">
      <mat-icon matListIcon>fact_check</mat-icon> Inventario
      <span fxFlex></span>
    </a>
    <a mat-list-item class="drawer-link" routerLink="/{{ tenantId }}/carta-vini" routerLinkActive="active"
      *ngIf="currentSessionClaims.permissions.includes('cli-cartavini')">
      <mat-icon matListIcon>menu_book</mat-icon> Carta dei Vini
    </a>
    <mat-divider></mat-divider>
    <a mat-list-item class="drawer-link" routerLink="/{{ tenantId }}/metodi-pagamento" routerLinkActive="active"
      *ngIf="currentSessionClaims.permissions.includes('cli-metodipagamento')">
      <mat-icon matListIcon>credit_card</mat-icon> Metodi di Pagamento
    </a>
    <mat-divider></mat-divider>
    <a mat-list-item class="drawer-link" routerLink="/{{ tenantId }}/movimenti-magazzino" routerLinkActive="active"
      *ngIf="currentSessionClaims.permissions.includes('cli-movimentimagazzino')">
      <mat-icon matListIcon>move_up</mat-icon> Movimenti
      Magazzino
      <span fxFlex></span>
      <div class="badge-container"></div>
    </a>
    <mat-divider></mat-divider>
    <a mat-list-item class="drawer-link" routerLink="/{{ tenantId }}/notifications" routerLinkActive="active"
      *ngIf="!currentSessionClaims.salesRepresentativeId">
      <mat-icon matListIcon>notifications</mat-icon> Notifiche
      <span fxFlex></span>
    </a>
  </mat-nav-list>

  <ngx-bottom-nav class="bottom-nav" slot="navbar">
    <button *ngIf="currentSessionClaims.permissions.includes('cli-magazzino')" ngx-bottom-nav label="SenseTray"
      routerLink="/{{ tenantId }}/magazzino" style="color: #8e3156; width: 25%">
      <mat-icon ngxBottomNavIcon>liquor</mat-icon>
    </button>
    <button *ngIf="currentSessionClaims.permissions.includes('cli-inventario')" ngx-bottom-nav label="Inventario"
      routerLink="/{{ tenantId }}/inventario" style="color: #8e3156; width: 25%">
      <mat-icon ngxBottomNavIcon>fact_check</mat-icon>
    </button>
    <button *ngIf="currentSessionClaims.permissions.includes('cli-cartavini')" ngx-bottom-nav label="Carta dei Vini"
      routerLink="/{{ tenantId }}/carta-vini" style="color: #8e3156; width: 25%">
      <mat-icon ngxBottomNavIcon>menu_book</mat-icon>
    </button>
    <button *ngIf="currentSessionClaims.permissions.includes('cli-movimentimagazzino')" ngx-bottom-nav label="Movimenti"
      routerLink="/{{ tenantId }}/movimenti-magazzino" style="color: #8e3156; width: 25%">
      <mat-icon ngxBottomNavIcon>move_up</mat-icon>
    </button>
  </ngx-bottom-nav>
</app-page>