export const environment = {
  id: 'esploravino-dev',
  production: false,
  serviceWorkerEnabled: true,
  google: {
    apiKey: 'AIzaSyC6loaLNVkA2ctT9qpcL0WgTEu3Lm_6S5w',
    authDomain: 'esploravino-dev.firebaseapp.com',
    projectId: 'esploravino-dev',
    storageBucket: 'esploravino-dev.appspot.com',
    messagingSenderId: '48973298201',
    appId: '1:48973298201:web:51209f1b82b1ce6a7ec888',
    measurementId: "G-DTLFMG9M85"
  },
  enumeraUiBaseUrl: 'https://enumera.app-dev.esploravino.com/enumeraevino.php',
  enumeraLoginBaseUrl: 'https://enumera.app-dev.esploravino.com/login-evino.php',
  esploravinoApiBaseUrl: 'https://esploravino-dev.oa.r.appspot.com/v1',
  defaultTenantId: 'EVDEV',
  firebaseAuthTenantIdsMap: {
    EVINO: 'EVINO-9mli4',
    EVDEV: 'EVDEV-275fc'
  },
  stripe: {
    publishableKey: 'pk_test_51JAeB5GLT1rlaq1f4aWndAVJkxiyJDzPakytUXpCzAM9KKYhCXujwHrxRjCKctr0TE9lpbQrOPLwAeH4E7cFTmci00gxdfECbd'
  }
};
