import { SnackBarService } from './../snack-bar.service';
import { EsploravinoApiService } from './../esploravino-api.service';
import { MatDialog } from '@angular/material/dialog';
import { Store } from './../models/store';
import { CartaVini } from './../models/carta-vini';
import { Component, OnInit } from '@angular/core';
import { from, NEVER, Observable, Subject } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionClaims } from '../models/session-claims';
import { distinct, filter, map, pairwise, skip, switchMap } from 'rxjs/operators';
import { CartaViniQrDialogComponent } from '../carta-vini-qr-dialog/carta-vini-qr-dialog.component';

import firebase from 'firebase/app';
import 'firebase/auth';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-carta-vini',
  templateUrl: './carta-vini.component.html',
  styleUrls: ['./carta-vini.component.scss']
})
export class CartaViniComponent implements OnInit {

  wineList$: Observable<CartaVini>;

  private wineListsCollection: AngularFirestoreCollection<CartaVini>;

  disabled = false;

  constructor(private angularFirestore: AngularFirestore, private activatedRoute: ActivatedRoute, private router: Router, private snackBarService: SnackBarService, private matDialog: MatDialog, private esploravinoApiService: EsploravinoApiService) {
    const tenantId = this.activatedRoute.parent?.parent?.snapshot.params.tenantId;
    const sessionClaims = this.activatedRoute.parent?.parent?.snapshot.data.currentSessionClaims as SessionClaims;
    const currentUser = this.activatedRoute.parent?.parent?.snapshot.data.currentUser as firebase.User;
    const storeId: string = this.activatedRoute.snapshot.params.storeId;
    const cartaId: string = this.activatedRoute.snapshot.params.cartaId;

    this.wineListsCollection = this.angularFirestore.collection<CartaVini>(`tenants/${tenantId}/customers/${sessionClaims.customerId}/stores/${storeId}/wine-lists`);

    if (cartaId) {
      const wineListDocument = this.wineListsCollection.doc(cartaId);
      this.wineList$ = wineListDocument.valueChanges() as Observable<CartaVini>;
      this.wineList$.pipe(filter((wl) => wl ? true : false)).pipe(map((wl) => wl.renderingPdf)).pipe(pairwise()).subscribe((pair) => {
        if (pair[0] === true && pair[1] === false) {
          this.snackBarService.showSuccess('Carta dei Vini pubblicata.');
        }
      });
    } else {
      this.wineList$ = NEVER;
      this.loadOrCreateDefaultWineList(storeId, sessionClaims, currentUser);
    }
  }

  ngOnInit(): void {
  }

  private async loadOrCreateDefaultWineList(storeId: string, sessionClaims: SessionClaims, currentUser: firebase.User): Promise<void> {
    const wineListsQuerySnapshots = await this.wineListsCollection.get().toPromise();
    let wineListDocument: AngularFirestoreDocument<CartaVini>;
    if (wineListsQuerySnapshots.size > 0) {
      wineListDocument = this.angularFirestore.doc(wineListsQuerySnapshots.docs[0].ref);
    } else {
      const store = await from(currentUser.getIdToken()).pipe(switchMap((token) => this.esploravinoApiService.getStore(sessionClaims.tenantId, sessionClaims.customerId, storeId, token, sessionClaims))).toPromise();
      wineListDocument = this.wineListsCollection.doc();
      await wineListDocument.set({ id: wineListDocument.ref.id, title: 'Carta dei Vini ' + store.name, image: null });
    }

    const wineList = await wineListDocument.get().pipe(map((d) => d.data() as CartaVini)).toPromise();

    this.router.navigate([wineList.id], { replaceUrl: true, relativeTo: this.activatedRoute });

  }

  async deleteWineList(wineList: CartaVini): Promise<void> {
    this.disabled = true;
    const result = await this.matDialog.open(ConfirmDialogComponent, { data: { title: 'Eliminazione Carta dei Vini', body: `Sei veramente sicuro di voler eliminare la Carta dei Vini "${wineList.title}"?\nPotrai sempre crearne una nuova successivamente.`, confirmButtonLabel: 'Sì, elimina' } }).afterClosed().toPromise();
    if (result) {
      this.snackBarService.showInProgress('Eliminazione Carta dei Vini in corso...');
      const wineListDocument = this.wineListsCollection.doc<CartaVini>(wineList.id);
      try {
        await wineListDocument.delete();
        this.snackBarService.showSuccess('Carta dei Vini eliminata.');
        this.router.navigate(['./../../'], { replaceUrl: true, relativeTo: this.activatedRoute });
      } catch (e) {
        console.error(e);
        this.snackBarService.showError();
      }
    }
    this.disabled = false;
  }

  async shareWineList(wineList: CartaVini): Promise<void> {
    if ('share' in navigator) {
      navigator.share({
        url: wineList.url,
        title: wineList.title
      });
    } else {
      // TODO: modale con campo per copia manuale

      const clipboardApiSupported = 'clipboard' in navigator && 'writeText' in (navigator as Navigator).clipboard;
      if (clipboardApiSupported) {
        await (navigator as Navigator).clipboard.writeText(wineList.url as string);
      }
      await this.matDialog.open(AlertDialogComponent, { data: { title: wineList.title, body: 'La Carta dei Vini è disponibile a questo indirizzo\n\n' + wineList.url + (clipboardApiSupported ? '\n\nL\'indirizzo è stato copiato negli appunti, puoi incollarlo dove vuoi.' : '') } }).afterClosed().toPromise();
    }
  }

  downloadWineList(wineList: CartaVini): void {
    window.open(wineList.pdfUrl, '_blank');
  }

  showQrWineList(wineList: CartaVini): void {
    this.matDialog.open(CartaViniQrDialogComponent, { data: { url: wineList.url, title: wineList.title } });
  }

  async publishWineList(wineList: CartaVini): Promise<void> {
    const tenantId = this.activatedRoute.parent?.parent?.snapshot.params.tenantId;
    const sessionClaims = this.activatedRoute.parent?.parent?.snapshot.data.currentSessionClaims as SessionClaims;
    const currentUser = this.activatedRoute.parent?.parent?.snapshot.data.currentUser as firebase.User;
    const storeId: string = this.activatedRoute.snapshot.params.storeId;
    const cartaId: string = this.activatedRoute.snapshot.params.cartaId;

    this.disabled = true;

    wineList.renderingPdf = true;

    try {
      await from(currentUser.getIdToken()).pipe(switchMap((token) => this.esploravinoApiService.renderWineListPdf(tenantId, sessionClaims.customerId, storeId, cartaId, token, sessionClaims))).toPromise();
    } catch (e) {
      console.error(e);
      this.snackBarService.showError();
    }

    this.disabled = false;
  }

}
