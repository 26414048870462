<div class="container-max-width">
    <div class="container">
        <ng-container *ngIf="notification$ | async; let notification; else: spinner">

            <h3 class="title">{{notification.subject}}</h3>
            <p class="body">
                {{notification.body}}
            </p>

            <ng-container *ngIf="notification.action && notification.actionUrl">
                <div class="extended-fab-spacer"></div>

                <div class="extended-fab-container">
                    <a mat-raised-button [href]="notification.actionUrl" color="accent">
                        <mat-icon>send</mat-icon>
                        <ng-container>
                            {{notification.action}}
                        </ng-container>
                    </a>
                </div>
            </ng-container>

        </ng-container>
    </div>

</div>

<ng-template #spinner>
    <app-fullscreen-spinner></app-fullscreen-spinner>
</ng-template>