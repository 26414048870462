<ng-container *ngIf="wineList$ | async; let wineList; else: spinner">
  <ng-container *ngIf="wines$ | async; let wines; else: spinner">
    <ng-container *ngIf="wineCategories$ | async; let wineCategories; else: spinner">
      <ng-container *ngFor="let wineCategory of wineCategories">
        <ng-container *ngIf="getViniByCategory(wines, wineCategory.id); let filteredWines">
          <div *ngIf="filteredWines.length > 0">
            <mat-nav-list>
              <h3 matSubheader class="wine-category">
                {{ wineCategory.name }}
              </h3>
              <ng-container *ngFor="
                  let wine of filteredWines;
                  let last = last;
                  let first = first
                ">
                <app-carta-vini-wine-list-item [vino]="wine" [disabled]="wineList.renderingPdf ? true : false"
                  (showClick)="setWineHidden(false, wine)" (hideClick)="setWineHidden(true, wine)"
                  (deleteClick)="deleteWine(wine)" (contextMenuOpen)="wines$.pause()"
                  (contextMenuClose)="wines$.resume()"></app-carta-vini-wine-list-item>
                <mat-divider></mat-divider>
              </ng-container>
            </mat-nav-list>
          </div>
        </ng-container>
      </ng-container>

      <div class="extended-fab-spacer"></div>

      <app-empty-list-message *ngIf="wines.length === 0">
        Nessun vino in questa Carta dei Vini.
      </app-empty-list-message>

      <div class="extended-fab-container" *ngIf="!wineList.renderingPdf">
        <button mat-raised-button color="accent" (click)="showAddVinoBottomSheet(wines)">
          <mat-icon>add</mat-icon>Aggiungi Vino
        </button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #spinner>
  <app-fullscreen-spinner></app-fullscreen-spinner>
</ng-template>