<ng-container *ngIf="wineList$ | async; let wineList; else: spinner">
  <div class="container-with-padding">
    <form fxLayout="column" (submit)="saveWineList(wineList, myForm.valid)" #myForm="ngForm">
      <mat-form-field class="input-full-width" appearance="fill" color="accent">
        <mat-label>Titolo</mat-label>
        <input matInput [(ngModel)]="wineList.title" name="title" required
          [readonly]="disabled || (wineList.renderingPdf ? true : false)" />
      </mat-form-field>

      <h3 class="section-title">Logo</h3>
      <div style="height: 175px">
        <app-image-form-field [image]="wineList.image"
          (imageChange)="wineList.image = $event; dataOutsideFormChanged = true" [basePath]="firebaseStorageBasePath"
          [disabled]="disabled || (wineList.renderingPdf ? true : false)" label="Logo">
        </app-image-form-field>
      </div>
      <small class="logo-help-text">Per risultati ottimali, carica un logo con dimensioni almeno di
        200px</small>

      <h3 class="section-title">Tema</h3>
      <div fxLayout="row wrap" style="margin-right: -16px;">
        <ng-container *ngFor=" let theme of themes">
          <mat-card class="theme-card" (click)="changeTheme(theme.id, wineList)" fxFlex.gt-sm
            fxFlex.sm="calc(50% - 16px)" fxFlex.xs="calc(50% - 16px)"
            [ngClass]="{'selected':wineList.theme === theme.id, 'disabled':disabled || (wineList.renderingPdf ? true : false)}"
            [style.background-image]="'url(/assets/wine-list-themes/'+theme.id+'.png)'">
            <div class="icon">
              <mat-icon *ngIf="wineList.theme === theme.id" [color]="wineList.theme === theme.id ? 'primary':'accent'">
                radio_button_checked</mat-icon>
              <mat-icon *ngIf="wineList.theme !== theme.id" color="accent">radio_button_unchecked</mat-icon>
              <div class="tri"></div>
            </div>
            <div class="title">{{theme.name}}</div>

          </mat-card>
        </ng-container>
      </div>

      <div class="extended-fab-container">
        <button *ngIf="myForm.dirty || dataOutsideFormChanged" type="submit" mat-raised-button color="accent"
          [disabled]="disabled">
          <mat-icon>check</mat-icon>Salva
        </button>
      </div>
    </form>

    <div class="extended-fab-spacer"></div>
  </div>
</ng-container>

<ng-template #spinner>
  <app-fullscreen-spinner></app-fullscreen-spinner>
</ng-template>