<mat-card (click)="detailsClick.emit()">
  <div fxLayout="row" fxLayoutAlign="end center">
    <div class="main-icon-container">
      <img
        src="/assets/card-brands/{{ paymentMethod.brand }}.png"
        class="main-icon"
      />
      <mat-icon class="valid-icon" *ngIf="paymentMethod.valid === true"
        >check</mat-icon
      >
      <mat-icon class="invalid-icon" *ngIf="paymentMethod.valid === false"
        >priority_high</mat-icon
      >
    </div>

    <mat-icon
      *ngIf="paymentMethod.preferred"
      mat-icon-button
      color="accent"
      style="margin: 0 8px 0 12px"
      >star</mat-icon
    >

    <div fxFlex class="credit-card-data">
      <div class="number">
        <mat-icon class="dot">circle</mat-icon
        ><mat-icon class="dot">circle</mat-icon
        ><mat-icon class="dot">circle</mat-icon>
        <mat-icon class="dot">circle</mat-icon
        ><mat-icon class="dot">circle</mat-icon
        ><mat-icon class="dot">circle</mat-icon
        ><mat-icon class="dot">circle</mat-icon
        ><mat-icon class="dot">circle</mat-icon
        ><mat-icon class="dot">circle</mat-icon
        ><span class="spacer">&nbsp;</span>
        <span class="last-digits">{{ paymentMethod.lastDigits }}</span>
      </div>
      <div class="expiration">
        scad.
        <b class="date" [class.invalid]="paymentMethod.valid === false"
          >{{ paymentMethod.expirationMonth | number: "2.0-0" }}/{{
            paymentMethod.expirationYear
          }}</b
        >
      </div>
    </div>

    <button
      mat-icon-button
      aria-label="Apri menu contestuale"
      [disabled]="disabled"
      [matMenuTriggerFor]="menu"
      style="margin-left: 8px"
      (click)="$event.stopPropagation(); $event.preventDefault()"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      [disabled]="paymentMethod.preferred"
      (click)="starClick.emit()"
    >
      <mat-icon>star</mat-icon>
      <span>Imposta come preferito</span>
    </button>
    <button
      mat-menu-item
      [disabled]="deleteDisabled"
      (click)="deleteClick.emit()"
    >
      <mat-icon>delete</mat-icon>
      <span>Elimina</span>
    </button>
    <button mat-menu-item (click)="detailsClick.emit()">
      <mat-icon>more_horiz</mat-icon>
      <span>Altri dettagli</span>
    </button>
  </mat-menu>
</mat-card>
