<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <p class="body">
    {{ data.body }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-stroked-button mat-dialog-close>
    {{ data.cancelButtonLabel ? data.cancelButtonLabel : "Annulla" }}
  </button>
  <span fxFlex>&nbsp;</span>
  <button
    mat-button
    mat-raised-button
    [mat-dialog-close]="true"
    color="accent"
    cdkFocusInitial
  >
    {{ data.confirmButtonLabel ? data.confirmButtonLabel : "OK" }}
  </button>
</mat-dialog-actions>
