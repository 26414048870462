import { AfterViewInit, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { EsploravinoApiService } from '../esploravino-api.service';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { SessionClaims } from '../models/session-claims';

import firebase from 'firebase/app';
import 'firebase/auth';
import { NEVER, Observable, Subject } from 'rxjs';
import { InventoryTransaction, InventoryTransactionItem } from '../models/inventory-transaction';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { debounceTime, first, share, switchMap } from 'rxjs/operators';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { DateRangePickerDialogComponent } from '../date-range-picker-dialog/date-range-picker-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SnackBarService } from '../snack-bar.service';
import { MovimentiMagazzinoWarningDialogComponent } from '../movimenti-magazzino-warning-dialog/movimenti-magazzino-warning-dialog.component';
import { InventoryItem } from '../models/inventory-item';
import { MovimentiMagazzinoTrackingDialogComponent } from '../movimenti-magazzino-tracking-dialog/movimenti-magazzino-tracking-dialog.component';
import { Moment } from 'moment';
import * as moment from 'moment';
import { format } from 'path';

@Component({
  selector: 'app-movimenti-magazzino',
  templateUrl: './movimenti-magazzino.component.html',
  styleUrls: ['./movimenti-magazzino.component.scss'],
  animations: [
    trigger('itemExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MovimentiMagazzinoComponent implements AfterViewInit {

  tenantId: string;

  currentSessionClaims: SessionClaims;

  displayedColumns: string[];

  items$: Observable<InventoryTransaction[]> = NEVER;
  itemsSnapshot: InventoryTransaction[] | null = null;
  filteredItemsForAutocomplete$: Observable<InventoryItem[]> = NEVER;

  expandedItem: InventoryTransaction | null = null;

  dateRange: DateRange<Moment> | null = null;
  search: string | null = null;
  searchItem: InventoryItem | null = null;

  searchSubject: Subject<String | null>;

  @ViewChildren('searchInput')
  private searchInput!: QueryList<ElementRef>;
  private searchInputFocusRequested = false;

  private currentUser: firebase.User;
  private storeId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private mainToolbarTitleService: MainToolbarTitleService,
    private esploravinoApiService: EsploravinoApiService,
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private matDialog: MatDialog,
    private snackBarService: SnackBarService
  ) {
    this.storeId = this.activatedRoute.snapshot.params.storeId;
    this.currentSessionClaims = this.activatedRoute.snapshot.parent?.parent?.data.currentSessionClaims as SessionClaims;

    this.displayedColumns = ['descrizione', 'carichi', 'giacenza', 'consumi', 'actions'];

    this.tenantId = this.activatedRoute.snapshot.parent?.parent?.params.tenantId;
    this.currentUser = this.activatedRoute.parent?.parent?.snapshot.data.currentUser as firebase.User;

    this.mainToolbarTitleService.setTitle('Movimenti Magazzino');
    this.mainToolbarTitleService.setSubtitle(this.storeId);

    this.searchSubject = new Subject<String | null>();
    this.searchSubject.asObservable().pipe(debounceTime(200)).subscribe((s) => {
      this.router.navigate([], { queryParams: { search: s }, queryParamsHandling: 'merge' });
    });

    this.currentUser.getIdToken().then((idToken) => {
      this.items$ = this.activatedRoute.queryParams.pipe(switchMap((params) => {

        if (params.start && params.end) {
          try {
            this.dateRange = new DateRange(moment(params.start, "YYYY-MM-DD"), moment(params.end, "YYYY-MM-DD"));
          } catch (e) {
            console.error("Error while parsing start & end dates", e);
            this.resetDateRange();
          }
        } else {
          this.dateRange = null;
        }

        this.search = params.search;

        return esploravinoApiService.listInventoryTransactions(this.tenantId, this.currentSessionClaims.customerId, this.storeId, idToken, this.currentSessionClaims, { start: params.start, end: params.end, item: params.search });
      }))
        .pipe(share());

      this.filteredItemsForAutocomplete$ = this.activatedRoute.queryParams.pipe(switchMap((params) =>
        esploravinoApiService.listInventoryItems(this.tenantId, this.currentSessionClaims.customerId, this.storeId, idToken, this.currentSessionClaims, { start: params.start, end: params.end })
      ));

      this.items$.subscribe((items) => {
        this.itemsSnapshot = items;
        if (this.activatedRoute.snapshot.queryParams['documentSeries'] && this.activatedRoute.snapshot.queryParams['documentNumber']) {
          this.expandedItem = items.find((item) => item.documentSeries === this.activatedRoute.snapshot.queryParams['documentSeries'] && item.documentNumber === this.activatedRoute.snapshot.queryParams['documentNumber']) ?? null;
        }
        if (this.activatedRoute.snapshot.queryParams['search']) {
          this.filteredItemsForAutocomplete$.pipe(first()).subscribe((items) => {
            this.searchItem = items.find((item) => item.id === this.activatedRoute.snapshot.queryParams['search']) ?? null;
          });
        }
      });

      esploravinoApiService.getStore(this.tenantId, this.currentSessionClaims.customerId, this.storeId, idToken, this.currentSessionClaims).toPromise().then((store) => this.mainToolbarTitleService.setSubtitle(store.name));
    });
  }

  isToday(date: Moment | null) {
    if (!date) return false;
    return new Date().toDateString() === date.toDate().toDateString();
  }

  isUnixTimeStart(date: Moment | null) {
    if (!date) return false;
    return date.toDate().getFullYear() === 1970 && date.toDate().getMonth() === 0 && date.toDate().getDate() === 1;
  }

  filterItemsForAutocomplete(items: InventoryItem[] | null, s: string | null): InventoryItem[] | null {
    return items !== null ? items.filter((item) => s ? (item.id.toLowerCase().includes(s.trim().toLowerCase()) || item.description.toLowerCase().includes(s.trim().toLowerCase())) : true) : null;
  }

  ngAfterViewInit(): void {
    this.searchInput.changes.subscribe(_ => {
      if (this.searchInputFocusRequested && this.searchInput.length > 0) {
        this.searchInput.first.nativeElement.focus();
        this.searchInputFocusRequested = false;
      }
    });
  }
  async showDateRangePickerDialog(): Promise<void> {

    const intialDateRange = this.dateRange ? this.dateRange : this.itemsSnapshot && this.itemsSnapshot.length > 0 ? new DateRange(moment(this.itemsSnapshot[0].date, "YYYY-MM-DD"), moment()) : null;

    const dateRange: DateRange<Moment> = await this.matDialog.open(DateRangePickerDialogComponent, {
      data: {
        dateRange: intialDateRange,
        maxDate: moment()
      }
    }).afterClosed().toPromise();

    if (dateRange != null) {
      const startString = dateRange.start?.format("YYYY-MM-DD");
      const endString = dateRange.end?.format("YYYY-MM-DD");
      this.router.navigate([], { queryParams: { start: startString, end: endString }, queryParamsHandling: 'merge' });
    }
  }

  async maximizeDateRange(): Promise<void> {

    const dateRange: DateRange<Moment> = new DateRange(moment(0), moment())

    const startString = dateRange.start?.format("YYYY-MM-DD");
    const endString = dateRange.end?.format("YYYY-MM-DD");
    this.router.navigate([], { queryParams: { start: startString, end: endString }, queryParamsHandling: 'merge' });

  }

  resetDateRange(): void {
    this.router.navigate([], { queryParams: { start: null, end: null }, queryParamsHandling: 'merge' });
  }

  resetSearch(): void {
    this.search = null;
    this.router.navigate([], { queryParams: { search: null }, queryParamsHandling: 'merge' });
    this.searchItem = null;
  }

  onSearchChange(): void {
    this.searchSubject.next(this.search);
  }

  openSearchBar(): void {
    this.search = "";
    this.searchInputFocusRequested = true;
  }

  opeAttachment(url: string): void {
    window.open(url, '_blank');
  }

  getAttachmentName(url: string) {
    return url.split('/').pop();
  }

  async openTracking(transaction: InventoryTransaction): Promise<void> {
    const confirmIntent: boolean = await this.matDialog.open(MovimentiMagazzinoTrackingDialogComponent, { data: { transaction, salesRepresentativeId: this.currentSessionClaims.salesRepresentativeId } }).afterClosed().toPromise();
    if (confirmIntent === true) {
      this.showConfirmDialog(transaction);
    } else if (confirmIntent === false) {
      this.showWarningDialog(transaction);
    }
  }

  async showConfirmDialog(transaction: InventoryTransaction): Promise<void> {
    const documentName = transaction.documentSeries + ": " + transaction.documentNumber;
    const documentDate = moment(transaction.documentDate, "YYYY-MM-DD").format("DD/MM/YYYY");

    const result = await this.matDialog.open(ConfirmDialogComponent, { data: { title: 'Conferma documento ' + documentName, body: `Hai ricevuto le bottiglie che ti abbiamo spedito con il documento  ${documentName} del ${documentDate}?\nGrazie per una conferma.`, confirmButtonLabel: 'Sì, conferma' } }).afterClosed().toPromise();
    if (result) {
      this.snackBarService.showInProgress('Conferma documento in corso...');

      const confirmationDateBackup = transaction.confirmationDate;
      transaction.confirmationDate = new Date().toISOString().substring(0, 10);
      try {

        const idToken = await this.currentUser.getIdToken();
        await this.esploravinoApiService.updateInventoryTransaction(this.tenantId, this.currentSessionClaims.customerId, this.storeId, idToken, this.currentSessionClaims, transaction).toPromise();

        this.snackBarService.showSuccess('Documento confermato.');
      } catch (e) {
        transaction.confirmationDate = confirmationDateBackup;
        console.error(e);
        this.snackBarService.showError();
      }
    }
  }

  async showWarningDialog(transaction: InventoryTransaction): Promise<void> {
    const result = await this.matDialog.open(MovimentiMagazzinoWarningDialogComponent, { data: { transaction } }).afterClosed().toPromise();
    if (result) {
      this.snackBarService.showInProgress('Segnalazione problemi sul documento in corso...');

      const issuesDetailsBackup = transaction.issuesDetails;
      const hasIssuesBackup = transaction.hasIssues;
      const confirmationDateBackup = transaction.confirmationDate;
      transaction.confirmationDate = new Date().toISOString().substring(0, 10);
      transaction.issuesDetails = result;
      transaction.hasIssues = true;
      try {

        const idToken = await this.currentUser.getIdToken();
        await this.esploravinoApiService.updateInventoryTransaction(this.tenantId, this.currentSessionClaims.customerId, this.storeId, idToken, this.currentSessionClaims, transaction).toPromise();

        this.snackBarService.showSuccess('Documento confermato con riserva.');
      } catch (e) {
        transaction.confirmationDate = confirmationDateBackup;
        transaction.issuesDetails = issuesDetailsBackup;
        transaction.hasIssues = hasIssuesBackup;
        console.error(e);
        this.snackBarService.showError();
      }
    }
  }



}
