import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InventoryTransaction } from '../models/inventory-transaction';

@Component({
  selector: 'app-movimenti-magazzino-tracking-dialog',
  templateUrl: './movimenti-magazzino-tracking-dialog.component.html',
  styleUrls: ['./movimenti-magazzino-tracking-dialog.component.scss']
})
export class MovimentiMagazzinoTrackingDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { transaction: InventoryTransaction, salesRepresentativeId: string | null }) {

  }

  openTrackingUrl() {
    window.open(this.data.transaction.trackingUrl, '_blank');
  }

}
