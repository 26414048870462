import { Component, OnInit } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { SwPush } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public constructor(private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messages.subscribe((message: any) => {
      const notification = new Notification(message.notification.title, { body: message.notification.body, icon: message.notification.icon });
      notification.addEventListener("click", () => {
        window.location.href = message.notification.click_action;
        notification.close();
      });
    });
  }
}
