import { MainToolbarTitleService } from './../main-toolbar-title.service';
import { PaymentMethodCardDetailsDialogComponent } from './../payment-method-card-details-dialog/payment-method-card-details-dialog.component';
import { SessionClaims } from './../models/session-claims';
import { ActivatedRoute } from '@angular/router';
import { PaymentMethod } from './../models/payment-method';
import { from, Observable, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { EsploravinoApiService } from '../esploravino-api.service';

import firebase from 'firebase/app';
import 'firebase/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '../snack-bar.service';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {

  paymentMethodsSubject: Subject<PaymentMethod[]>;
  paymentMethods$: Observable<PaymentMethod[]>;

  private sessionClaims: SessionClaims;

  private currentUser: firebase.User;

  private tenantId: string;

  disabled = false;

  constructor(private esploravinoApiService: EsploravinoApiService, activatedRoute: ActivatedRoute, private snackBarService: SnackBarService, private matDialog: MatDialog, mainToolbarTitleService: MainToolbarTitleService) {
    this.sessionClaims = activatedRoute.parent?.parent?.snapshot.data.currentSessionClaims as SessionClaims;
    this.currentUser = activatedRoute.parent?.parent?.snapshot.data.currentUser as firebase.User;
    this.tenantId = activatedRoute.parent?.parent?.snapshot.params.tenantId;
    this.paymentMethodsSubject = new Subject<PaymentMethod[]>();
    this.paymentMethods$ = this.paymentMethodsSubject.asObservable();
    this.list();

    mainToolbarTitleService.setTitle('Metodi di Pagamento');
  }

  private async list(): Promise<void> {
    const token = await this.currentUser.getIdToken();
    const paymentMethods: PaymentMethod[] = await this.esploravinoApiService.listPaymentMethods(this.tenantId, this.sessionClaims.customerId, token, this.sessionClaims).toPromise();
    this.paymentMethodsSubject.next(paymentMethods);
  }

  ngOnInit(): void {
  }

  async onPaymentMethodDeleteClick(paymentMethod: PaymentMethod): Promise<void> {
    this.disabled = true;
    this.snackBarService.showInProgress('Eliminazione carta...');
    try {
      const token = await this.currentUser.getIdToken();
      await this.esploravinoApiService.deletePaymentMethod(this.tenantId, this.sessionClaims.customerId, paymentMethod.id, token, this.sessionClaims).toPromise();
      await this.list();
      this.snackBarService.showSuccess('Carta eliminata.');
    } catch (e) {
      console.error(e);
      this.snackBarService.showError();
    }
    this.disabled = false;
  }

  async onPaymentMethodStarClick(paymentMethod: PaymentMethod): Promise<void> {
    this.disabled = true;
    this.snackBarService.showInProgress('Salvataggio carta...');
    try {
      const token = await this.currentUser.getIdToken();
      await this.esploravinoApiService.preferredPaymentMethod(this.tenantId, this.sessionClaims.customerId, paymentMethod.id, token, this.sessionClaims).toPromise();
      await this.list();
      this.snackBarService.showSuccess('Carta salvata come preferita.');
    } catch (e) {
      console.error(e);
      this.snackBarService.showError();
    }
    this.disabled = false;
  }

  onPaymentMethodDetailsClick(paymentMethod: PaymentMethod): void {
    this.matDialog.open(PaymentMethodCardDetailsDialogComponent, { data: { paymentMethod } });

  }

  hasAtLeastAnotherValidPaymentMethod(paymentMethod: PaymentMethod, paymentMethods: PaymentMethod[]): boolean {
    return paymentMethods.filter((pm) => pm.valid).filter((pm) => pm.id !== paymentMethod.id).length > 0;
  }

}
