<div class="container-max-width">

    <mat-toolbar>

        <div fxLayout="column" fxFlex>
            <div>
                <b>Tutti i Movimenti</b>
            </div>
            <small fxFlex>
                <ng-container *ngIf="dateRange; else: withoutDateRange">

                    <ng-container *ngIf="isUnixTimeStart(dateRange.start); else: showStartDate">
                        dall'<b class="red">inizio</b>
                    </ng-container>
                    <ng-template #showStartDate>
                        dal
                        <b class="red">{{dateRange.start?.format('DD/MM/YYYY')}}</b>
                    </ng-template>
                    <ng-container *ngIf="isToday(dateRange.end); else: showEndDate">
                        ad <b class="red">oggi</b>
                    </ng-container>
                    <ng-template #showEndDate>
                        al <b class="red">{{dateRange.end?.format('DD/MM/YYYY')}}</b>
                    </ng-template>
                </ng-container>
                <ng-template #withoutDateRange>dal <b class="red">penultimo inventario</b> ad <b class="red">oggi</b>
                </ng-template>
            </small>
        </div>

        <button mat-icon-button aria-label="Cerca" *ngIf="search === null || search === undefined"
            (click)="openSearchBar()">
            <mat-icon>search</mat-icon>
        </button>
        <button mat-icon-button aria-label="Periodo" [matMenuTriggerFor]="dateRangeMenu">
            <mat-icon>calendar_month</mat-icon>
        </button>
        <mat-menu #dateRangeMenu="matMenu">
            <button mat-menu-item (click)="resetDateRange()">
                <mat-icon>fact_check</mat-icon>
                <span>dal Penultimo Inventario</span>
            </button>
            <button mat-menu-item (click)="showDateRangePickerDialog()">
                <mat-icon>date_range</mat-icon>
                <span>Periodo Personalizzato</span>
            </button>
            <button mat-menu-item (click)="maximizeDateRange()">
                <mat-icon>all_inclusive</mat-icon>
                <span>Tutti i Movimenti</span>
            </button>
        </mat-menu>

    </mat-toolbar>

    <mat-toolbar *ngIf="search !== null && search !== undefined">
        <mat-chip-list *ngIf="searchItem; else: exact" fxFlex>
            <mat-chip [removable]="true" (removed)="resetSearch()" [matTooltip]="searchItem.id" fxFlex>
                <span fxFlex>{{searchItem.description}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
        <ng-template #exact>
            <mat-form-field class="example-form-field" appearance="standard" fxFlex color="primary">
                <mat-label>Cerca</mat-label>
                <input matInput type="text" [(ngModel)]="search" (ngModelChange)="searchItem = null"
                    [matAutocomplete]="auto" #searchInput>
                <button matSuffix mat-icon-button aria-label="Clear" (click)="resetSearch()">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSearchChange()">
                    <mat-option
                        *ngFor="let trantactionItems of filterItemsForAutocomplete(filteredItemsForAutocomplete$ | async, search)"
                        [value]="trantactionItems.id">
                        <span>{{trantactionItems.description}}</span> |
                        <small>{{trantactionItems.id}}</small>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-template>

    </mat-toolbar>


    <ng-container *ngIf="items$ | async; let items; else: spinner">

        <table mat-table [dataSource]="items" multiTemplateDataRows class="mat-typography">

            <ng-container [matColumnDef]="'descrizione'">
                <th mat-header-cell *matHeaderCellDef>
                    <span style="font-size: 14px;">Lista Movimenti</span>
                    <ng-container *ngIf="items && items[0]">
                        <br>
                        <span style="font-weight:normal; font-size: 14px;">Giacenza iniziale</span>&nbsp;
                        <b style="font-size: 16px;">{{
                            (items[0].initialQuantity ? items[0].initialQuantity : 0)
                            - (items[0].inQuantity ? items[0].inQuantity : 0)
                            + (items[0].outQuantity ? items[0].outQuantity : 0)
                            }}</b>
                    </ng-container>
                </th>
                <td mat-cell *matCellDef="let item">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex>
                            {{item.date | date:'dd/MM/yyyy'}}
                            <br>
                            <small>
                                <b>{{item.documentSeries+": "+item.documentNumber}}</b> del {{item.documentDate |
                                date:'dd/MM/yyyy'}}
                            </small>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center" style="padding: 0 8px; cursor: pointer;"
                            (click)="$event.stopPropagation()"
                            *ngIf="item.hasIssues || (item.documentSeries === 'DCV' && !item.confirmationDate)">
                            <mat-icon matTooltip="Ci sono dei problemi su questo documento" class="red"
                                *ngIf="item.hasIssues" (click)="showWarningDialog(item)">warning
                            </mat-icon>
                            <mat-icon
                                matTooltip="Questo documento è relativo ad una spedizione che potrebbe essere ancora in transito"
                                class="red" *ngIf="item.documentSeries === 'DCV' && !item.confirmationDate"
                                (click)="openTracking(item)">
                                local_shipping</mat-icon>
                        </div>
                    </div>

                </td>
            </ng-container>

            <ng-container [matColumnDef]="'carichi'">
                <th mat-header-cell *matHeaderCellDef class="mat-cell-center">
                    <span class="rotated-container">
                        <span class="rotated">
                            <span
                                style="font-size: 24px; line-height: 24px; vertical-align: middle;">◂</span>&nbsp;Carichi
                        </span>
                    </span>
                </th>
                <td mat-cell *matCellDef="let item" class="mat-cell-center">
                    <ng-container *ngIf="item.inQuantity">
                        <span [ngClass]="{'red':item.inQuantity<0}">{{item.inQuantity}}</span>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="'giacenza'">
                <th mat-header-cell *matHeaderCellDef class="mat-cell-center">
                    <span class="rotated-container">
                        <span class="rotated">
                            <span
                                style="font-size: 24px; line-height: 24px; vertical-align: middle;">◂</span>&nbsp;Giacenza
                        </span>
                    </span>
                </th>
                <td mat-cell *matCellDef="let item" class="mat-cell-center">
                    <ng-container *ngIf="item.initialQuantity || item.initialQuantity === 0">
                        <b>{{item.initialQuantity}}</b>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="'consumi'">
                <th mat-header-cell *matHeaderCellDef class="mat-cell-center">
                    <span class="rotated-container">
                        <span class="rotated">
                            <span
                                style="font-size: 24px; line-height: 24px; vertical-align: middle;">◂</span>&nbsp;Consumi
                        </span>
                    </span>
                </th>
                <td mat-cell *matCellDef="let item" class="mat-cell-center">
                    <ng-container *ngIf="item.outQuantity">
                        <span [ngClass]="{'red':item.outQuantity<0}">{{item.outQuantity}}</span>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="'actions'">
                <th mat-header-cell *matHeaderCellDef class="mat-cell-actions"></th>
                <td mat-cell *matCellDef="let item" class="mat-cell-actions" (click)="$event.stopPropagation()">
                    <button mat-icon-button aria-label="Azioni" [matMenuTriggerFor]="actionsMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #actionsMenu="matMenu">
                        <button mat-menu-item (click)="opeAttachment(item.documentUrl)" *ngIf="item.documentUrl">
                            <mat-icon>description</mat-icon>
                            <span>Visualizza PDF</span>
                        </button>
                        <ng-container *ngIf="item.attachments && item.attachments.length>0">
                            <button mat-menu-item [matMenuTriggerFor]="attachmentsMenu">
                                <mat-icon>attachment</mat-icon>
                                <span>Allegati</span>
                            </button>
                            <mat-menu #attachmentsMenu="matMenu">
                                <button mat-menu-item (click)="opeAttachment(attachment)"
                                    *ngFor="let attachment of item.attachments">
                                    <mat-icon>description</mat-icon>
                                    <span>{{getAttachmentName(attachment)}}</span>
                                </button>
                            </mat-menu>
                        </ng-container>
                        <button mat-menu-item (click)="showConfirmDialog(item)"
                            [disabled]="currentSessionClaims.salesRepresentativeId"
                            *ngIf="item.documentSeries === 'DCV' && !item.confirmationDate">
                            <mat-icon>library_add_check</mat-icon>
                            <span>Conferma Ricezione</span>
                        </button>
                        <button mat-menu-item (click)="showWarningDialog(item)"
                            [disabled]="currentSessionClaims.salesRepresentativeId"
                            *ngIf="item.documentSeries === 'DCV' && !item.confirmationDate">
                            <mat-icon>warning</mat-icon>
                            <span>Conferma con Riserva</span>
                        </button>
                        <button mat-menu-item (click)="showWarningDialog(item)" *ngIf="item.hasIssues">
                            <mat-icon>warning</mat-icon>
                            <span>Dettagli Problema</span>
                        </button>
                        <button mat-menu-item
                            *ngIf="(item.documentSeries === 'DCV' && !item.confirmationDate) && (item.trackingUrl || item.trackingCompany || item.trackingNumber)"
                            (click)="openTracking(item)">
                            <mat-icon>local_shipping</mat-icon>
                            <span>Rintraccia Spedizione</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="'item-details'">
                <td mat-cell *matCellDef="let item" [attr.colspan]="5" style="padding:0">
                    <div class="item-details" [@itemExpand]="item == expandedItem ? 'expanded' : 'collapsed'">

                        <table mat-table [dataSource]="item.items">

                            <ng-container [matColumnDef]="'item-descrizione'">
                                <th mat-header-cell *matHeaderCellDef>Descrizione</th>
                                <td mat-cell *matCellDef="let subitem">
                                    <div [ngClass.xs]="'subitem-description-xs'">
                                        {{subitem.itemDescription}}
                                        <br>
                                        <span style="font-size: 12px">{{subitem.itemBrand}}</span>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container [matColumnDef]="'item-carichi'">
                                <th mat-header-cell *matHeaderCellDef class="mat-cell-center">Carichi</th>
                                <td mat-cell *matCellDef="let subitem" class="mat-cell-center">
                                    <ng-container *ngIf="subitem.inQuantity">
                                        <span [ngClass]="{'red':subitem.inQuantity<0}">{{subitem.inQuantity}}</span>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <ng-container [matColumnDef]="'item-giacenza'">
                                <th mat-header-cell *matHeaderCellDef class="mat-cell-center">Giacenza</th>
                                <td mat-cell *matCellDef="let subitem" class="mat-cell-center">

                                </td>
                            </ng-container>

                            <ng-container [matColumnDef]="'item-consumi'">
                                <th mat-header-cell *matHeaderCellDef class="mat-cell-center">Consumi</th>
                                <td mat-cell *matCellDef="let subitem" class="mat-cell-center">
                                    <ng-container *ngIf="subitem.outQuantity">
                                        <span [ngClass]="{'red':subitem.outQuantity<0}">{{subitem.outQuantity}}</span>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <ng-container [matColumnDef]="'item-actions'">
                                <th mat-header-cell *matHeaderCellDef class="mat-cell-actions"></th>
                                <td mat-cell *matCellDef="let subitem" class="mat-cell-actions">
                                    <span style="display: inline-block; width: 32px;"></span>
                                </td>
                            </ng-container>

                            <tr mat-row
                                *matRowDef="let subitem; columns: ['item-descrizione','item-carichi','item-giacenza','item-consumi', 'item-actions']">
                            </tr>
                        </table>

                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

            <tr mat-row *matRowDef="let item; columns: displayedColumns;" [class.expanded-row]="expandedItem === item"
                (click)="expandedItem = expandedItem === item ? null : item" class="header-row">
            </tr>

            <tr mat-row *matRowDef="let subitem; columns: ['item-details']" class="detail-row"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="5">Nessun movimento.</td>
            </tr>
        </table>

    </ng-container>

</div>

<ng-template #spinner>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner [diameter]="32" color="accent" style="margin: 64px"></mat-spinner>
    </div>
</ng-template>