<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <p class="body">
    {{ data.body }}
  </p>
  <p class="details" *ngIf="data.details">
    {{ data.details }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <span fxFlex>&nbsp;</span>
  <ng-container *ngIf="data.buttons; else: defaultButton">
    <ng-container *ngFor="let button of data.buttons; let last = last">
      <ng-container *ngIf="last; else notLastButtonChoice">
        <button mat-button mat-raised-button [mat-dialog-close]="button.key" color="accent" cdkFocusInitial>
          {{button.label}}
        </button>
      </ng-container>
      <ng-template #notLastButtonChoice>
        <button mat-button mat-raised-button [mat-dialog-close]="button.key">
          {{button.label}}
        </button>
      </ng-template>

    </ng-container>
  </ng-container>
  <ng-template #defaultButton>
    <button mat-button mat-raised-button [mat-dialog-close]="true" color="accent" cdkFocusInitial>
      Ok
    </button>
  </ng-template>

</mat-dialog-actions>