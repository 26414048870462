<div class="container-max-width">
    <ng-container *ngIf="inventoryReport$ | async; let inventoryReport; else: spinner">
        <ng-container *ngIf="wineCategories$ | async; let wineCategories; else: spinner">
            <ng-container *ngIf="currentStep === 'edit'">
                <app-inventario-edit [wineCategories]="wineCategories" [inventoryReport]="inventoryReport"
                    (complete)="onEditComplete(inventoryReport)">
                </app-inventario-edit>
            </ng-container>
            <ng-container *ngIf="currentStep === 'review'">
                <app-inventario-review [wineCategories]="wineCategories" [inventoryReport]="inventoryReport"
                    [isAgent]="sessionClaims.salesRepresentativeId ? true : false"
                    (complete)="onReviewComplete(inventoryReport)" (cancel)="onReviewCancel()">
                </app-inventario-review>
            </ng-container>
            <ng-container *ngIf="currentStep === 'saving'">
                <app-fullscreen-spinner></app-fullscreen-spinner>
            </ng-container>
            <ng-container *ngIf="currentStep === 'submitting'">
                <app-fullscreen-spinner></app-fullscreen-spinner>
            </ng-container>
            <ng-container *ngIf="currentStep === 'success'">
                <app-inventario-success [inventoryReportDocument]="inventoryReport.document" [coupons]="coupons"
                    (pagaOra)="onPagaOra($event, inventoryReport.document)">
                </app-inventario-success>
            </ng-container>
            <ng-container *ngIf="currentStep === 'paying'">
                <app-fullscreen-spinner></app-fullscreen-spinner>
            </ng-container>
            <ng-container *ngIf="currentStep === 'paid'">
                <app-inventario-paid></app-inventario-paid>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<ng-template #spinner>
    <app-fullscreen-spinner></app-fullscreen-spinner>
</ng-template>