import { MainToolbarTitleService } from './../main-toolbar-title.service';
import { EsploravinoApiService } from './../esploravino-api.service';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionClaims } from '../models/session-claims';

import firebase from 'firebase/app';
import 'firebase/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarService } from '../snack-bar.service';

// import * as stripe from 'stripe-v3'; // FIXME
declare var stripe: any;

@Component({
  selector: 'app-payment-methods-add-card',
  templateUrl: './payment-methods-add-card.component.html',
  styleUrls: ['./payment-methods-add-card.component.scss']
})
export class PaymentMethodsAddCardComponent implements OnDestroy, AfterViewInit {
  @ViewChild('cardInfo') cardInfo!: ElementRef;

  elements: stripe.elements.Elements;
  stripe: stripe.Stripe;

  cardHolderFullName!: string;

  card: any;
  cardHandler = this.onChange.bind(this);
  cardError: string | null = null;

  fabDisabled = false;

  constructor(
    private cd: ChangeDetectorRef,
    private mediaObserver: MediaObserver,
    private esploravinoApiService: EsploravinoApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBarService: SnackBarService,
    mainToolbarTitleService: MainToolbarTitleService
  ) {
    this.stripe = (window as any).Stripe(environment.stripe.publishableKey);
    this.elements = this.stripe.elements();

    mainToolbarTitleService.setTitle('Metodi di Pagamento');
    mainToolbarTitleService.setSubtitle('Aggiungi una Carta di Credito');
  }

  ngOnDestroy(): void {
    if (this.card) {
      // We remove event listener here to keep memory clean
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  ngAfterViewInit(): void {
    this.initiateCardElement();
  }

  initiateCardElement(): void {

    const isXs = this.mediaObserver.isActive('xs');

    this.card = this.elements.create('card', {
      hidePostalCode: true,
      //hideIcon: isXs,
      style: {
        base: {
          fontFamily: 'monospace',
          fontSmoothing: 'antialiased',
          fontSize: isXs ? '16px' : '19px'
        }
      }
    });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  onChange(error: any): void {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }
    this.cd.detectChanges();
  }

  async confirmCard(): Promise<void> {


    this.fabDisabled = true;

    this.snackBarService.showInProgress('Salvataggio carta...');

    try {
      const tenantId = this.activatedRoute.parent?.parent?.snapshot.params.tenantId;
      const sessionClaims = this.activatedRoute.parent?.parent?.snapshot.data.currentSessionClaims as SessionClaims;
      const currentUser = this.activatedRoute.parent?.parent?.snapshot.data.currentUser as firebase.User;

      const idToken = await currentUser.getIdToken();
      const setupIntentClientSecret = await this.esploravinoApiService.setupPaymentMethod(tenantId, sessionClaims.customerId, idToken).toPromise();

      const result = await this.stripe.confirmCardSetup(
        setupIntentClientSecret,
        {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.cardHolderFullName,
            },
          },
        }
      );

      if (result.error) {
        if (result.error.message) {
          this.cardError = result.error.message;
          this.snackBarService.showError(result.error.message);
        }
      } else {
        this.cardError = null;
        this.snackBarService.showSuccess('Carta salvata.');
        this.router.navigate(['./../'], { relativeTo: this.activatedRoute });
      }
    } catch (e) {
      console.error(e);
      this.snackBarService.showError();
    }



    this.fabDisabled = false;
  }
}
