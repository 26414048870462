import { Component, Input, Output, EventEmitter, OnDestroy, OnInit, NgZone } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { CallbackMessage } from '../models/callback-message';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit, OnDestroy {

  @Input()
  url!: SafeUrl;

  @Input()
  supportEsploravinoUiEnumeraReadyMessageEvent: boolean = false;

  @Output()
  callbackMessageReceived = new EventEmitter<CallbackMessage>();

  @Output()
  ready = new EventEmitter<void>();

  esploravinoUiEnumeraReadyMessageEventOccurred = false;
  isReady: boolean = false;

  private onMessageFn: ((event: any) => void) | null = null;

  constructor(private ngZone: NgZone) { };

  ngOnInit(): void {
    this.onMessageFn = (event: any) => {
      this.ngZone.run(async () => {
        if (this.callbackMessageReceived) {
          if (event && event.data && event.data['appCallbackData']) {
            try {
              const appCallbackData = JSON.parse(event.data['appCallbackData']);
              this.callbackMessageReceived.emit(appCallbackData);
            } catch (e) {
              console.error("Failed to parse appCallbackData", e);
            }
          }
        }
        if (this.supportEsploravinoUiEnumeraReadyMessageEvent) {
          if (event && event.data && event.data.type === "esploravino-ui-enumera-ready") {
            if (!this.isReady) {
              this.isReady = true;
              this.ready.emit();
            }
          }
        }
      });
    };
    window.addEventListener('message', this.onMessageFn);
  }
  ngOnDestroy(): void {
    if (this.onMessageFn) {
      window.removeEventListener('message', this.onMessageFn);
    }
  }

  onIframeLoaded(e: any): void {
    if (this.supportEsploravinoUiEnumeraReadyMessageEvent === true) {
      setTimeout(() => this.ngZone.run(() => {
        if (!this.isReady) {
          console.warn("Expected message event <esploravino-ui-enumera-ready> not received. Timeout occurred.");
          this.isReady = true;
          this.ready.emit();
        }

      }), 5000);
    } else {
      this.isReady = true;
      this.ready.emit();
    }
  }

}
