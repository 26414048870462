import { Vino } from './../models/vino';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-carta-vini-wine-list-item',
  templateUrl: './carta-vini-wine-list-item.component.html',
  styleUrls: ['./carta-vini-wine-list-item.component.scss']
})
export class CartaViniWineListItemComponent implements OnInit {

  @Input()
  vino!: Vino;

  @Input()
  disabled!: boolean;

  @Output()
  deleteClick = new EventEmitter<void>();

  @Output()
  showClick = new EventEmitter<void>();

  @Output()
  hideClick = new EventEmitter<void>();

  @Output()
  contextMenuOpen = new EventEmitter<void>();

  @Output()
  contextMenuClose = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  deleteWine(): void {
    this.deleteClick.emit();
  }

  hideWine(): void {
    this.hideClick.emit();
  }

  showWine(): void {
    this.showClick.emit();
  }

}
