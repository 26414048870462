import { SnackBarService } from './../snack-bar.service';
import { CartaVini } from './../models/carta-vini';
import { Vino } from './../models/vino';
import { EsploravinoApiService } from './../esploravino-api.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionClaims } from '../models/session-claims';

import firebase from 'firebase/app';
import 'firebase/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-carta-vini-add-vino-bottom-sheet',
  templateUrl: './carta-vini-add-vino-bottom-sheet.component.html',
  styleUrls: ['./carta-vini-add-vino-bottom-sheet.component.scss']
})
export class CartaViniAddVinoBottomSheetComponent implements OnInit {

  firstChild: ActivatedRoute | null;

  constructor(private matBottomSheetRef: MatBottomSheetRef<CartaViniAddVinoBottomSheetComponent>, private router: Router, activatedRoute: ActivatedRoute, private esploravinoApiService: EsploravinoApiService, private angularFirestore: AngularFirestore, private snackBarService: SnackBarService, @Inject(MAT_BOTTOM_SHEET_DATA) private data: { wines: Vino[] }, private matDialog: MatDialog) {
    this.firstChild = activatedRoute.firstChild;
    while (this.firstChild?.firstChild) {
      this.firstChild = this.firstChild.firstChild;
    }
  }

  ngOnInit(): void {
  }

  onNewClick(): void {
    this.matBottomSheetRef.dismiss();
    this.router.navigate(['./new'], { relativeTo: this.firstChild });
  }

  async onImportClick(): Promise<void> {
    this.matBottomSheetRef.dismiss();

    const tenantId = this.firstChild?.parent?.parent?.parent?.snapshot.params.tenantId;
    const sessionClaims = this.firstChild?.parent?.parent?.parent?.snapshot.data.currentSessionClaims as SessionClaims;
    const storeId: string = this.firstChild?.parent?.snapshot.params.storeId;
    const cartaId: string = this.firstChild?.parent?.snapshot.params.cartaId;
    const currentUser = this.firstChild?.parent?.parent?.parent?.snapshot.data.currentUser as firebase.User;

    this.snackBarService.showInProgress('Importazione assortimento vini...');

    try {

      const wines = await from(currentUser.getIdToken()).pipe(switchMap((token) => this.esploravinoApiService.listWines(tenantId, sessionClaims.customerId, storeId, token, sessionClaims))).toPromise();

      if (wines.length === 0) {
        this.snackBarService.showInfo("Nessun vino da importare poiché l'assortimento vini è vuoto.");
        return;
      }

      // TODO: import more than 500 elements. Batched writes supports at max 500 docs per batch.

      const wineListDocument = this.angularFirestore.doc<CartaVini>(`tenants/${tenantId}/customers/${sessionClaims.customerId}/stores/${storeId}/wine-lists/${cartaId}`);
      const winesCollection = wineListDocument.collection<Vino>(`wines`);

      const batch = this.angularFirestore.firestore.batch();
      for (const wine of wines) {
        const docRef = winesCollection.doc(wine.externalId as string).ref;
        batch.set(docRef, { ...wine, id: wine.externalId as string, hidden: false }, { merge: true });
      }
      await batch.commit();

      const oldImportedWineIds = this.data.wines.filter((w) => w.externalId).map((w) => w.externalId);
      const freshImportedWineIds = wines.map((w) => w.externalId);
      const wineIdsToBeDeleted = oldImportedWineIds.filter((id) => !freshImportedWineIds.includes(id));

      if (wineIdsToBeDeleted.length > 0) {
        const deleteAction = await this.matDialog.open(ConfirmDialogComponent, { data: { title: 'Importazione Vini', body: `In questa Carta dei Vini sono presenti ${wineIdsToBeDeleted.length} vini precedentemente importati da Esploravino che adesso però non fanno più parte del tuo attuale assortimento prodotti.\nVuoi rimuoverli dalla Carta dei Vini? Altrimenti, saranno semplicemente nascosti.`, confirmButtonLabel: 'OK, rimuovi', cancelButtonLabel: 'No, nascondi' } }).afterClosed().toPromise();
        const batch2 = this.angularFirestore.firestore.batch();
        for (const id of wineIdsToBeDeleted) {
          const docRef = winesCollection.doc(id as string).ref;
          if (deleteAction) {
            batch2.delete(docRef);
          } else {
            batch2.update(docRef, { hidden: true });
          }
        }
        await batch2.commit();

      }

      wineListDocument.update({ dirty: true });

      this.snackBarService.showSuccess('Vini importati.');

    } catch (e) {
      console.error(e);
      this.snackBarService.showError();
    }
  }

}

