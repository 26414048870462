import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { EsploravinoApiService } from '../esploravino-api.service';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { SessionClaims } from '../models/session-claims';
import { Notification } from '../models/notification';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent {

  notifications$: Observable<Notification[]>;

  tenantId: string;
  sessionClaims: SessionClaims;

  firestoreCollectionPath: string;

  constructor(private activatedRoute: ActivatedRoute, private angularFirestore: AngularFirestore, private mainToolbarTitleService: MainToolbarTitleService, private esploravinoApiService: EsploravinoApiService, private matDialog: MatDialog) {
    this.tenantId = this.activatedRoute.snapshot.parent?.params.tenantId;
    this.sessionClaims = this.activatedRoute.snapshot.parent?.data.currentSessionClaims as SessionClaims;

    const archived: boolean = this.activatedRoute.snapshot.data.archived;

    this.firestoreCollectionPath = `tenants/${this.tenantId}/customers/${this.sessionClaims.customerId}/notifications`;

    this.notifications$ = this.angularFirestore.collection<Notification>(this.firestoreCollectionPath, ref => ref.where("archived", "==", archived).orderBy('timestamp', 'desc')).valueChanges();

    this.mainToolbarTitleService.setTitle('Notifiche');
    this.mainToolbarTitleService.setSubtitle(this.sessionClaims.customerId + " • " + this.sessionClaims.customerName);


  }

  setArchived(notification: Notification, archived: boolean) {
    this.angularFirestore.doc(this.firestoreCollectionPath + "/" + notification.id).update({
      archived
    });
  }

  setRead(notification: Notification, read: boolean) {
    this.angularFirestore.doc(this.firestoreCollectionPath + "/" + notification.id).update({
      read
    });
  }

}
