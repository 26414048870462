<div class="container-max-width">
  <ng-container *ngIf="activeStores$ | async; let stores; else: spinner">
    <div class="container-with-padding">
      <mat-card style="padding: 0">
        <mat-nav-list style="padding-top: 0">
          <ng-container *ngFor="let store of stores; let last = last">
            <a mat-list-item [routerLink]="[store.id]" (click)="setCurrentStore(store)">
              <mat-icon matListIcon>store</mat-icon>
              <b matLine class="store-name">{{ store.name | lowercase }}</b>
              <small matLine class="store-description">{{
                store.address | lowercase
                }}</small>
            </a>
            <mat-divider *ngIf="!last"></mat-divider>
          </ng-container>
        </mat-nav-list>
      </mat-card>
    </div>
    <app-empty-list-message *ngIf="stores.length === 0">
      Nessun punto vendita attivo.
    </app-empty-list-message>
  </ng-container>

  <ng-container *ngIf="inactiveStores$ | async; let stores">
    <ng-container *ngIf="stores.length > 0">
      <h1 style="margin-top: 48px">Punti vendita non attivi</h1>
      <mat-card style="padding: 0">
        <mat-nav-list style="padding-top: 0">
          <ng-container *ngFor="let store of stores; let last = last">
            <mat-list-item (click)="onInactiveStoreClick()">
              <mat-icon matListIcon>disabled_by_default</mat-icon>
              <b matLine class="store-name">{{ store.name | lowercase }}</b>
              <small matLine class="store-description">{{
                store.address | lowercase
                }}</small>
            </mat-list-item>
            <mat-divider *ngIf="!last"></mat-divider>
          </ng-container>
        </mat-nav-list>
      </mat-card>
    </ng-container>
  </ng-container>
</div>

<ng-template #spinner>
  <app-fullscreen-spinner></app-fullscreen-spinner>
</ng-template>