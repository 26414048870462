import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MainToolbarTitleService {

  private titleSubject: BehaviorSubject<string | null>;
  title$: Observable<string | null>;

  private subtitleSubject: BehaviorSubject<string | null>;
  subtitle$: Observable<string | null>;

  private previousUrl: string | null = null;

  constructor(router: Router) {
    this.titleSubject = new BehaviorSubject<string | null>(null);
    this.title$ = this.titleSubject.asObservable();

    this.subtitleSubject = new BehaviorSubject<string | null>(null);
    this.subtitle$ = this.subtitleSubject.asObservable();

    router.events.pipe(
      filter(evt => evt instanceof NavigationStart)
    ).subscribe((evt) => {
      const navigationStartEvent: NavigationStart = evt as NavigationStart;
      if (this.previousUrl !== null && this.previousUrl !== navigationStartEvent.url.split("?")[0]) {
        this.setTitle(null);
        this.setSubtitle(null);
      }
      this.previousUrl = navigationStartEvent.url.split("?")[0];
    });
  }

  setTitle(title: string | null): void {
    this.titleSubject.next(title);
  }

  setSubtitle(subtitle: string | null): void {
    this.subtitleSubject.next(subtitle);
  }
}
