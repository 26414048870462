import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { EsploravinoApiService } from '../esploravino-api.service';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { SessionClaims } from '../models/session-claims';
import { SnackBarService } from '../snack-bar.service';
import { Notification } from '../models/notification';

@Component({
  selector: 'app-notifications-view',
  templateUrl: './notifications-view.component.html',
  styleUrls: ['./notifications-view.component.scss']
})
export class NotificationsViewComponent implements OnInit {

  notificationDocument: AngularFirestoreDocument<Notification>;
  notification$: Observable<Notification | undefined>;

  constructor(private datePipe: DatePipe, private activatedRoute: ActivatedRoute, private snackBarService: SnackBarService, private angularFirestore: AngularFirestore, private mainToolbarTitleService: MainToolbarTitleService, private esploravinoApiService: EsploravinoApiService, private matDialog: MatDialog) {
    const tenantId = this.activatedRoute.snapshot.params.tenantId;
    const sessionClaims = this.activatedRoute.snapshot.data.currentSessionClaims as SessionClaims;

    const notificationId = this.activatedRoute.snapshot.params.notificationId;

    this.notificationDocument = this.angularFirestore.doc<Notification>(`tenants/${tenantId}/customers/${sessionClaims.customerId}/notifications/${notificationId}`);
    this.notification$ = this.notificationDocument.valueChanges();

    this.mainToolbarTitleService.setTitle('Notifiche');
    this.mainToolbarTitleService.setSubtitle("...");
    this.notification$.subscribe((notification) => {
      this.mainToolbarTitleService.setSubtitle(datePipe.transform(notification?.timestamp.toDate(), "dd/MM/yyyy"));
    });


  }

  ngOnInit(): void {
    this.notificationDocument.update({ read: true });
  }

}
