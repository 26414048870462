<div fxLayout="row" fxLayoutAlign="start center" class="header">
    <div fxFlex>Riepilogo Consumi</div>
    <div style="margin-right: 16px;">
        <button mat-icon-button aria-label="Chiudi" (click)="emitCancel()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div class="banner" *ngIf="!isAgent"><b>Attenzione!</b>
    Premendo su Conferma l'inventario verrà trasmesso in sede e sarà generata la documentazione contabile per i
    consumi rilevati.
</div>

<div class="banner" *ngIf="isAgent"><b>Nota Bene</b>
    Questa bozza di inventario dovrà essere confermata ed inviata in sede direttamente dal Cliente tramite la app.
</div>

<div class="row-header" fxLayout="row">
    <div fxFlex></div>
    <div class="col">Contabile</div>
    <div class="col">Inventario</div>
    <div class="col red"><b>Consumo</b></div>
</div>
<div *ngIf="getData(inventoryReport); let data" style="overflow: hidden;">
    <mat-nav-list style="margin-right: -8px;">
        <ng-container *ngFor="
          let item of data.items | keyvalue;
          let last = last;
          let first = first
        ">
            <mat-divider *ngIf="first"></mat-divider>
            <a class="item" mat-list-item>

                <div style="overflow: hidden; margin-right: 8px" mat-line fxLayout="row">
                    <div fxFlex class="category">{{item.value.label}}</div>
                    <div class="col">{{item.value.qtaContabile}}</div>
                    <div class="col">{{item.value.qtaInventario}}</div>
                    <div class="col red">{{item.value.qtaConsumata}}</div>
                </div>
            </a>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-nav-list>
    <div class="row-footer" fxLayout="row">
        <div fxFlex></div>
        <div class="col">{{data.qtaContabile}}</div>
        <div class="col">{{data.qtaInventario}}</div>
        <div class="col red"><b>{{data.qtaConsumata}}</b></div>
    </div>
</div>

<div class="extended-fab-container" *ngIf="!isAgent">

    <button mat-raised-button color="accent" (click)="emitComplete()">
        <mat-icon>check</mat-icon>Conferma
    </button>
</div>

<div class="extended-fab-spacer"></div>