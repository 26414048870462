import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EsploravinoApiService } from './esploravino-api.service';
import { Customer } from './models/customer';

import firebase from 'firebase/app';
import 'firebase/auth';
import { SessionClaims } from './models/session-claims';

@Injectable({
  providedIn: 'root'
})
export class CurrentCustomerService {

  private currentCustomerSubject: BehaviorSubject<Customer | null>;
  currentCustomer$: Observable<Customer | null>;
  private _currentCustomer: Customer | null;

  get currentCustomer(): Customer | null {
    return this._currentCustomer;
  }

  constructor(
    private esploravinoApiService: EsploravinoApiService
  ) {
    this.currentCustomerSubject = new BehaviorSubject<Customer | null>(null);
    this.currentCustomer$ = this.currentCustomerSubject.asObservable();
    this._currentCustomer = null;
  }

  async setCurrentCustomer(customer: Customer, tenantId: string, currentUser: firebase.User, currentSessionClaims: SessionClaims): Promise<void> {
    if (currentSessionClaims.customerId !== customer.id) {
      const idToken = await currentUser.getIdToken();
      await this.esploravinoApiService.updateSessionSetCustomer(tenantId, idToken, currentSessionClaims, customer.id).toPromise();
      currentSessionClaims.customerId = customer.id
      currentSessionClaims.customerName = customer.displayName;
    }
    this.next(customer);
  }

  clearCurrentCustomer(currentSessionClaims: SessionClaims): void {
    currentSessionClaims.customerId = null as any as string;
    currentSessionClaims.customerName = null as any as string;
    this.next(null);
  }

  private next(customer: Customer | null) {
    this.currentCustomerSubject.next(customer);
    this._currentCustomer = customer;
  }
}
