<div class="container-max-width">
    <ng-container *ngIf="customers$ | async; let customers; else: spinner">
        <div class="container-with-padding">
            <mat-card style="padding: 0">
                <mat-nav-list style="padding-top: 0">
                    <ng-container *ngFor="let customer of customers; let last = last">
                        <a mat-list-item href="javascript:void(0)"
                            (click)="setCurrentCustomerAndNavigate(customer, [customer.id])">
                            <mat-icon matListIcon>person</mat-icon>
                            <b matLine class="customer-name">{{ customer.displayName | lowercase }}</b>
                            <small matLine class="customer-description">{{
                                customer.location | lowercase
                                }}</small>

                        </a>
                        <mat-divider *ngIf="!last"></mat-divider>
                    </ng-container>
                </mat-nav-list>
            </mat-card>
        </div>
        <app-empty-list-message *ngIf="customers.length === 0">
            Nessun cliente disponibile.
        </app-empty-list-message>
    </ng-container>
</div>

<ng-template #spinner>
    <app-fullscreen-spinner></app-fullscreen-spinner>
</ng-template>

<app-fullscreen-spinner *ngIf="loadingSettingCurrentCustomer"></app-fullscreen-spinner>