<h2 mat-dialog-title>Periodo Personalizzato</h2>
<mat-dialog-content class="mat-typography">
    <div>Scegli la data di inizio e la data di fine periodo.</div>
    <mat-calendar [selected]="dateRange" (selectedChange)="onMatCalendarSelectedChange($event)" [maxDate]="maxDate"
        [minDate]="minDate">
    </mat-calendar>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-flat-button mat-dialog-close>
        Annulla
    </button>
    <span fxFlex>&nbsp;</span>
    <button mat-button mat-raised-button (click)="ok()" color="accent" cdkFocusInitial>
        Ok
    </button>
</mat-dialog-actions>