<div id="container" [style.background-image]="sanitizeImageUrl('url(' + image?.url + ')')" fxLayout="row"
  fxFlexAlign="center center" [ngClass]="{ bordered: !image, dragging: dragging }">
  <button mat-icon-button id="image-bt-upload" *ngIf="!loading && !disabled">
    <mat-icon *ngIf="dragging">check</mat-icon>
    <mat-icon *ngIf="!dragging">cloud_upload</mat-icon>
  </button>

  <mat-progress-spinner id="spinner" *ngIf="loading" diameter="50" mode="indeterminate"></mat-progress-spinner>

  <input *ngIf="!disabled" hidden type="file" #fileInput accept="image/*" (change)="uploadImage($event)"
    (dragover)="dragging = true" (dragleave)="dragging = false" />
  <div id="label" [ngClass]="{ 'with-image': image }">{{ label }}</div>
</div>

<button type="button" mat-icon-button id="delete-bt" *ngIf="image && !loading && !dragging && !disabled"
  (click)="deleteImage($event)">
  <mat-icon>cancel</mat-icon>
</button>
<button type="button" mat-icon-button id="edit-bt" *ngIf="image && !loading && !dragging && !disabled"
  (click)="editImage($event)">
  <mat-icon>aspect_ratio</mat-icon>
</button>