import { SessionClaims } from './../models/session-claims';
import { Component } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { environment } from 'src/environments/environment';
import { EsploravinoApiService } from '../esploravino-api.service';

import firebase from 'firebase/app';
import 'firebase/auth';
import { NEVER, Observable } from 'rxjs';
import { Post } from '../models/post';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { NewsComponent } from '../news/news.component';

const packageJson = require('../../../package.json');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  currentSessionClaims: SessionClaims;

  posts$: Observable<Post[]> = NEVER;

  constructor(private activatedRoute: ActivatedRoute, mainToolbarTitleService: MainToolbarTitleService, esploravinoApiService: EsploravinoApiService, private sanitizer: DomSanitizer,) {
    this.currentSessionClaims = this.activatedRoute.snapshot.data.currentSessionClaims as SessionClaims;
    mainToolbarTitleService.setTitle('Esploravino');
    if (!environment.production) {
      mainToolbarTitleService.setSubtitle(environment.id + ' v' + packageJson.version);
    }

    const currentUser = this.activatedRoute.snapshot.data.currentUser as firebase.User;

    currentUser.getIdToken().then((idToken) => {
      this.posts$ = esploravinoApiService.listPosts(this.currentSessionClaims.tenantId, idToken, this.currentSessionClaims, true).pipe(map((posts) => posts.slice(0, Math.min(4, posts.length - 1))));
    });

  }

  getSafeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  isFeatured(post: Post) {
    return post.tags?.includes(NewsComponent.FEATURED_TAG);
  }

}
