<div fxLayout="row" fxLayoutAlign="start center" class="header">
    <div fxFlex style="margin: 0 8px; overflow-x: auto;">
        <mat-chip-list class="mat-chip-list-inventario">
            <ng-container *ngFor="let wineCategory of wineCategories;">
                <mat-chip *ngIf="categoryHasItems(inventoryReport.items, wineCategory.id)"
                    (click)="toggleCategoryFilter(wineCategory.id)"
                    [ngClass]="{'active':isCategoryFilterActive(wineCategory.id)}" style="justify-content: space-around; margin: 0 4px;
                            ">
                    {{ wineCategory.name }}
                </mat-chip>
            </ng-container>
        </mat-chip-list>
    </div>
    <div style="margin-bottom: -7px">
        <button mat-icon-button aria-label="Apri menu contestuale" [matMenuTriggerFor]="menu"
            style="margin-right: 16px; position: relative; bottom: 3px" *ngIf="inventoryReport.draft">
            <mat-icon>more_vert</mat-icon>

            <mat-menu #menu="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="menuReimposta">
                    <mat-icon>restart_alt</mat-icon>
                    <span>Reimposta</span>
                    <mat-menu #menuReimposta="matMenu">
                        <button mat-menu-item (click)="resetToQtaContabili(inventoryReport.items)">
                            <span>Usa quantità contabili</span>
                        </button>
                        <button mat-menu-item (click)="resetToQtaCentralina(inventoryReport.items)"
                            *ngIf="isSenseTrayVisible(inventoryReport)">
                            <span>Usa quantità SenseTray</span>
                        </button>
                        <button mat-menu-item (click)="resetToQtaInScadenza(inventoryReport.items)">
                            <span>Usa quantità in scadenza</span>
                        </button>
                        <button mat-menu-item (click)="resetToZero(inventoryReport.items)">
                            <span>Tutto a zero</span>
                        </button>
                    </mat-menu>

                </button>

            </mat-menu>
        </button>
    </div>
</div>

<mat-nav-list style="padding-top:0">
    <ng-container *ngFor="
            let item of getItemsByCategory(inventoryReport.items);
            let last = last;
            let first = first
        ">
        <app-inventario-item [item]="item" [disabled]="!inventoryReport.draft">
        </app-inventario-item>
        <mat-divider></mat-divider>
    </ng-container>
</mat-nav-list>
<app-empty-list-message *ngIf="inventoryReport.items.length === 0">
    Nessun prodotto.
</app-empty-list-message>

<div class="extended-fab-container" *ngIf="inventoryReport.items.length>0 && inventoryReport.draft">
    <button mat-raised-button color="accent" (click)="emitComplete()">
        <mat-icon>check</mat-icon>Avanti
    </button>
</div>

<div class="extended-fab-spacer"></div>

<ng-template #spinner>
    <app-fullscreen-spinner></app-fullscreen-spinner>
</ng-template>