import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { SessionClaims } from '../models/session-claims';

import firebase from 'firebase/app';
import 'firebase/auth';
import { CallbackMessage } from '../models/callback-message';
import { DOCUMENT } from '@angular/common';
import { EsploravinoApiService } from '../esploravino-api.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-magazzino',
  templateUrl: './magazzino.component.html',
  styleUrls: ['./magazzino.component.scss']
})
export class MagazzinoComponent {

  url: SafeUrl;

  tenantId: string;

  currentSessionClaims: SessionClaims;

  constructor(
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private mainToolbarTitleService: MainToolbarTitleService,
    @Inject(DOCUMENT) private document: Document,
    private esploravinoApiService: EsploravinoApiService,
    private angularFireAuth: AngularFireAuth,
    private router: Router

  ) {
    const storeId: string = this.activatedRoute.snapshot.params.storeId;
    this.currentSessionClaims = this.activatedRoute.snapshot.parent?.parent?.data.currentSessionClaims as SessionClaims;
    this.tenantId = this.activatedRoute.snapshot.parent?.parent?.params.tenantId;

    const url = environment.enumeraUiBaseUrl + '?o2c=evino_cli_magazzino&JXSESSNAME=' + encodeURIComponent(this.currentSessionClaims.uiSessionName) + '&pos_id=' + encodeURIComponent(storeId) + '&appCallback=' + encodeURIComponent(this.document.location.protocol + '//' + this.document.location.host + '/postmessage.html');
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    const currentUser = this.activatedRoute.parent?.parent?.snapshot.data.currentUser as firebase.User;

    this.mainToolbarTitleService.setTitle('SenseTray');
    this.mainToolbarTitleService.setSubtitle(storeId);
    currentUser.getIdToken().then((idToken) => esploravinoApiService.getStore(this.tenantId, this.currentSessionClaims.customerId, storeId, idToken, this.currentSessionClaims).toPromise().then((store) => this.mainToolbarTitleService.setSubtitle(store.name)));

  }

  onCallbackMessageReceived(callbackMessage: CallbackMessage) {
    if (callbackMessage.type === 'unauthorized') {
      this.angularFireAuth.signOut();
      this.router.navigateByUrl('/' + this.tenantId + '/login?returnUrl=' + encodeURIComponent(this.router.url) + '&userId=' + encodeURIComponent(this.currentSessionClaims.userId));
      this.esploravinoApiService.deleteSession(this.tenantId, this.currentSessionClaims);
    } else {
      console.warn("Unexpected callback message", callbackMessage);
    }
  }

}
