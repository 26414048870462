import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { InventoryItem } from '../models/inventory-item';
import { InventoryReport, InventoryReportItem } from '../models/inventory-report';
import { WineCategory } from '../models/vino';


import firebase from 'firebase/app';
import 'firebase/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, share, switchMap } from 'rxjs/operators';
import { EsploravinoApiService } from '../esploravino-api.service';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { SessionClaims } from '../models/session-claims';
import { DatePipe } from '@angular/common';
import { SnackBarService } from '../snack-bar.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-inventario-edit',
  templateUrl: './inventario-edit.component.html',
  styleUrls: ['./inventario-edit.component.scss']
})
export class InventarioEditComponent implements OnInit {

  tenantId: string;
  sessionClaims: SessionClaims;
  storeId: string;
  currentUser: firebase.User;

  @Input()
  wineCategories!: WineCategory[];

  @Input()
  inventoryReport!: InventoryReport;

  @Output()
  complete: EventEmitter<void> = new EventEmitter();

  constructor(private activatedRoute: ActivatedRoute, private mainToolbarTitleService: MainToolbarTitleService, private esploravinoApiService: EsploravinoApiService, private router: Router, private snackBarService: SnackBarService, private matDialog: MatDialog) {
    this.tenantId = this.activatedRoute.snapshot.params.tenantId;
    this.sessionClaims = this.activatedRoute.snapshot.data.currentSessionClaims as SessionClaims;
    this.storeId = this.activatedRoute.snapshot.params.storeId;

    this.currentUser = this.activatedRoute.snapshot.data.currentUser as firebase.User;

    this.mainToolbarTitleService.setTitle('Inventario');
    this.mainToolbarTitleService.setSubtitle(this.storeId);
    this.currentUser.getIdToken().then((idToken) => esploravinoApiService.getStore(this.tenantId, this.sessionClaims.customerId, this.storeId, idToken, this.sessionClaims).toPromise().then((store) => this.mainToolbarTitleService.setSubtitle(store.name)));



  }

  ngOnInit(): void {
    if (!this.inventoryReport.draft) {
      this.snackBarService.showInfo("L'inventario di oggi è già stato confermato.");
    }
  }

  getItemsByCategory(items: InventoryReportItem[]): InventoryReportItem[] {
    const currentCategoryId = this.activatedRoute.snapshot.queryParamMap.get("category");
    if (!currentCategoryId) {
      return items;
    } else {
      return items.filter((v) => v.categoryId === currentCategoryId);
    }
  }

  toggleCategoryFilter(wineCategory: string) {
    const currentCategoryId = this.activatedRoute.snapshot.queryParamMap.get("category");
    this.router.navigate(["."], {
      relativeTo: this.activatedRoute,
      queryParams: {
        category: currentCategoryId ? (currentCategoryId === wineCategory ? null : wineCategory) : wineCategory
      },

    });
  }

  isCategoryFilterActive(wineCategory: string) {
    const currentCategoryId = this.activatedRoute.snapshot.queryParamMap.get("category");
    return currentCategoryId === wineCategory;
  }

  categoryHasItems(items: InventoryReportItem[], categoria: string) {
    return items.find((item) => item.categoryId == categoria) ? true : false;
  }

  resetToQtaContabili(items: InventoryReportItem[]) {
    items.forEach((item) => item.qtaInventario = item.qtaContabile);
  }

  resetToQtaCentralina(items: InventoryReportItem[]) {
    items.forEach((item) => item.qtaInventario = item.qtaCentralina);
  }

  resetToQtaInScadenza(items: InventoryReportItem[]) {
    items.forEach((item) => item.qtaInventario = item.qtaContabile - item.qtaInScadenza);
  }

  resetToZero(items: InventoryReportItem[]) {
    items.forEach((item) => item.qtaInventario = 0);
  }

  isSenseTrayVisible(inventoryReport: InventoryReport) {
    inventoryReport.items.filter((item) => item.isCentralinaOk === true).length === inventoryReport.items.length;
  }

  emitComplete() {
    this.complete.emit();
  }
}
