import { PaymentMethod } from './../models/payment-method';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-method-card-details-dialog',
  templateUrl: './payment-method-card-details-dialog.component.html',
  styleUrls: ['./payment-method-card-details-dialog.component.scss']
})
export class PaymentMethodCardDetailsDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { paymentMethod: PaymentMethod }) { }

  ngOnInit(): void {
  }

}
