import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Coupon } from '../models/coupon';
import { InventoryReport, InventoryReportDocument } from '../models/inventory-report';
import { Payment } from '../models/payment';

interface Cart {
  coupons: Map<Coupon, number>;
  amount: number
}

@Component({
  selector: 'app-inventario-success',
  templateUrl: './inventario-success.component.html',
  styleUrls: ['./inventario-success.component.scss']
})
export class InventarioSuccessComponent {

  @Input()
  inventoryReportDocument!: InventoryReportDocument;

  @Input()
  coupons!: Coupon[];

  @Output()
  pagaOra: EventEmitter<Payment> = new EventEmitter();

  constructor() { }

  getDocumentDate(document: InventoryReportDocument) {
    return moment(document.date, "YYYY-MM-DD").toDate();
  }

  applyCoupons(total: number, coupons: Coupon[]): Cart {
    const cart: Cart = {
      amount: total,
      coupons: new Map<Coupon, number>()
    }
    if (total > 0) {
      for (const coupon of coupons) {
        if (cart.amount >= coupon.amount) {
          cart.coupons.set(coupon, coupon.amount);
        } else {
          cart.coupons.set(coupon, cart.amount);
        }
        cart.amount = Math.max(0, cart.amount - coupon.amount);

        if (cart.amount === 0) break;
      }
    }
    return cart;
  }

  onPagaOraClick(amount: number, coupons: Coupon[]) {
    this.pagaOra.emit({
      amount, coupons
    });
  }

}
