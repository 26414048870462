<div fxLayout="column" fxFlex style="height: 100%;">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content class="mat-typography" style="height: 100%;">
        <angular-cropper [cropperOptions]="cropperOptions" [imageUrl]="data.imageUrl" #angularCropper
            style="height: 100%; display: block;">
        </angular-cropper>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="!loading; else spinner;" fxFlex>
        <button mat-button mat-stroked-button mat-dialog-close>
            Annulla
        </button>
        <span fxFlex>&nbsp;</span>
        <button mat-button mat-raised-button (click)="ok()" color="accent" cdkFocusInitial>
            OK
        </button>
    </mat-dialog-actions>
    <ng-template #spinner>
        <div class="progress-bar-container">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </ng-template>
</div>