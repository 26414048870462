<a class="item" mat-list-item [routerLink]="disabled ? [] : ['./' + vino.id]" [ngClass]="{ 'vino-hidden': vino.hidden }"
  style="height: 64px">
  <div class="vino-icon" mat-list-icon>
    <mat-icon *ngIf="!vino.externalId">liquor</mat-icon>
    <img *ngIf="vino.externalId" src="/assets/v-white-on-green.png" />
  </div>

  <div style="overflow: hidden; margin-right: 8px" mat-line>
    <b>{{ vino.denominazione }}</b>
    <div class="vino-detail">
      <b>{{ vino.cantina }}</b><br /><span *ngIf="vino.capacita">{{ vino.capacita }} ml</span>
    </div>
  </div>
  <div class="vino-prices" *ngIf="!vino.hidden" [ngStyle]="
      vino.prezzoBottiglia || vino.prezzoAsporto || vino.prezzoCalice
        ? { 'min-width': '80px' }
        : {}
    ">
    <span *ngIf="vino.prezzoBottiglia">bottiglia
      <b><span class="vino-price-value">{{
          vino.prezzoBottiglia | number: "1.0-2"
          }}</span></b>€<br /></span>
    <span *ngIf="vino.prezzoAsporto">asporto
      <b><span class="vino-price-value">{{
          vino.prezzoAsporto | number: "1.0-2"
          }}</span></b>€<br /></span>
    <span *ngIf="vino.prezzoCalice">calice
      <b><span class="vino-price-value">{{
          vino.prezzoCalice | number: "1.0-2"
          }}</span></b>€</span>
  </div>
  <mat-icon *ngIf="vino.hidden" matTooltip="Questo vino non sarà visibile sulla Carta">visibility_off</mat-icon>
  <button mat-icon-button aria-label="Apri menu contestuale" [disabled]="disabled" [matMenuTriggerFor]="menu"
    style="margin-left: 8px" (click)="$event.stopPropagation(); $event.preventDefault();"
    (onMenuClose)="contextMenuClose.emit()" (onMenuOpen)="contextMenuOpen.emit()">
    <mat-icon>more_vert</mat-icon>
  </button>
</a>

<mat-menu #menu="matMenu">
  <a mat-menu-item routerLink="./{{ vino.id }}">
    <mat-icon>edit</mat-icon>
    <span>Modifica</span>
  </a>
  <button mat-menu-item (click)="showWine()" *ngIf="vino.hidden === true">
    <mat-icon>visibility</mat-icon>
    <span>Mostra</span>
  </button>
  <button mat-menu-item (click)="hideWine()" *ngIf="vino.hidden === false">
    <mat-icon>visibility_off</mat-icon>
    <span>Nascondi</span>
  </button>
  <button mat-menu-item (click)="deleteWine()">
    <mat-icon>delete</mat-icon>
    <span>Elimina</span>
  </button>
</mat-menu>