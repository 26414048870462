import { SnackBarService } from './../snack-bar.service';
import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog.component';
import { WineCategory } from './../models/vino';
import { CartaVini } from './../models/carta-vini';
import { ActivatedRoute } from '@angular/router';
import { CartaViniAddVinoBottomSheetComponent } from './../carta-vini-add-vino-bottom-sheet/carta-vini-add-vino-bottom-sheet.component';
import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Vino } from '../models/vino';
import { SessionClaims } from '../models/session-claims';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { EsploravinoApiService } from '../esploravino-api.service';

import firebase from 'firebase/app';
import 'firebase/auth';
import { switchMap, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PausableObservable, pausable } from 'rxjs-pausable';

@Component({
  selector: 'app-carta-vini-wines',
  templateUrl: './carta-vini-wines.component.html',
  styleUrls: ['./carta-vini-wines.component.scss']
})
export class CartaViniWinesComponent implements OnInit {

  winesCollection: AngularFirestoreCollection<Vino>;
  wineListDocument: AngularFirestoreDocument<CartaVini>;

  wineCategories$: Observable<WineCategory[]>;
  wineList$: Observable<CartaVini>;
  wines$: PausableObservable<Vino[]>;

  constructor(private matBottomSheet: MatBottomSheet, private activatedRoute: ActivatedRoute, private snackBarService: SnackBarService, private angularFirestore: AngularFirestore, private mainToolbarTitleService: MainToolbarTitleService, private esploravinoApiService: EsploravinoApiService, private matDialog: MatDialog) {
    const tenantId = this.activatedRoute.parent?.parent?.parent?.snapshot.params.tenantId;
    const sessionClaims = this.activatedRoute.parent?.parent?.parent?.snapshot.data.currentSessionClaims as SessionClaims;
    const storeId: string = this.activatedRoute.parent?.snapshot.params.storeId;
    const cartaId: string = this.activatedRoute.parent?.snapshot.params.cartaId;

    this.wineListDocument = this.angularFirestore.doc<CartaVini>(`tenants/${tenantId}/customers/${sessionClaims.customerId}/stores/${storeId}/wine-lists/${cartaId}`);
    this.wineList$ = this.wineListDocument.valueChanges() as Observable<CartaVini>;

    this.winesCollection = this.wineListDocument.collection<Vino>(`wines`);
    this.wines$ = this.winesCollection.valueChanges().pipe(pausable()) as PausableObservable<Vino[]>;

    const currentUser = this.activatedRoute.parent?.parent?.parent?.snapshot.data.currentUser as firebase.User;

    this.mainToolbarTitleService.setTitle('Carta dei Vini');
    this.mainToolbarTitleService.setSubtitle(storeId);
    currentUser.getIdToken().then((idToken) => esploravinoApiService.getStore(tenantId, sessionClaims.customerId, storeId, idToken, sessionClaims).toPromise().then((store) => this.mainToolbarTitleService.setSubtitle(store.name)));

    this.wineCategories$ = from(currentUser.getIdToken()).pipe(switchMap((idToken) => esploravinoApiService.listWineCategories(tenantId, idToken, sessionClaims)));

  }

  ngOnInit(): void {
  }

  getViniByCategory(wines: Vino[], categoria: string): Vino[] {
    const strip = (input: string) => input.replace(/\W/g, ''); // https://stackoverflow.com/a/9364527
    return wines.filter((v) => v.categoria === categoria).sort((a, b) => strip(a.cantina) === strip(b.cantina) ? (strip(a.denominazione) >= strip(b.denominazione) ? 1 : -1) : (strip(a.cantina) >= (b.cantina) ? 1 : -1));
  }

  showAddVinoBottomSheet(wines: Vino[]): void {
    this.matBottomSheet.open(CartaViniAddVinoBottomSheetComponent, { data: { wines } });
  }

  async deleteWine(wine: Vino): Promise<void> {
    const result = await this.matDialog.open(ConfirmDialogComponent, { data: { title: 'Eliminazione vino', body: `Sei veramente sicuro di voler eliminare il vino "${wine.denominazione}" dalla Carta dei Vini?`, confirmButtonLabel: 'Sì, elimina' } }).afterClosed().toPromise();
    if (result) {
      this.snackBarService.showInProgress('Eliminazione vino in corso...');
      const wineDocument = this.winesCollection.doc<Vino>(wine.id);
      try {
        await wineDocument.delete();
        this.snackBarService.showSuccess('Vino eliminato.');
        this.wineListDocument.update({ dirty: true });
      } catch (e) {
        console.error(e);
        this.snackBarService.showError();
      }
    }
  }

  async setWineHidden(hidden: boolean, wine: Vino): Promise<void> {
    this.snackBarService.showInProgress('Salvataggio vino in corso...');
    const previousValue = wine.hidden;
    wine.hidden = hidden;
    const wineDocument = this.winesCollection.doc<Vino>(wine.id);
    try {
      await wineDocument.update({ hidden: wine.hidden });
      this.wineListDocument.update({ dirty: true });
      this.snackBarService.showSuccess('Vino salvato.');
    } catch (e) {
      console.error(e);
      this.snackBarService.showError();
      wine.hidden = previousValue;
    }
  }

}

