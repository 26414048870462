<div fxLayout="column" style="height: 100%; margin: 0 auto">
  <div class="toolbar">
    <ng-content select="[slot=toolbar]"></ng-content>
  </div>
  <div class="main-container" fxFlex>
    <mat-drawer-container fxLayout="column" style="height: 100%; margin: 0 auto">
      <mat-drawer #drawer mode="over" class="drawer" ngClass.gt-sm="drawer-desktop" position="start">
        <ng-content select="[slot=drawer]"></ng-content>
      </mat-drawer>
      <router-outlet></router-outlet>
    </mat-drawer-container>
  </div>
  <div class="navbar">
    <ng-content select="[slot=navbar]"></ng-content>
  </div>
</div>