import { Component, ComponentFactoryResolver, ElementRef, Input, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent {

  @ViewChild('drawer')
  private drawer!: MatDrawer;

  toggleDrawer = () => {
    this.drawer.toggle();
  }

  closeDrawer = () => {
    this.drawer.close();
  }

}
