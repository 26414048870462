<div class="container" [ngClass.xs]="{ slideout: url }">
  <div fxLayout="column" fxFlex fxLayoutAlign="center center" *ngIf="!url && !loading">
    <div class="box">
      <img src="/assets/logo-esploravino.png" style="height: 200px" />
    </div>
    <div class="box">
      <button mat-button mat-raised-button color="accent" (click)="loginStart()">
        ACCEDI
      </button>
      <div *ngIf="environmentId" class="env-project-id">{{environmentId}} v{{version}}</div>
    </div>
  </div>
  <app-iframe [url]="url" *ngIf="url" (ready)="onIframeLoaded()"
    [supportEsploravinoUiEnumeraReadyMessageEvent]="true"></app-iframe>
  <app-fullscreen-spinner *ngIf="loading" [backgroundColor]="'white'"></app-fullscreen-spinner>
</div>
<div class="shape-container top" [ngClass.xs]="'xs'">
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" width="1920" height="102" viewBox="0 0 1920 102">
    <g fill-rule="evenodd" transform="matrix(1 0 0 -1 0 102)">
      <path fill-opacity=".457"
        d="M0,7.9621684 C293.239583,47.9621684 613.239583,54.6288351 960,27.9621684 C1306.76042,1.2955018 1626.76042,14.6288351 1920,67.9621684 L1920,102 L0,102 L0,7.9621684 Z"
        transform="matrix(-1 0 0 1 1920 0)"></path>
      <path
        d="M0,37.9621684 C300.495844,88.578393 620.495844,88.578393 960,37.9621684 C1299.50416,-12.6540561 1619.50416,-12.6540561 1920,37.9621684 L1920,102 L0,102 L0,37.9621684 Z">
      </path>
    </g>
  </svg>
</div>
<div class="shape-container bottom" [ngClass.xs]="{ slideout: url, xs: true }">
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" width="1920" height="102" viewBox="0 0 1920 102">
    <g fill-rule="evenodd" transform="matrix(1 0 0 -1 0 102)">
      <path fill-opacity=".457"
        d="M0,7.9621684 C293.239583,47.9621684 613.239583,54.6288351 960,27.9621684 C1306.76042,1.2955018 1626.76042,14.6288351 1920,67.9621684 L1920,102 L0,102 L0,7.9621684 Z"
        transform="matrix(-1 0 0 1 1920 0)"></path>
      <path
        d="M0,37.9621684 C300.495844,88.578393 620.495844,88.578393 960,37.9621684 C1299.50416,-12.6540561 1619.50416,-12.6540561 1920,37.9621684 L1920,102 L0,102 L0,37.9621684 Z">
      </path>
    </g>
  </svg>
</div>