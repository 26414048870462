import { AngularFireStorage } from '@angular/fire/storage';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EsploravinoApiService } from '../esploravino-api.service';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { CartaVini, THEMES } from '../models/carta-vini';
import { SessionClaims } from '../models/session-claims';
import { Image } from '../models/image';

import firebase from 'firebase/app';
import 'firebase/auth';
import { SnackBarService } from '../snack-bar.service';

@Component({
  selector: 'app-carta-vini-settings',
  templateUrl: './carta-vini-settings.component.html',
  styleUrls: ['./carta-vini-settings.component.scss']
})
export class CartaViniSettingsComponent implements OnInit {

  private wineListDocument: AngularFirestoreDocument<CartaVini>;

  wineList$;

  firebaseStorageBasePath: string;

  disabled = false;

  dataOutsideFormChanged = false;

  themes = THEMES;

  constructor(private angularFirestore: AngularFirestore, private activatedRoute: ActivatedRoute, private router: Router, private snackBarService: SnackBarService, private mainToolbarTitleService: MainToolbarTitleService, esploravinoApiService: EsploravinoApiService, private afStorage: AngularFireStorage) {
    const tenantId = this.activatedRoute.parent?.parent?.parent?.snapshot.params.tenantId;
    const sessionClaims = this.activatedRoute.parent?.parent?.parent?.snapshot.data.currentSessionClaims as SessionClaims;
    const storeId: string = this.activatedRoute.parent?.snapshot.params.storeId;
    const cartaId: string = this.activatedRoute.parent?.snapshot.params.cartaId;

    this.firebaseStorageBasePath = `tenants/${tenantId}/customers/${sessionClaims.customerId}/stores/${storeId}/wine-lists/${cartaId}/assets`;

    const wineListsCollection = this.angularFirestore.collection<CartaVini>(`tenants/${tenantId}/customers/${sessionClaims.customerId}/stores/${storeId}/wine-lists`);

    this.wineListDocument = wineListsCollection.doc(cartaId);
    this.wineList$ = this.wineListDocument.valueChanges();

    const currentUser = this.activatedRoute.parent?.parent?.parent?.snapshot.data.currentUser as firebase.User;
    this.mainToolbarTitleService.setTitle('Carta dei Vini');
    this.mainToolbarTitleService.setSubtitle(storeId);
    currentUser.getIdToken().then((idToken) => esploravinoApiService.getStore(tenantId, sessionClaims.customerId, storeId, idToken, sessionClaims).toPromise().then((store) => this.mainToolbarTitleService.setSubtitle(store.name)));

  }

  ngOnInit(): void {
  }

  async saveWineList(wineList: CartaVini, isValid: boolean | null): Promise<void> {

    if (!isValid) {
      this.snackBarService.showWarning('Compila tutti i campi obbligatori');
      return;
    }

    this.disabled = true;

    this.snackBarService.showInProgress('Salvataggio Carta dei Vini...');

    try {
      await this.wineListDocument.set({ ...wineList, dirty: true });
      this.dataOutsideFormChanged = false;
      this.snackBarService.showSuccess('Carta dei Vini salvata.');
      this.router.navigate(['./../'], { relativeTo: this.activatedRoute });
    } catch (e) {
      console.error(e);
      this.snackBarService.showError();
    }
    this.disabled = false;
  }

  changeTheme(themeId: string, wineList: CartaVini): void {
    if (this.disabled || wineList.renderingPdf) return;
    wineList.theme = themeId;
    this.dataOutsideFormChanged = true;
  }

}
