<h2 mat-dialog-title>
    {{data.transaction.documentSeries}}: {{data.transaction.documentNumber}} del
    {{data.transaction.documentDate|date:"dd/MM/yyyy"}}
</h2>
<mat-dialog-content class="mat-typography" fxLayout="column">
    <p *ngIf="!data.transaction.confirmationDate; else: dettagli">Conferma ricezione con riserva.</p>
    <ng-template #dettagli>
        Dettagli problema.
    </ng-template>
    <mat-form-field appearance="fill" fxFlex color="accent">
        <mat-label>Descrivi i problemi riscontrati</mat-label>
        <textarea matInput name=" issue" [(ngModel)]="data.transaction.issuesDetails" fxFlex
            [readonly]="data.transaction.confirmationDate !== null"></textarea>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-flat-button mat-dialog-close *ngIf="!data.transaction.confirmationDate">
        Annulla
    </button>
    <span fxFlex>&nbsp;</span>
    <button mat-button mat-raised-button mat-dialog-close color="accent" cdkFocusInitial
        *ngIf="data.transaction.confirmationDate; else: submit">
        Ok
    </button>
    <ng-template #submit>
        <button mat-button mat-raised-button [mat-dialog-close]="data.transaction.issuesDetails" color="accent"
            cdkFocusInitial>
            Conferma con Riserva
        </button>
    </ng-template>
</mat-dialog-actions>