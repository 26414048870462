<div style="position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;">

  <div class="container-max-width">

    <div class="content">
      <h2 class="title">
        Benvenuto<br /><b>
          <ng-container *ngIf="currentSessionClaims.salesRepresentativeId; else: customerName">
            {{
            currentSessionClaims.salesRepresentativeName | lowercase }}
          </ng-container>
          <ng-template #customerName>{{ currentSessionClaims.customerName | lowercase }}</ng-template>
        </b>
      </h2>
    </div>
    <div *ngIf="posts$ | async; let posts" style="margin-bottom: 210px">
      <div class="posts-container" [ngClass.xs]="'xs'">
        <div class="posts-container" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center center">
          <a routerLink="/{{ currentSessionClaims.tenantId }}/news/post/{{post.url | base64url}}"
            class="link-unstyled post" [ngClass]="{'featured':isFeatured(post)}" *ngFor="let post of posts">
            <mat-card>
              <div class="tri">
                <mat-icon *ngIf="isFeatured(post)">star</mat-icon>
              </div>
              <mat-card-header>
                <mat-card-subtitle>
                  {{post.date | date:'dd MMMM yyyy'}}
                </mat-card-subtitle>
                <mat-card-title>
                  {{post.title}}
                </mat-card-title>

              </mat-card-header>
              <img mat-card-image [src]="getSafeUrl(post.imageUrl)">
            </mat-card>
          </a>
        </div>
      </div>

      <div class="posts-fab">
        <a mat-raised-button color="accent" routerLink="./news">
          Altre Notizie & Eventi<mat-icon>chevron_right</mat-icon>
        </a>
      </div>

    </div>



  </div>
</div>


<img class="hero" src="/assets/hero-final.png" [ngClass.xs]="'xs'" />

<div class="shape-container bottom" [ngClass.xs]="'xs'">
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" width="1920" height="102" viewBox="0 0 1920 102">
    <g fill-rule="evenodd" transform="matrix(1 0 0 -1 0 102)">
      <path fill-opacity=".457"
        d="M0,7.9621684 C293.239583,47.9621684 613.239583,54.6288351 960,27.9621684 C1306.76042,1.2955018 1626.76042,14.6288351 1920,67.9621684 L1920,102 L0,102 L0,7.9621684 Z"
        transform="matrix(-1 0 0 1 1920 0)"></path>
      <path
        d="M0,37.9621684 C300.495844,88.578393 620.495844,88.578393 960,37.9621684 C1299.50416,-12.6540561 1619.50416,-12.6540561 1920,37.9621684 L1920,102 L0,102 L0,37.9621684 Z">
      </path>
    </g>
  </svg>
</div>