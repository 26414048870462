import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentMethod } from '../models/payment-method';

@Component({
  selector: 'app-payment-method-card',
  templateUrl: './payment-method-card.component.html',
  styleUrls: ['./payment-method-card.component.scss']
})
export class PaymentMethodCardComponent implements OnInit {

  @Input()
  paymentMethod!: PaymentMethod;

  @Input()
  deleteDisabled!: boolean;

  @Input()
  disabled!: boolean;

  @Output()
  deleteClick = new EventEmitter<void>();

  @Output()
  starClick = new EventEmitter<void>();

  @Output()
  detailsClick = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
