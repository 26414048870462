import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage } from '@angular/fire/storage';

import { Image } from '../models/image';

import firebase from 'firebase/app';
import 'firebase/storage';
import { SnackBarService } from '../snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { ImageCropperDialogComponent } from '../image-cropper-dialog/image-cropper-dialog.component';

@Component({
  selector: 'app-image-form-field',
  templateUrl: './image-form-field.component.html',
  styleUrls: ['./image-form-field.component.css']
})
export class ImageFormFieldComponent {

  @Input()
  image!: Image | null;

  @Output()
  imageChange = new EventEmitter<Image | null>();

  @Input()
  basePath!: string;

  @Input()
  label!: string;

  @Input()
  disabled!: boolean;

  loading = false;

  dragging = false;

  constructor(
    private sanitizer: DomSanitizer,
    private snackBarService: SnackBarService,
    private angularFireStorage: AngularFireStorage,
    private matDialog: MatDialog
  ) { }

  sanitizeImageUrl(url: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(url);
  }

  private getNewFilePath(fileName?: string): string {
    const uid = new Date().getTime();
    return this.basePath + '/' + uid + '_' + (fileName || '');
  }

  private onUploadSuccess(data: firebase.storage.UploadTaskSnapshot): void {
    data.ref.getDownloadURL().then((url: string) => {
      this.image = { url, ref: data.ref.fullPath };
      this.imageChange.emit(this.image);
      this.loading = false;
      this.dragging = false;
    }).catch(this.onError);
  }

  private onError(error: any): void {
    console.error(error);
    this.matDialog.open(AlertDialogComponent, { data: { title: "Errore", body: 'Purtroppo si è verificato un erorre, non è stato possibile caricare il file, riprova più tardi. Se l\'errore persiste, contatta il supporto, grazie.' } });
    this.loading = false;
  }

  uploadImage(event: Event): void {
    // TODO: mettere in sicurezza firebase storage permettendo solo immagini
    // TODO: pulire immagini non più in uso

    this.loading = true;

    for (const file of (event.target as any).files) {
      const filePath = this.getNewFilePath(file.name);
      const task = this.angularFireStorage.upload(filePath, file);
      task
        .then((data) => this.onUploadSuccess(data))
        .catch((error) => this.onError(error));
    }
  }

  deleteImage($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (!this.image) { return; }
    this.image = null;
    this.imageChange.emit(this.image);

    this.snackBarService.showInfo('Immagine eliminata');
  }

  async editImage($event: Event): Promise<void> {
    $event.preventDefault();
    $event.stopPropagation();

    if (!this.image) { return; }

    const result = await this.matDialog.open(ImageCropperDialogComponent, {
      data: {
        title: "Ridimensiona o ritaglia",
        imageUrl: this.image?.url
      }
    }).afterClosed().toPromise();
    if (result) {
      this.loading = true;

      const filePath = this.getNewFilePath();
      const task = this.angularFireStorage.ref(filePath).put(result);
      task
        .then((data) => this.onUploadSuccess(data))
        .catch((error) => this.onError(error));
    }
  }

}
