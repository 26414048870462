import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable, NEVER } from 'rxjs';
import { map } from 'rxjs/operators';
import { EsploravinoApiService } from '../esploravino-api.service';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { Post } from '../models/post';
import { SessionClaims } from '../models/session-claims';

import firebase from 'firebase/app';
import 'firebase/auth';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-news-post',
  templateUrl: './news-post.component.html',
  styleUrls: ['./news-post.component.scss']
})
export class NewsPostComponent {

  loading = true;

  postUrl: string;
  postUrlSafe: SafeUrl;

  constructor(private activatedRoute: ActivatedRoute, mainToolbarTitleService: MainToolbarTitleService, esploravinoApiService: EsploravinoApiService, private sanitizer: DomSanitizer, datePipe: DatePipe) {
    const currentSessionClaims = this.activatedRoute.snapshot.data.currentSessionClaims as SessionClaims;

    this.postUrl = atob(decodeURIComponent(this.activatedRoute.snapshot.params.base64urlencodedPostUrl));
    this.postUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.postUrl);

    const currentUser = this.activatedRoute.snapshot.data.currentUser as firebase.User;

    currentUser.getIdToken().then(async (idToken) => {
      const posts = await esploravinoApiService.listPosts(currentSessionClaims.tenantId, idToken, currentSessionClaims).toPromise();
      const post = posts.find((p) => p.url == this.postUrl);

      if (post) {
        mainToolbarTitleService.setSubtitle(datePipe.transform(post.date, 'dd MMMM yyyy'));
        mainToolbarTitleService.setTitle(post.title);
      }


    });

  }

  onIframeLoaded(): void {
    this.loading = false;
  }

}
