import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from, Observable, throwError } from 'rxjs';
import { EsploravinoApiService } from '../esploravino-api.service';
import { SessionClaims } from '../models/session-claims';
import { Store } from '../models/store';
import firebase from 'firebase/app';
import 'firebase/auth';
import { switchMap, map, share } from 'rxjs/operators';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CurrentStoreService } from '../current-store.service';
import { MainToolbarTitleService } from '../main-toolbar-title.service';

@Component({
  selector: 'app-store-selector',
  templateUrl: './store-selector.component.html',
  styleUrls: ['./store-selector.component.scss']
})
export class StoreSelectorComponent implements OnInit {

  activeStores$!: Observable<Store[]>;
  inactiveStores$!: Observable<Store[]>;

  constructor(private activatedRoute: ActivatedRoute, private esploravinoApiService: EsploravinoApiService, private router: Router, private matDialog: MatDialog, private currentStoreService: CurrentStoreService, mainToolbarTitleService: MainToolbarTitleService) {
    const sessionClaims = this.activatedRoute.parent?.parent?.snapshot.data.currentSessionClaims as SessionClaims;
    const currentUser = this.activatedRoute.parent?.parent?.snapshot.data.currentUser as firebase.User;
    const tenantId = this.activatedRoute.parent?.parent?.snapshot.params.tenantId;
    const stores$ = from(currentUser.getIdToken()).pipe(switchMap((token) => this.esploravinoApiService.listStores(tenantId, sessionClaims.customerId, token, sessionClaims))).pipe(share());
    this.activeStores$ = stores$.pipe(map((stores) => stores.filter((s) => s.active)));
    this.inactiveStores$ = stores$.pipe(map((stores) => stores.filter((s) => !s.active)));

    mainToolbarTitleService.setTitle("Seleziona il punto vendita");

    this.activeStores$.subscribe((stores) => {
      if (stores.length === 1) {
        this.setCurrentStore(stores[0]);
        this.router.navigate([stores[0].id], { replaceUrl: true, relativeTo: this.activatedRoute });
      } else {
        if (this.currentStoreService.currentStore !== null) {
          const store = stores.find((s) => s.id === this.currentStoreService.currentStore?.id);
          if (store) {
            this.setCurrentStore(store);
            this.router.navigate([store.id], { replaceUrl: true, relativeTo: this.activatedRoute });
          } else {
            this.setCurrentStore(null);
          }
        }
      }
    });
  }

  ngOnInit(): void {

  }

  setCurrentStore(store: Store | null): void {
    this.currentStoreService.setCurrentStore(store);
  }

  async onInactiveStoreClick(): Promise<void> {
    await this.matDialog.open(AlertDialogComponent, { data: { title: 'Punto Vendita', body: 'Non puoi selezionare questo punto vendita. Questo punto vendita non è attivo.' } }).afterClosed().toPromise();
  }
}
