<mat-toolbar color="primary" style="padding: 0; white-space: unset;">
  <div class="container-max-width" fxLayout="row" fxLayoutAlign="start center" fxFlex style="padding: 0 16px;">
    <button style="color: black" mat-icon-button aria-label="Indietro" class="back-button" (click)="goBack()"
      *ngIf="backButtonRouterLink">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <ng-container *ngIf="!backButtonRouterLink">
      <button style="color: black" mat-icon-button aria-label="Menu" (click)="menuButtonClick.emit()">
        <mat-icon>menu</mat-icon>
      </button>
    </ng-container>


    <div fxFlex style="position: relative;">
      <div style=" position: absolute;top: -16px; left: 8px; right: 8px; bottom: 0;"
        [ngStyle]="!subtitle ? {'top': '-12px'} : {}">
        <div *ngIf="title" class="title">{{ title }}</div>
        <div *ngIf="subtitle" class="subtitle">{{ subtitle }}</div>
      </div>
    </div>


    <div *ngIf="currentUser" style="cursor: pointer" fxLayout="row" fxLayoutAlign="center center">

      <button mat-icon-button [matMenuTriggerFor]="menu" class="user" (click)="moreMenuButtonClick.emit()">
        <mat-icon [matBadge]="notificationsCount$ | async"
          [matBadgeDisabled]="currentSessionClaims.salesRepresentativeId">
          account_circle</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <mat-icon>account_circle</mat-icon>
          <span style="text-transform: capitalize;">{{ currentSessionClaims.userId }} • {{
            currentSessionClaims.customerName | lowercase
            }} {{
            currentSessionClaims.salesRepresentativeName | lowercase
            }}</span>
        </button>
        <ng-container *ngIf="currentSessionClaims.salesRepresentativeId">
          <button mat-menu-item (click)="clearCurrentCustomerAndStoreAndGoToCustomerSelector(currentCustomer.id)"
            *ngIf="currentCustomer$ | async; let currentCustomer">
            <mat-icon>person</mat-icon>
            <span style="text-transform: capitalize;">{{currentCustomer.displayName | lowercase}}</span>
          </button>
        </ng-container>
        <button mat-menu-item (click)="clearCurrentStoreAndGoToStoreSelector(currentStore.id)"
          *ngIf="currentStore$ | async; let currentStore">
          <mat-icon>store</mat-icon>
          <span style="text-transform: capitalize;">{{currentStore.name | lowercase}}</span>
        </button>
        <button mat-menu-item routerLink="/{{ tenantId }}/notifications" fxLayout="row" fxLayoutAlign="center center"
          *ngIf="!currentSessionClaims.salesRepresentativeId">
          <mat-icon>notifications</mat-icon>
          <span>Notifiche</span>
          <span fxFlex></span>
          <div class="badge-container" [matBadge]="notificationsCount$ | async"
            [matBadgeDisabled]="currentSessionClaims.salesRepresentativeId"></div>
        </button>
        <button mat-menu-item routerLink="/{{ tenantId }}/change-password">
          <mat-icon>vpn_key</mat-icon>
          <span>Modifica Password</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Esci</span>
        </button>
        <button mat-menu-item routerLink="/" class="app-info">
          <span>App v{{appVersion}} • {{ tenantId }} </span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>