import { Image } from './image';

export const THEMES = [{ id: 'basic', name: "Basic" }, { id: 'classic', name: "Classic" }, { id: 'contemporary', name: "Contemporary" }, { id: 'elegant', name: "Elegant" }];

export interface CartaVini {
  id: string;
  title: string;
  url?: string;
  pdfUrl?: string;
  image: Image | null;
  dirty?: boolean;
  renderingPdf?: boolean;
  theme?: string;
}
