<h2 mat-dialog-title>
    {{data.transaction.documentSeries}}: {{data.transaction.documentNumber}} del
    {{data.transaction.documentDate|date:"dd/MM/yyyy"}}
</h2>
<mat-dialog-content class="mat-typography">
    <p *ngIf="data.transaction.trackingCompany">Vettore:<br><b>{{data.transaction.trackingCompany}}</b></p>
    <p *ngIf="data.transaction.trackingNumber">Numero Spedizione:<br><b>{{data.transaction.trackingNumber}}</b></p>
    <p *ngIf="data.transaction.trackingUrl">Pagina web:<br><b><a (click)="openTrackingUrl()"
                href="javascript:void()">{{data.transaction.trackingUrl}}</a></b></p>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="start end">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" *ngIf="!data.salesRepresentativeId">
        <button mat-button mat-raised-button [mat-dialog-close]="true" *ngIf="!data.transaction.confirmationDate"
            class="conferma-btn">
            Conferma Ricezione
        </button>
        <button mat-button mat-raised-button [mat-dialog-close]="false" *ngIf="!data.transaction.confirmationDate"
            class="conferma-btn">
            Conferma con Riserva
        </button>
    </div>
    <span fxFlex>&nbsp;&nbsp;&nbsp;</span>
    <button mat-button mat-raised-button mat-dialog-close color="accent" cdkFocusInitial>
        Ok
    </button>
</mat-dialog-actions>