import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Observable, NEVER } from 'rxjs';
import { EsploravinoApiService } from '../esploravino-api.service';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { Post } from '../models/post';
import { SessionClaims } from '../models/session-claims';

import firebase from 'firebase/app';
import 'firebase/auth';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent {

  static TAG_PREFIX = "App ";
  static FEATURED_TAG = "App Featured";

  TAG_PREFIX = NewsComponent.TAG_PREFIX;
  FEATURED_TAG = NewsComponent.FEATURED_TAG;

  currentSessionClaims: SessionClaims;

  posts$: Observable<Post[]> = NEVER;

  tag$: Observable<string | null>;

  constructor(private activatedRoute: ActivatedRoute, mainToolbarTitleService: MainToolbarTitleService, esploravinoApiService: EsploravinoApiService, private sanitizer: DomSanitizer, private router: Router) {
    this.currentSessionClaims = this.activatedRoute.snapshot.data.currentSessionClaims as SessionClaims;

    mainToolbarTitleService.setSubtitle('Esploravino');
    mainToolbarTitleService.setTitle('Notizie & Eventi');

    const currentUser = this.activatedRoute.snapshot.data.currentUser as firebase.User;

    currentUser.getIdToken().then((idToken) => {
      this.posts$ = esploravinoApiService.listPosts(this.currentSessionClaims.tenantId, idToken, this.currentSessionClaims);
    });

    this.tag$ = this.activatedRoute.queryParams.pipe(map((params) => params.tag));

  }

  getSafeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  filterPostsByTag(posts: Post[], tag: string | null): Post[] {
    return tag ? posts.filter((post) => post.tags && post.tags.filter((t) => t === tag).length > 0) : posts;
  }

  getTags(posts: Post[]): string[] {
    const tags = new Set<string>();
    for (const post of posts) {
      if (post.tags) {
        for (const tag of post.tags) {
          if (tag.startsWith(this.TAG_PREFIX)) {
            tags.add(tag);
          }
        }
      }

    }
    return [...tags].filter((t) => t !== this.FEATURED_TAG);
  }

  setTag(tag: string | null) {
    this.router.navigate(["."], {
      relativeTo: this.activatedRoute,
      queryParams: {
        tag
      }
    });
  }

  getTagDisplayText(tag: string) {
    return tag.startsWith(this.TAG_PREFIX) ? tag.substring(this.TAG_PREFIX.length) : tag;
  }

  isFeatured(post: Post) {
    return post.tags?.includes(this.FEATURED_TAG);
  }
}