import { CartaViniWinesComponent } from './carta-vini-wines/carta-vini-wines.component';
import { CartaViniComponent } from './carta-vini/carta-vini.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { StoreSelectorComponent } from './store-selector/store-selector.component';
import { CurrentSessionClaimsResolver } from './current-session-claims.resolver';
import { environment } from 'src/environments/environment';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

import LoginPageComponent from './login-page/login-page.component';
import { MainPageComponent } from './main-page/main-page.component';
import { CurrentUserResolver } from './current-user.resolver';
import { InventarioComponent } from './inventario/inventario.component';
import { MagazzinoComponent } from './magazzino/magazzino.component';
import { HomeComponent } from './home/home.component';
import { CartaViniDettaglioVinoComponent } from './carta-vini-dettaglio-vino/carta-vini-dettaglio-vino.component';
import { PaymentMethodsAddCardComponent } from './payment-methods-add-card/payment-methods-add-card.component';
import { CartaViniSettingsComponent } from './carta-vini-settings/carta-vini-settings.component';
import { MovimentiMagazzinoComponent } from './movimenti-magazzino/movimenti-magazzino.component';
import { NewsComponent } from './news/news.component';
import { NewsPostComponent } from './news-post/news-post.component';
import { CustomerSelectorComponent } from './customer-selector/customer-selector.component';
import { InventarioEditComponent } from './inventario-edit/inventario-edit.component';
import { InventarioReviewComponent } from './inventario-review/inventario-review.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsViewComponent } from './notifications-view/notifications-view.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';

// tslint:disable-next-line:no-string-literal
const redirectUnauthorizedToLogin = (next: ActivatedRouteSnapshot) => redirectUnauthorizedTo('/' + next.params['tenantId'] + '/login?returnUrl=' + encodeURIComponent((next as any)['_routerState'].url));

const routes: Routes = [
  {
    path: '',
    redirectTo: environment.defaultTenantId,
    pathMatch: 'full',
  },
  {
    path: ':tenantId/login',
    component: LoginPageComponent
  },
  {
    path: ':tenantId',
    pathMatch: 'prefix',
    component: MainPageComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    resolve: { currentUser: CurrentUserResolver, currentSessionClaims: CurrentSessionClaimsResolver },
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'news',
        component: NewsComponent
      },
      {
        path: 'news/post/:base64urlencodedPostUrl',
        component: NewsPostComponent,
        data: { backButtonRouterLink: ['../../'] }
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      },
      {
        path: 'inventario',
        children: [
          {
            path: '',
            component: CustomerSelectorComponent
          },
          {
            path: ':customerId',
            component: StoreSelectorComponent
          },
          {
            path: ':customerId/:storeId',
            component: InventarioComponent
          }
        ]
      },
      {
        path: 'movimenti-magazzino',
        children: [
          {
            path: '',
            component: CustomerSelectorComponent
          },
          {
            path: ':customerId',
            component: StoreSelectorComponent
          },
          {
            path: ':customerId/:storeId',
            component: MovimentiMagazzinoComponent
          }
        ]
      },
      {
        path: 'magazzino',
        children: [
          {
            path: '',
            component: CustomerSelectorComponent
          },
          {
            path: ':customerId',
            component: StoreSelectorComponent
          },
          {
            path: ':customerId/:storeId',
            component: MagazzinoComponent
          }
        ]
      },
      {
        path: 'carta-vini',
        children: [
          {
            path: '',
            component: CustomerSelectorComponent
          },
          {
            path: ':customerId',
            component: StoreSelectorComponent
          },
          {
            path: ':customerId/:storeId',
            component: CartaViniComponent,
          },
          {
            path: ':customerId/:storeId/:cartaId',
            component: CartaViniComponent,
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'wines'
              },
              {
                path: 'wines',
                component: CartaViniWinesComponent
              },
              {
                path: 'settings',
                component: CartaViniSettingsComponent
              }
            ]
          },
          {
            path: ':customerId/:storeId/:cartaId/wines/new',
            component: CartaViniDettaglioVinoComponent,
            data: { backButtonRouterLink: ['../'] }
          },
          {
            path: ':customerId/:storeId/:cartaId/wines/:vinoId',
            component: CartaViniDettaglioVinoComponent,
            data: { backButtonRouterLink: ['../'] }
          }
        ]
      },
      {
        path: 'metodi-pagamento',
        children: [
          {
            path: '',
            component: CustomerSelectorComponent
          },
          {
            path: ':customerId',
            component: PaymentMethodsComponent
          },
          {
            path: ':customerId/new',
            component: PaymentMethodsAddCardComponent,
            data: { backButtonRouterLink: ['../'] }
          }
        ]
      },
      {
        path: 'metodi-pagamento',
        children: [
          {
            path: '',
            component: CustomerSelectorComponent
          },
          {
            path: ':customerId',
            component: PaymentMethodsComponent
          },
          {
            path: ':customerId/new',
            component: PaymentMethodsAddCardComponent,
            data: { backButtonRouterLink: ['../'] }
          }
        ]
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'inbox'
          },
          {
            path: 'inbox',
            component: NotificationsListComponent,
            data: { archived: false }
          },
          {
            path: 'archive',
            component: NotificationsListComponent,
            data: { archived: true }
          }
        ]
      },
      {
        path: 'notifications/:notificationId',
        component: NotificationsViewComponent,
        data: { backButtonRouterLink: ['../'] }
      }
    ]
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
