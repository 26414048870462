<ng-container *ngIf="notifications$ | async; let notifications; else: spinner">
    <mat-nav-list class="list">
        <ng-container *ngFor=" let notification of notifications">
            <a class="item" mat-list-item [routerLink]="['../' + notification.id]"
                [ngClass]="{ 'read': notification.read }">

                <mat-icon class="item-icon" *ngIf="notification.category==='PROMEMORIAINVENTARIO'"
                    mat-list-icon>fact_check</mat-icon>
                <mat-icon class="item-icon" *ngIf="notification.category==='NUOVACONSEGNA'"
                    mat-list-icon>move_up</mat-icon>

                <div mat-line class="subject">
                    {{notification.subject}}
                </div>
                <div mat-line class="body">
                    {{notification.body}}
                </div>

                <small class="date">
                    {{notification.timestamp.toDate() | date:"dd/MM"}}
                    <br>
                    {{notification.timestamp.toDate() | date:"yyyy"}}
                </small>

                <button mat-icon-button aria-label="Azioni" [matMenuTriggerFor]="actionsMenu" class="actions"
                    (click)="$event.stopPropagation();$event.preventDefault()">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #actionsMenu="matMenu">
                    <button mat-menu-item (click)="setArchived(notification, true)" *ngIf="!notification.archived">
                        <mat-icon>archive</mat-icon>
                        <span>Archivia</span>
                    </button>
                    <button mat-menu-item (click)="setArchived(notification, false)" *ngIf="notification.archived">
                        <mat-icon>inbox</mat-icon>
                        <span>Sposta In Arrivo</span>
                    </button>
                    <button mat-menu-item (click)="setRead(notification, true)" *ngIf="!notification.read">
                        <mat-icon>mark_email_read</mat-icon>
                        <span>Segna come Letta</span>
                    </button>
                    <button mat-menu-item (click)="setRead(notification, false)" *ngIf="notification.read">
                        <mat-icon>mark_email_unread</mat-icon>
                        <span>Segna come Da Leggere</span>
                    </button>
                </mat-menu>
            </a>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-nav-list>

    <app-empty-list-message *ngIf="notifications.length === 0">
        Nessuna notifica
    </app-empty-list-message>

</ng-container>

<ng-template #spinner>
    <app-fullscreen-spinner backgroundColor="transparent"></app-fullscreen-spinner>
</ng-template>