<div fxLayout="row" fxLayoutAlign="start center" class="container">
  <ng-container [ngSwitch]="data.type">
    <mat-icon *ngSwitchCase="'in-progress'" style="color: #ffeb3b"
      >sync</mat-icon
    >
    <mat-icon *ngSwitchCase="'info'" style="color: #03a9f4">info</mat-icon>
    <mat-icon *ngSwitchCase="'success'" style="color: #009688">check</mat-icon>
    <mat-icon *ngSwitchCase="'warning'" style="color: #ff9800"
      >warning</mat-icon
    >
    <mat-icon *ngSwitchCase="'error'" style="color: #f44336">cancel</mat-icon>
  </ng-container>

  <span fxFlex class="message">{{ data.message }}</span>
  <span class="mat-simple-snackbar-action" *ngIf="hasAction">
    <button
      mat-button
      mat-raised-button
      (click)="action()"
      style="color: #ffffff; background: black"
    >
      {{ data.action }}
    </button>
  </span>
</div>
