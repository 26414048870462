import { MainToolbarComponent } from './main-toolbar/main-toolbar.component';
import { PageComponent } from './page/page.component';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BottomNavModule } from 'ngx-bottom-nav';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, TENANT_ID } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, DEBUG_MODE, APP_NAME, APP_VERSION } from '@angular/fire/analytics';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatBadgeModule } from '@angular/material/badge';

import { QrCodeModule } from 'ng-qrcode';

import { environment } from 'src/environments/environment';
import LoginPageComponent from './login-page/login-page.component';
import { MainPageComponent } from './main-page/main-page.component';
import { HttpClientModule } from '@angular/common/http';
import { InventarioComponent } from './inventario/inventario.component';
import { HomeComponent } from './home/home.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { PaymentMethodCardComponent } from './payment-method-card/payment-method-card.component';
import { FullscreenSpinnerComponent } from './fullscreen-spinner/fullscreen-spinner.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EmptyListMessageComponent } from './empty-list-message/empty-list-message.component';
import { StoreSelectorComponent } from './store-selector/store-selector.component';
import { IframeComponent } from './iframe/iframe.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MagazzinoComponent } from './magazzino/magazzino.component';
import { CartaViniComponent } from './carta-vini/carta-vini.component';
import { CartaViniAddVinoBottomSheetComponent } from './carta-vini-add-vino-bottom-sheet/carta-vini-add-vino-bottom-sheet.component';
import { CartaViniDettaglioVinoComponent } from './carta-vini-dettaglio-vino/carta-vini-dettaglio-vino.component';
import { FormsModule } from '@angular/forms';
import { PaymentMethodsAddCardComponent } from './payment-methods-add-card/payment-methods-add-card.component';
import { CartaViniQrDialogComponent } from './carta-vini-qr-dialog/carta-vini-qr-dialog.component';
import { PaymentMethodCardDetailsDialogComponent } from './payment-method-card-details-dialog/payment-method-card-details-dialog.component';
import { CartaViniWinesComponent } from './carta-vini-wines/carta-vini-wines.component';
import { CartaViniWineListItemComponent } from './carta-vini-wine-list-item/carta-vini-wine-list-item.component';
import { CartaViniSettingsComponent } from './carta-vini-settings/carta-vini-settings.component';
import { ImageFormFieldComponent } from './image-form-field/image-form-field.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { ImageCropperDialogComponent } from './image-cropper-dialog/image-cropper-dialog.component';
import { MovimentiMagazzinoComponent } from './movimenti-magazzino/movimenti-magazzino.component';

const packageJson = require('../../package.json');

import { DatePipe, registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { NewsComponent } from './news/news.component';
import { NewsPostComponent } from './news-post/news-post.component';
import { Base64UrlPipe } from './base64url.pipe';
import { DateRangePickerDialogComponent } from './date-range-picker-dialog/date-range-picker-dialog.component';
import { MovimentiMagazzinoWarningDialogComponent } from './movimenti-magazzino-warning-dialog/movimenti-magazzino-warning-dialog.component';
import { MovimentiMagazzinoTrackingDialogComponent } from './movimenti-magazzino-tracking-dialog/movimenti-magazzino-tracking-dialog.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomerSelectorComponent } from './customer-selector/customer-selector.component';
import { InventarioItemComponent } from './inventario-item/inventario-item.component';
import { InventarioEditComponent } from './inventario-edit/inventario-edit.component';
import { InventarioReviewComponent } from './inventario-review/inventario-review.component';
import { InventarioSuccessComponent } from './inventario-success/inventario-success.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsViewComponent } from './notifications-view/notifications-view.component';
import { InventarioPaidComponent } from './inventario-paid/inventario-paid.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    PageComponent,
    LoginPageComponent,
    MainPageComponent,
    MainToolbarComponent,
    InventarioComponent,
    HomeComponent,
    PaymentMethodsComponent,
    PaymentMethodCardComponent,
    FullscreenSpinnerComponent,
    EmptyListMessageComponent,
    StoreSelectorComponent,
    IframeComponent,
    ChangePasswordComponent,
    MagazzinoComponent,
    CartaViniComponent,
    CartaViniAddVinoBottomSheetComponent,
    CartaViniDettaglioVinoComponent,
    PaymentMethodsAddCardComponent,
    CartaViniQrDialogComponent,
    PaymentMethodCardDetailsDialogComponent,
    CartaViniWinesComponent,
    CartaViniWineListItemComponent,
    CartaViniSettingsComponent,
    ImageFormFieldComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    SnackBarComponent,
    ImageCropperDialogComponent,
    MovimentiMagazzinoComponent,
    NewsComponent,
    NewsPostComponent,
    Base64UrlPipe,
    DateRangePickerDialogComponent,
    MovimentiMagazzinoWarningDialogComponent,
    MovimentiMagazzinoTrackingDialogComponent,
    CustomerSelectorComponent,
    InventarioItemComponent,
    InventarioEditComponent,
    InventarioReviewComponent,
    InventarioSuccessComponent,
    NotificationsComponent,
    NotificationsViewComponent,
    InventarioPaidComponent,
    NotificationsListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    BottomNavModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatMenuModule,
    MatChipsModule,
    MatTooltipModule,
    MatCardModule,
    MatBottomSheetModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatTableModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.google),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    HttpClientModule,
    QrCodeModule,
    AngularCropperjsModule,
    MatDialogModule,
    MatBadgeModule,
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.serviceWorkerEnabled,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: TENANT_ID, useFactory: () => {
        const tenantId = window.location.pathname.split('/')[1] || environment.defaultTenantId;
        const firebaseAuthTenantId = (environment.firebaseAuthTenantIdsMap as any)[tenantId];
        if (!firebaseAuthTenantId) {
          alert('Purtroppo si è verificato un errore, si prega di contattare l\'assistenzza clienti');
          throw new Error('Fatal Error! Non è possibile determinare il tenant Firebase Auth. Il tenant indicato nell\'URL, o quello di default, risulta ' + tenantId);
        }
        return firebaseAuthTenantId;
      }
    }, {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      }
    },
    {
      provide: DEBUG_MODE, useValue: environment.production ? false : true
    },
    {
      provide: APP_NAME, useValue: environment.id
    },
    {
      provide: APP_VERSION, useValue: packageJson.version
    },
    DatePipe,
    ScreenTrackingService,
    UserTrackingService,
    MatDatepickerModule,
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: MAT_DATE_LOCALE, useValue: 'it' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
