<div class="container-max-width">
  <div class="container-with-padding">
    <div fxLayout="column">
      <p style="margin-bottom: 25px">
        Semplifica la gestione dei tuoi pagamenti aggiungendo qui di seguito i
        dettagli di una carta di credito.
      </p>
      <form (ngSubmit)="confirmCard()">
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="fill" fxFlex style="margin-bottom: 15px" color="accent">
            <mat-label>Titolare della carta</mat-label>
            <mat-icon matPrefix class="icon-gray icon-w32">person</mat-icon>
            <input matInput name="cardHolderFullName" [(ngModel)]="cardHolderFullName" required />
            <mat-error *ngIf="!cardHolderFullName">Inserisci nome e cognome del titolare della carta</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <div class="stripe-form-field" fxFlex>
            <div #cardInfo></div>
            <mat-error *ngIf="cardError" style="font-size: 11px; padding-left: 10px">{{ cardError }}</mat-error>
          </div>
        </div>
        <div class="extended-fab-container">
          <button mat-raised-button color="accent" id="submit-button" type="submit" [disabled]="fabDisabled">
            <mat-icon>check</mat-icon>Conferma
          </button>
        </div>
      </form>
      <p>
        Nessun addebito sarà effettuato adesso. La carta sarà utilizzata
        successivamente per saldare le tue fatture, in accordo con il tuo
        contratto Esploravino.
      </p>

      <div class="extended-fab-spacer"></div>
    </div>
  </div>
</div>