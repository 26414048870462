import { Vino, WineCategory } from './models/vino';
import { Router } from '@angular/router';
import { SessionTokens } from './models/session-tokens';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Store } from './models/store';
import { catchError } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

import { SessionClaims } from './models/session-claims';
import { PaymentMethod } from './models/payment-method';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { InventoryTransaction } from './models/inventory-transaction';
import { Post } from './models/post';
import { InventoryItem } from './models/inventory-item';
import { Customer } from './models/customer';
import { InventoryReport } from './models/inventory-report';
import { Coupon } from './models/coupon';
import { Payment } from './models/payment';

@Injectable({
  providedIn: 'root'
})
export class EsploravinoApiService {

  constructor(public httpClient: HttpClient, private router: Router, private angularFireAuth: AngularFireAuth, private matDialog: MatDialog) { }

  createSession(tenantId: string, sessionTokens: SessionTokens): Observable<string> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/sessions';
    return this.httpClient.put<string>(url, { apiSessionName: sessionTokens.apiSessionName, apiSessionToken: sessionTokens.apiSessionToken, uiSessionName: sessionTokens.uiSessionName, uiSessionToken: sessionTokens.uiSessionToken }, { responseType: 'text' as 'json' }); // https://github.com/angular/angular/issues/18672#issuecomment-527874728
  }

  deleteSession(tenantId: string, sessionTokens: SessionTokens): Observable<any> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/sessions';
    return this.httpClient.request('DELETE', url, { body: { apiSessionName: sessionTokens.apiSessionName, apiSessionToken: sessionTokens.apiSessionToken, uiSessionName: sessionTokens.uiSessionName, uiSessionToken: sessionTokens.uiSessionToken } });
  }

  updateSessionSetCustomer(tenantId: string, idToken: string, currentSessionClaims: SessionClaims, customerId: string): Observable<void> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/sessions/customer/' + customerId;

    return this.httpClient.patch<void>(url, null, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  listWineCategories(tenantId: string, idToken: string, currentSessionClaims: SessionClaims): Observable<WineCategory[]> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/wine-categories';

    return this.httpClient.get<WineCategory[]>(url, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  listStores(tenantId: string, customerId: string, idToken: string, currentSessionClaims: SessionClaims): Observable<Store[]> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/stores';

    return this.httpClient.get<Store[]>(url, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  getStore(tenantId: string, customerId: string, storeId: string, idToken: string, currentSessionClaims: SessionClaims): Observable<Store> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/stores/' + storeId;

    return this.httpClient.get<Store>(url, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  listWines(tenantId: string, customerId: string, storeId: string, idToken: string, currentSessionClaims: SessionClaims): Observable<Vino[]> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/stores/' + storeId + '/wines';

    return this.httpClient.get<Vino[]>(url, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  renderWineListPdf(tenantId: string, customerId: string, storeId: string, wineListId: string, idToken: string, currentSessionClaims: SessionClaims): Observable<void> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/stores/' + storeId + '/wine-lists/' + wineListId + '/render-pdf';

    return this.httpClient.put<void>(url, null, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  setupPaymentMethod(tenantId: string, customerId: string, idToken: string): Observable<string> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/payment-methods/setup';
    return this.httpClient.post<string>(url, null, {
      headers: new HttpHeaders({
        Authorization: idToken
      }), responseType: 'text' as 'json'
    }); // https://github.com/angular/angular/issues/18672#issuecomment-527874728
  }

  listPaymentMethods(tenantId: string, customerId: string, idToken: string, currentSessionClaims: SessionClaims): Observable<PaymentMethod[]> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/payment-methods';

    return this.httpClient.get<PaymentMethod[]>(url, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  deletePaymentMethod(tenantId: string, customerId: string, paymentMethodId: string, idToken: string, currentSessionClaims: SessionClaims): Observable<any> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/payment-methods/' + paymentMethodId;

    return this.httpClient.request('DELETE', url, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  preferredPaymentMethod(tenantId: string, customerId: string, paymentMethodId: string, idToken: string, currentSessionClaims: SessionClaims): Observable<any> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/payment-methods/' + paymentMethodId + '/preferred';

    return this.httpClient.put(url, null, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  listInventoryTransactions(tenantId: string, customerId: string, storeId: string, idToken: string, currentSessionClaims: SessionClaims, params?: { start?: string, end?: string, item?: string }): Observable<InventoryTransaction[]> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/stores/' + storeId + '/inventory/transactions';

    let httpParams = new HttpParams();
    if (params?.start) {
      httpParams = httpParams.set('start', params.start);
    }
    if (params?.end) {
      httpParams = httpParams.set('end', params.end);
    }
    if (params?.item) {
      httpParams = httpParams.set('item', params.item);
    }

    return this.httpClient.get<InventoryTransaction[]>(url, {
      params: httpParams,
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  updateInventoryTransaction(tenantId: string, customerId: string, storeId: string, idToken: string, currentSessionClaims: SessionClaims, transaction: InventoryTransaction): Observable<any> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/stores/' + storeId + '/inventory/transactions/' + transaction.id;

    return this.httpClient.put(url, transaction, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  listInventoryItems(tenantId: string, customerId: string, storeId: string, idToken: string, currentSessionClaims: SessionClaims, params?: { start?: string, end?: string }): Observable<InventoryItem[]> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/stores/' + storeId + '/inventory/items';

    let httpParams = new HttpParams();
    if (params?.start) {
      httpParams = httpParams.set('start', params.start);
    }
    if (params?.end) {
      httpParams = httpParams.set('end', params.end);
    }

    return this.httpClient.get<InventoryItem[]>(url, {
      params: httpParams,
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  listPosts(tenantId: string, idToken: string, currentSessionClaims: SessionClaims, silent: boolean = false): Observable<Post[]> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/posts';

    return this.httpClient.get<Post[]>(url, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e, true)));
  }

  getInventoryReport(tenantId: string, customerId: string, storeId: string, idToken: string, currentSessionClaims: SessionClaims): Observable<InventoryReport> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/stores/' + storeId + '/inventory/reports/today';

    return this.httpClient.get<InventoryReport>(url, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  updateInventoryReport(tenantId: string, customerId: string, storeId: string, idToken: string, currentSessionClaims: SessionClaims, inventoryReport: InventoryReport): Observable<InventoryReport> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/stores/' + storeId + '/inventory/reports/today';

    return this.httpClient.post<InventoryReport>(url, inventoryReport, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e, true)));
  }

  private handleErrors(tenantId: string, currentSessionClaims: SessionClaims, e: HttpErrorResponse, silent: boolean = false): Observable<never> {
    console.error(e);

    if (e.status === 401) {
      this.angularFireAuth.signOut();
      this.router.navigateByUrl('/' + tenantId + '/login?returnUrl=' + encodeURIComponent(this.router.url) + '&userId=' + encodeURIComponent(currentSessionClaims.userId));
      this.deleteSession(tenantId, currentSessionClaims);
      return new Observable<never>();
    } if (e.status >= 500 && e.status <= 599) {
      if (!silent) {
        this.matDialog.open(AlertDialogComponent, { data: { title: "Errore", body: 'Purtroppo si è verificato un erorre sul server.\nSe l\'errore persiste, contatta il supporto, grazie.', details: e.error.message } });
      }
      return throwError(e);
    } else {
      if (!silent) {
        this.matDialog.open(AlertDialogComponent, { data: { title: "Errore", body: 'Purtroppo si è verificato un erorre, la comunicazione con il server non è andata a buon fine, riprova più tardi.\nSe l\'errore persiste, contatta il supporto, grazie.', details: e.error.message } });
      }
      return throwError(e);
    }
  }

  listCustomers(tenantId: string, idToken: string, currentSessionClaims: SessionClaims): Observable<Customer[]> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers';

    return this.httpClient.get<Customer[]>(url, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  listCoupons(tenantId: string, customerId: string, idToken: string, currentSessionClaims: SessionClaims): Observable<Coupon[]> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/coupons';

    return this.httpClient.get<Coupon[]>(url, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e)));
  }

  createPayment(tenantId: string, customerId: string, idToken: string, currentSessionClaims: SessionClaims, documentSeries: string, documentId: string, payment: Payment): Observable<InventoryReport> {
    const url = environment.esploravinoApiBaseUrl + '/tenants/' + tenantId + '/customers/' + customerId + '/documents/' + documentSeries + '/' + documentId + '/payments';

    return this.httpClient.post<InventoryReport>(url, payment, {
      headers: new HttpHeaders({
        Authorization: idToken
      })
    }).pipe(catchError((e) => this.handleErrors(tenantId, currentSessionClaims, e, true)));
  }

}
