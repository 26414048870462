import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InventoryReport } from '../models/inventory-report';
import { WineCategory } from '../models/vino';

interface InventoryReportReviewItem {
  label: string;
  qtaContabile: number;
  qtaInventario: number;
  qtaConsumata: number;
}

interface InventoryReportReview {
  qtaContabile: number;
  qtaInventario: number;
  qtaConsumata: number;
  items: { [key: string]: InventoryReportReviewItem };
}

@Component({
  selector: 'app-inventario-review',
  templateUrl: './inventario-review.component.html',
  styleUrls: ['./inventario-review.component.scss']
})
export class InventarioReviewComponent {

  @Input()
  inventoryReport!: InventoryReport;

  @Input()
  wineCategories!: WineCategory[];

  @Input()
  isAgent: boolean = false;

  @Output()
  complete: EventEmitter<void> = new EventEmitter();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter();

  constructor() {

  }

  emitComplete() {
    this.complete.emit();
  }

  emitCancel() {
    this.cancel.emit();
  }

  private categoryHasItems(inventoryReport: InventoryReport, categoria: string) {
    return inventoryReport.items.find((item) => item.categoryId == categoria) ? true : false;
  }

  getData(inventoryReport: InventoryReport): InventoryReportReview {
    const inventoryReportReview: InventoryReportReview = {
      items: {},
      qtaConsumata: 0,
      qtaContabile: 0,
      qtaInventario: 0
    };

    this.wineCategories.forEach((wineCategory) => {
      if (this.categoryHasItems(inventoryReport, wineCategory.id)) {
        const item: InventoryReportReviewItem = {
          label: wineCategory.name,
          qtaConsumata: 0,
          qtaContabile: 0,
          qtaInventario: 0
        }
        inventoryReportReview.items[wineCategory.id] = item;
      }
    });

    inventoryReport.items.forEach((inventoryReportItem) => {
      inventoryReportReview.items[inventoryReportItem.categoryId].qtaContabile += inventoryReportItem.qtaContabile;
      inventoryReportReview.items[inventoryReportItem.categoryId].qtaInventario += inventoryReportItem.qtaInventario;
      inventoryReportReview.items[inventoryReportItem.categoryId].qtaConsumata += (inventoryReportItem.qtaContabile - inventoryReportItem.qtaInventario);

      inventoryReportReview.qtaContabile += inventoryReportItem.qtaContabile;
      inventoryReportReview.qtaInventario += inventoryReportItem.qtaInventario;
      inventoryReportReview.qtaConsumata += (inventoryReportItem.qtaContabile - inventoryReportItem.qtaInventario);
    });

    return inventoryReportReview;

  }
}
