import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { filter, first } from 'rxjs/operators';

import firebase from 'firebase/app';
import 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserResolver implements Resolve<firebase.User | null> {

  constructor(private angularFireAuth: AngularFireAuth) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<firebase.User | null> {
    return this.angularFireAuth.user.pipe(filter((x) => x !== null)).pipe(first());
  }
}

