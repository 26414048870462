import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from './models/store';

@Injectable({
  providedIn: 'root'
})
export class CurrentStoreService {

  private currentStoreSubject: BehaviorSubject<Store | null>;
  currentStore$: Observable<Store | null>;
  private _currentStore: Store | null;

  get currentStore(): Store | null {
    return this._currentStore;
  }

  constructor() {
    this.currentStoreSubject = new BehaviorSubject<Store | null>(null);
    this.currentStore$ = this.currentStoreSubject.asObservable();
    this._currentStore = null;
  }

  setCurrentStore(store: Store | null): void {
    this.currentStoreSubject.next(store);
    this._currentStore = store;
  }
}
