import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EsploravinoApiService } from '../esploravino-api.service';
import { MainToolbarTitleService } from '../main-toolbar-title.service';
import { CallbackMessage } from '../models/callback-message';
import { SessionClaims } from '../models/session-claims';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

  url: SafeUrl;

  tenantId: string;

  currentSessionClaims: SessionClaims;

  constructor(
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private mainToolbarTitleService: MainToolbarTitleService,
    @Inject(DOCUMENT) private document: Document,
    private esploravinoApiService: EsploravinoApiService,
    private angularFireAuth: AngularFireAuth,
    private router: Router
  ) {
    this.currentSessionClaims = this.activatedRoute.snapshot.data.currentSessionClaims as SessionClaims;
    this.tenantId = this.activatedRoute.snapshot.params.tenantId;

    const currentSessionClaims = this.activatedRoute.snapshot.parent?.data.currentSessionClaims as SessionClaims;
    const url = environment.enumeraUiBaseUrl + '?o2c=evino_cambio_password&JXSESSNAME=' + encodeURIComponent(currentSessionClaims.uiSessionName) + '&appCallback=' + encodeURIComponent(this.document.location.protocol + '//' + this.document.location.host + '/postmessage.html');
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.mainToolbarTitleService.setTitle("Modifica Password");
    this.mainToolbarTitleService.setSubtitle(currentSessionClaims.customerName);
  }

  onCallbackMessageReceived(callbackMessage: CallbackMessage) {
    if (callbackMessage.type === 'unauthorized') {
      this.angularFireAuth.signOut();
      this.router.navigateByUrl('/' + this.tenantId + '/login?returnUrl=' + encodeURIComponent(this.router.url) + '&userId=' + encodeURIComponent(this.currentSessionClaims.userId));
      this.esploravinoApiService.deleteSession(this.tenantId, this.currentSessionClaims);
    } else {
      console.warn("Unexpected callback message", callbackMessage);
    }
  }

}
