import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionClaims } from '../models/session-claims';
import { Notification } from '../models/notification';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

  tenantId: string;

  currentSessionClaims: SessionClaims;

  notifications$: Observable<Notification[]>;
  notificationsCount$: Observable<number | null>;

  constructor(private activatedRoute: ActivatedRoute, private angularFirestore: AngularFirestore) {
    this.tenantId = this.activatedRoute.snapshot.params.tenantId;
    this.currentSessionClaims = this.activatedRoute.snapshot.data.currentSessionClaims as SessionClaims;

    this.notifications$ = this.angularFirestore.collection<Notification>(`tenants/${this.tenantId}/customers/${this.currentSessionClaims.customerId}/notifications`, ref => ref.where('read', '==', false).where("archived", "==", false)).valueChanges();
    const countMap = map((results: any[]) => results.length > 0 ? results.length : null);
    this.notificationsCount$ = this.notifications$.pipe(countMap);
  }

  ngOnInit(): void {
  }

}
