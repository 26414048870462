<div class="container-max-width">

    <div fxLayout="row" fxLayoutAlign="start center" class="header">
        <nav mat-tab-nav-bar color="accent" fxFlex>
            <a mat-tab-link class="mat-typography" routerLink="./inbox" routerLinkActive [active]="rla1.isActive"
                #rla1="routerLinkActive">
                <mat-icon class="tab-icon">inbox</mat-icon>
                <span>In Arrivo</span>
            </a>
            <a mat-tab-link class="mat-typography" routerLink="./archive" routerLinkActive [active]="rla2.isActive"
                #rla2="routerLinkActive">
                <mat-icon class="tab-icon">archive</mat-icon>
                <span>Archivio</span>
            </a>
        </nav>
    </div>

    <router-outlet></router-outlet>

    <div class="extended-fab-spacer"></div>

    <div class="extended-fab-container" *ngIf="!currentMessagingToken">
        <button mat-raised-button color="accent" (click)="requestPermission()" [disabled]="activating">
            <mat-icon>notifications</mat-icon>
            <ng-container *ngIf="!activating; else: activatingTemplate">
                Attiva notifiche Push
            </ng-container>
            <ng-template #activatingTemplate>
                Attivazione in corso...
            </ng-template>
        </button>
    </div>
    <div class="extended-fab-container" *ngIf="currentMessagingToken">
        <button mat-raised-button (click)="revokePermission()" [disabled]="activating">
            <mat-icon>notifications_off</mat-icon>
            <ng-container *ngIf="!activating; else: activatingTemplate">
                Disattiva notifiche Push
            </ng-container>
            <ng-template #activatingTemplate>
                Disattivazione in corso...
            </ng-template>
        </button>
    </div>

</div>